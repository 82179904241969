<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>租户共享数据配置</h2>
    <p>租户是通过租户tenantId进行数据隔离的，如果想让数据被各个租户共享，有以下2个办法。</p>
    <h3><a id="_2"></a>方法一：</h3>
    <p>在Mapper接口上或者方法上添加以下注解</p>
    <pre><code class="lang-language">@InterceptorIgnore(tenantLine = "true")
</code></pre>
    <p>可以在mapper接口上添加，所有方法都忽略</p>
    <pre><code class="lang-language">@InterceptorIgnore(tenantLine = "true")
public interface TableColumnMapper extends BaseMapper &lt;TableColumn&gt; {



    List &lt;TableColumnDTO&gt; findListAllStatus(String tableId);

    List &lt;TableColumnDTO&gt; findList(String tableId);

}

</code></pre>
    <p>也可以在方法上添加注解，只忽略该方法</p>
    <pre><code class="lang-language">public interface TableColumnMapper extends BaseMapper &lt;TableColumn&gt; {


@InterceptorIgnore(tenantLine = "true")
    List &lt;TableColumnDTO&gt; findListAllStatus(String tableId);

    List &lt;TableColumnDTO&gt; findList(String tableId);

}

</code></pre>
    <h3><a id="_35"></a>方法二：忽略具体的表</h3>
    <pre><code class="lang-language">com..config.TenantLineHandlerImpl
</code></pre>
    <p>可以自定义决定忽略哪些表</p>
    <pre><code class="lang-language">  switch (tableName) {
            case "sys_menu":
            case "sys_user_role":
            case "sys_user_post":
            case "sys_role_menu"
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom119"
}
</script>

<style scoped>

</style>
