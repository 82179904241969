<template>
<div class="wordhomw-wrap">


</div>
</template>

<script>
export default {
  name: "wordCom"
}
</script>

<style scoped>

</style>
