<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>设计数据库表</h3>
    <p>数据库表名和字段名请使用驼峰命名，不符合规范的表名（x_yyyy), 不建议以单字符带下划线作为表名的起始字段。原因如下：例如s_user, 那么它对应的实体类名为 SUser，这是不被允许的类命名方式，参见<a href="/#/doc/detail/1585460201252515842/1586265526524190721">创建Domain</a>。</p>
    <p>数据库表，必须包含以下必填字段。</p>
    <table>
      <thead>
      <tr>
        <th>字段</th>
        <th>类型</th>
        <th>必须</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>id</td>
        <td>varchar(64)</td>
        <td>✅</td>
        <td>主键</td>
      </tr>
      <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td>tenant_id</td>
        <td>varchar(64)</td>
        <td>✅</td>
        <td>租户id</td>
      </tr>
      <tr>
        <td>create_by_id</td>
        <td>varchar(64)</td>
        <td>✅</td>
        <td>创建者</td>
      </tr>
      <tr>
        <td>create_time</td>
        <td>datetime</td>
        <td>✅</td>
        <td>创建时间</td>
      </tr>
      <tr>
        <td>update_by_id</td>
        <td>varchar(64)</td>
        <td>✅</td>
        <td>更新者</td>
      </tr>
      <tr>
        <td>update_time</td>
        <td>datetime</td>
        <td>✅</td>
        <td>更新时间</td>
      </tr>
      <tr>
        <td>del_flag</td>
        <td>int(11)</td>
        <td>✅</td>
        <td>逻辑删除标记（0：显示；1：隐藏）</td>
      </tr>
      </tbody>
    </table>
    <h3><a id="_19"></a>附录：建议的命名方式</h3>
    <p>1）采用26字母和0-9的自然数（一般不使用）加上下互相 ‘<em>’ 组成，命名简洁明确，多个单词用下划线 ‘</em>’ 隔开</p>
    <p>2）全部小写命名，尽量避免出现大写</p>
    <p>3）字段必须填写描述信息</p>
    <p>4）禁止使用数据库关键字</p>
    <p>5）字段名称一般采用名词或动宾短语</p>
    <p>6）采用字段的名字必须是易于理解，一般不超过三个英文单词</p>
    <p>7）在命名表的列时，不要重复表的名称（如：在 user 表中，出现 user_name 字段）</p>
    <p>8）字段命名使用完整名称</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom51"
}
</script>

<style scoped>

</style>
