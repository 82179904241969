<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_1"></a>多对多</h2>
    <p>2个表之间存在多对多关系，需要创建一张中间表。</p>
    <h3><a id="1__4"></a>1 首先创建单表（学生和课程），如下所示：</h3>
    <h4><a id="11_5"></a>1.1配置表单（学生表）</h4>
    <p><img src="../../../assets/word/28.png" alt=""></p>
    <h4><a id="12_7"></a>1.2运行效果（学生表）</h4>
    <p><img src="../../../assets/word/31.png" alt=""></p>
    <h4><a id="13_10"></a>1.3配置表单(课程表)</h4>
    <p><img src="../../../assets/word/30.png" alt=""></p>
    <h4><a id="14_12"></a>1.4运行效果(课程表)</h4>
    <p><img src="../../../assets/word/29.png" alt=""></p>
    <h4><a id="15_JAVA_14"></a>1.5 将学生表和课程表添加到JAVA类型</h4>
    <p><img src="../../../assets/word/32.png" alt=""></p>
    <h3><a id="2__17"></a>2 创建单表（学生课程表）</h3>
    <h4><a id="21_19"></a>2.1配置数据库属性</h4>
    <p><img src="../../../assets/word/33.png" alt=""></p>
    <h4><a id="22JAVA_21"></a>2.2JAVA实体设置</h4>
    <p>Java类型：选择2.5步骤中生成的自定义对象<br><br>
      Java属性名称：xx.id|name (自定义对象.id|name)<br></p>
    <h4><a id="23__24"></a>2.3 表单页面</h4>
    <p>表单类型：grid选择框<br><br>
      <img src="../../../assets/word/34.png" alt=""><br>
      <br><br>
      配置grid选择框<br>
      JAVA属性标签：grid选择框中显示的table的表头（例如洲名|备注）。<br>
      JAVA属性名称：自定义java对象中的属性，作为列表值显示在gird选择框中。<br>
      检索标签：gird选择框中搜索标签。<br>
      检索key：自定义java对象中的属性，作为检索key（例如name）。<br>
      <img src="../../../assets/word/53.png" alt=""></p>
    <h3><a id="24_35"></a>2.4页面校验</h3>
    <p><img src="../../../assets/word/35.png" alt=""></p>
    <h3><a id="3_39"></a>3运行效果</h3>
    <p><img src="../../../assets/word/54.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom89"
}
</script>

<style scoped>

</style>
