<template>
  <div class="programme">
    <!--  政务转型解决方案-->
    <div class="banner">
      <img :src=plan.topPhotos  width="100%"  height="300px">
      <div class="wrap">
        <h1 ref="title1" style="color: black">{{ plan.title }}</h1>
        <h2 ref="title2" style="color: black">{{ plan.enTitle}}</h2>
      </div>
    </div>
    <div class="linkbanner">
      <el-link type="danger" @click="index">首页</el-link>
      <i class="el-icon-arrow-right"></i>
      <el-link type="danger"  @click="next">方案</el-link>
      <i class="el-icon-arrow-right"></i>
      <el-link type="info">{{ plan.title }}</el-link>
    </div>
    <div class="wrap" v-html="plan.detail">
    </div>
  </div>
</template>

<script>
import newService from "@/api/newService";

export default {
  name: "programmeOne",
  data(){
    return {
      plan:{},
    }
  },
    mounted() {
      this.getPlanOne()
    },
    methods:{
    index(){
      this.$store.state.components='home'
    },
    next(){
      this.$store.state.components='programme'
    },
      getPlanOne(){
        var id =localStorage.getItem('planId')
        newService.getOnePlan(id).then((res)=>{
            if (res.titleColor!== null && res.titleColor!== ""){
                this.$refs.title1.style.color=res.titleColor
                this.$refs.title2.style.color=res.titleColor
            }
            this.plan=res
        })
      }
  }
}
</script>

<style lang="less" scoped>
.programme{
  .linkbanner{
    width: 118rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    margin: auto;
    background: #F8F8F8;
    box-shadow: 0px 1px 0px 0px #E5E5E5;
    padding-left: 2rem;
    font-size: 1.6rem;
    /deep/.el-link--inner{
      font-size: 1.6rem;
    }
    .el-link.el-link--danger{
      color: #ED0200;
      font-size: 1.6rem;
    }
    i{
      margin: 0 5px;
      font-size: 1.6rem;
      padding-top: 1px;
    }
  }
  .banner{
    display: block;
    overflow: hidden;
    position: relative;
    width: 120rem;
    min-width: 120rem;
    margin: 6.4rem auto 0;
    img{display: block}
    .wrap{
      width: auto;
      position: absolute;
      top: 6rem;
      left:  10rem;
      z-index: 1;
      color: #333;
      margin: 0;
      h1{
        font-size: 5.2rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        letter-spacing: .8rem;
      }
      h2{
        font-size: 2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
  .wrap{
    width: 120rem;
    display: block;
    overflow: hidden;
    margin:15rem auto;
    h3{
      font-size: 2.8rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.8rem;
      margin-bottom: 3.2rem;
    }
    h4{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-bottom: 10.0rem;
      color: #444955;
      line-height: 4.0rem;
    }
    .programmeOne-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 6.0rem auto;
      div{
        width: 18.0rem;
        height: 26.0rem;
        margin-left: .5rem;
        margin-right: .5rem;
        background: #FFFEFF;
        box-shadow: 0rem .2rem .8rem 0rem rgba(0,0,0,0.2);
        border-radius: .4rem;
        margin-bottom: 4.0rem;
        font-size: 1.8rem;
        font-family: PingFangSC-Medium, PingFang SC;
        padding: 2.0rem;
        font-weight: 500;
        color: #333333;
        line-height: 2;

        img{
          width: 2.4rem;
          height: 2.4rem;
          margin-right: .5rem;
        }
        h5{
          margin: 0;
          line-height: 2.0rem;
        }
        span{
          display: flex;
          margin-bottom: 2.0rem;
        }
        em{
          font-style: normal;
          font-size: 1.6rem;
        }
      }
    }
  }
}
</style>
