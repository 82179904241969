<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><p>title	sidebar_position<br>
    读Excel<br>
    1<br>
    注解<br>
    使用注解很简单，只要在对应的实体类上面加上注解即可。</p>
    <p>ExcelProperty<br>
      用于匹配excel和实体类的匹配,参数如下：</p>
    <p>名称	默认值	描述<br>
      value	空	用于匹配excel中的头，必须全匹配,如果有多行头，会匹配最后一行头<br>
      order	Integer.MAX_VALUE	优先级高于value，会根据order的顺序来匹配实体和excel中数据的顺序<br>
      index	-1	优先级高于value和order，会根据index直接指定到excel中具体的哪一列<br>
      converter	自动选择	指定当前字段用什么转换器，默认会自动选择。读的情况下只要实现com.alibaba.excel.converters.Converter#convertToJavaData(com.alibaba.excel.converters.ReadConverterContext&lt;?&gt;) 方法即可<br>
      ExcelIgnore<br>
      默认所有字段都会和excel去匹配，加了这个注解会忽略该字段</p>
    <p>ExcelIgnoreUnannotated<br>
      默认不加ExcelProperty 的注解的都会参与读写，加了不会参与</p>
    <p>DateTimeFormat<br>
      日期转换，用String去接收excel日期格式的数据会调用这个注解,参数如下：</p>
    <p>名称	默认值	描述<br>
      value	空	参照java.text.SimpleDateFormat书写即可<br>
      use1904windowing	自动选择	excel中时间是存储1900年起的一个双精度浮点数，但是有时候默认开始日期是1904，所以设置这个值改成默认1904年开始<br>
      NumberFormat<br>
      数字转换，用String去接收excel数字格式的数据会调用这个注解。</p>
    <p>名称	默认值	描述<br>
      value	空	参照java.text.DecimalFormat书写即可<br>
      roundingMode	RoundingMode.HALF_UP	格式化的时候设置舍入模式<br>
      参数<br>
      概念介绍<br>
      ReadWorkbook 可以理解成一个excel<br>
      ReadSheet 理解成一个excel里面的一个表单<br>
      通用参数<br>
      ReadWorkbook,ReadSheet 都会有的参数，如果为空，默认使用上级。</p>
    <p>名称	默认值	描述<br>
      converter	空	默认加载了很多转换器，这里可以加入不支持的字段<br>
      readListener	空	可以注册多个监听器，读取excel的时候会不断的回调监听器中的方法<br>
      headRowNumber	1	excel中头的行数，默认1行<br>
      head	空	与clazz二选一。读取文件头对应的列表，会根据列表匹配数据，建议使用class<br>
      clazz	空	与head二选一。读取文件的头对应的class，也可以使用注解。如果两个都不指定，则会读取全部数据<br>
      autoTrim	true	会对头、读取数据等进行自动trim<br>
      use1904windowing	false	excel中时间是存储1900年起的一个双精度浮点数，但是有时候默认开始日期是1904，所以设置这个值改成默认1904年开始<br>
      useScientificFormat	false	数字转文本的时候在较大的数值的是否是否采用科学计数法<br>
      ReadWorkbook<br>
      :::tip</p>
    <p>设置方法如下，找不到参数的看下通用参数里面是否存在。</p>
    <p>EasyExcel.read(fileName, DemoData.class, new DemoDataListener())<br>
      // 在 read 方法之后， 在 sheet方法之前都是设置ReadWorkbook的参数<br>
      .sheet()<br>
      .doRead();<br>
      :::</p>
    <p>名称	默认值	描述<br>
      excelType	空	当前excel的类型,支持XLS、XLSX、CSV<br>
      inputStream	空	与file二选一。读取文件的流，如果接收到的是流就只用，不用流建议使用file参数。因为使用了inputStream easyexcel会帮忙创建临时文件，最终还是file<br>
      file	空	与inputStream二选一。读取文件的文件。<br>
      mandatoryUseInputStream	false	强制使用 inputStream 来创建对象，性能会变差，但是不会创建临文件。<br>
      charset	Charset#defaultCharset	只有csv文件有用，读取文件的时候使用的编码<br>
      autoCloseStream	true	自动关闭读取的流。<br>
      readCache	空	默认小于5M用 内存，超过5M会使用 EhCache,这里不建议使用这个参数。<br>
      readCacheSelector	SimpleReadCacheSelector	用于选择什么时候用内存去存储临时数据，什么时候用磁盘存储临时数据<br>
      ignoreEmptyRow	true	忽略空的行<br>
      password	空	读取文件的密码<br>
      xlsxSAXParserFactoryName	空	指定sax读取使用的class的名称，例如：com.sun.org.apache.xerces.internal.jaxp.SAXParserFactoryImpl<br>
      useDefaultListener	true	@since 2.1.4 默认会加入ModelBuildEventListener 来帮忙转换成传入class的对象，设置成false后将不会协助转换对象，自定义的监听器会接收到Map&lt;Integer,CellData&gt;对象，如果还想继续接听到class对象，请调用readListener方法，加入自定义的beforeListener、 ModelBuildEventListener、 自定义的afterListener即可。<br>
      extraReadSet	空	额外需要读取内容的set，默认不读取这些数据<br>
      ReadSheet<br>
      :::tip 设置方法如下，找不到参数的看下通用参数里面是否存在。</p>
    <p>EasyExcel.read(fileName, DemoData.class, new DemoDataListener())<br>
      .sheet()<br>
      // 在 sheet 方法之后， 在 doRead方法之前都是设置ReadSheet的参数<br>
      .doRead();<br>
      :::</p>
    <p>名称	默认值	描述<br>
      sheetNo	0	需要读取Sheet的编码，建议使用这个来指定读取哪个Sheet<br>
      sheetName	空	根据名字去匹配Sheet</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom70"
}
</script>

<style scoped>

</style>
