<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="Vue_1"></a>如何在Vue中获取当前登录用户</h3>
    <p>在vue中可以使用以下代码获取当前登录用户信息。</p>
    <pre><code class="lang-language">	var userInfo = this.$TOOL.data.get("USER_INFO");
	let id = userInfo.id;
	let name = userInfo.name;
        let loginName = userInfo.loginName;
	let no = userInfo.no;
	let photo = userInfo.photo;
        let officeId = userInfo.officeDTO?.id;
        let officeName = userInfo.officeDTO?.name;
        let companyId = userInfo..companyDTO?.id;
        let companyName = userInfo..companyDTO?.name;
</code></pre>
    <p>建议 通过computed包装使用。</p>
    <pre><code class="lang-language">  computed: {
    currentUser () {
      var userInfo = this.$TOOL.data.get("USER_INFO");
      return userInfo;
    }
  }
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom37"
}
</script>

<style scoped>

</style>
