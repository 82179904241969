<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>静态路由</h3>
    <p>constantRoutes： 代表那些不需要动态判断权限的路由，如登录页、404、等通用页面。</p>
    <p>位置： /src/router/systemRouter.js。</p>
    <p>包含两种格式：</p>
    <p>不需要侧边栏和顶栏的全新页面，例如login， 直接配置在routes数组中。</p>
    <pre><code class="lang-language">const routes = [
 {
........
	{
		path: "/login",
		component: () =&gt; import(/* webpackChunkName: "login" */'@/views/sys/login/login'),
		meta: {
			title: "登录"
		}
<!-- 	}-->
]
</code></pre>
    <p>需要包含侧边栏和顶栏的内容页面，需要配置成layout下面的子路由。</p>
    <pre><code class="lang-language">const routes = [
	{
		name: "layout",
		path: "/",
		meta: {title: '工作台'},
		component: () =&gt; import(/* webpackChunkName: "layout" */'@/layout'),
		redirect: tool.data.get('APP_HOME') || config.DASHBOARD_URL,
		children: [
		.......
<!--		   {-->
<!--			path: 'flowable/task/TaskForm', component: () =&gt; import(/* webpackChunkName: "TaskForm" */'@/views/flowable/task/TaskForm'),-->
<!--			name: 'task-form',-->
<!--			meta: {title: '流程表单'}-->
<!--		   }-->
		.......
		]
	}
]
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom13"
}
</script>

<style scoped>

</style>
