<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>多租户配置</h3>
    <p>添加多租户</p>
    <h4><a id="_3"></a>实现原理</h4>
    <p>根据域名识别租户id</p>
    <p>通过租户拦截器进行数据隔离处理。</p>
    <h4><a id="_10"></a>部署方式</h4>
    <p>后端通过nginx配置多个租户域名。</p>
    <p>前端通过相对地址访问对应的带租户域名的接口，防止跨域。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom118"
}
</script>

<style scoped>

</style>
