<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="domain_0"></a>创建domain</h3>
    <p>最常用实体类，和数据表一一对应，一个实体对应一张表, 每个实体的字段对应一个相应的数据库字段。</p>
    <p>类型必须以大写字符开头，但是不能连续两个大写字符，例如：a, ABook，这种类名都是不允许的。</p>
    <pre><code class="lang-language">@Data
@EqualsAndHashCode(callSuper = false)
@TableName("test_form_leave")
public class TestFormLeave extends BaseEntity {

       private static final long serialVersionUID = 1L;

	/**
     * 归属部门
     */
	private String officeId;
	/**
     * 员工
     */
	private String userId;
	/**
     * 地区
     */
	private String area;
	/**
     * 请假开始日期
     */
	private Date beginDate;
	/**
     * 请假结束日期
     */
	private Date endDate;

}

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom52"
}
</script>

<style scoped>

</style>
