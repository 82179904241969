<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>创建自己的模块</h1>
    <blockquote>
      <p>如果你需要创建自己的业务系统，例如进销存系统，应该是新建立一个maven工程，依赖-core和-admin, 如果你要开发工作流相关的系统(调用工作流API），还要依赖-flowable，作者不建议你修改任何的模块的代码，你所有的业务代码的开发应该在自己新建的maven工程中。</p>
    </blockquote>
    <h4><a id="1modulemaven_xxxx_7"></a>1.在-module下创建自己的maven工程 -xxxx</h4>
    <p>并参照以下pom文件修改自己的maven工程的pom文件内容。</p>
    <pre><code class="lang-">&lt;?xml version="1.0" encoding="UTF-8"?&gt;
&lt;project xmlns="http://maven.apache.org/POM/4.0.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd"&gt;
    &lt;modelVersion&gt;4.0.0&lt;/modelVersion&gt;

    &lt;parent&gt;
        &lt;groupId&gt;org.&lt;/groupId&gt;
        &lt;artifactId&gt;-module&lt;/artifactId&gt;
        &lt;version&gt;9.0&lt;/version&gt;
    &lt;/parent&gt;


    &lt;artifactId&gt;-test&lt;/artifactId&gt;
    &lt;packaging&gt;jar&lt;/packaging&gt;

    &lt;name&gt;-test&lt;/name&gt;
    &lt;description&gt;测试用例&lt;/description&gt;


    &lt;properties&gt;
        &lt;project.build.sourceEncoding&gt;UTF-8&lt;/project.build.sourceEncoding&gt;
        &lt;project.reporting.outputEncoding&gt;UTF-8&lt;/project.reporting.outputEncoding&gt;
        &lt;java.version&gt;1.8&lt;/java.version&gt;
    &lt;/properties&gt;

    &lt;dependencies&gt;
        &lt;dependency&gt;
            &lt;groupId&gt;org.&lt;/groupId&gt;
            &lt;artifactId&gt;-admin&lt;/artifactId&gt;
            &lt;version&gt;${project.parent.version}&lt;/version&gt;
        &lt;/dependency&gt;
        &lt;dependency&gt;
            &lt;groupId&gt;org.&lt;/groupId&gt;
            &lt;artifactId&gt;-flowable&lt;/artifactId&gt;
            &lt;version&gt;${project.parent.version}&lt;/version&gt;
        &lt;/dependency&gt;
    &lt;/dependencies&gt;
&lt;/project&gt;

</code></pre>
    <h4><a id="2_modulespomxml_57"></a>2 修改-modules/pom.xml</h4>
    <p>将业务模块工程添加到父pom中管理，如下图：</p>
    <pre><code class="lang-language">&lt;?xml version="1.0" encoding="UTF-8"?&gt;
&lt;project xmlns="http://maven.apache.org/POM/4.0.0"
         xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
         xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd"&gt;
    &lt;parent&gt;
        &lt;artifactId&gt;&lt;/artifactId&gt;
        &lt;groupId&gt;org.&lt;/groupId&gt;
        &lt;version&gt;9.0&lt;/version&gt;
    &lt;/parent&gt;
    &lt;modelVersion&gt;4.0.0&lt;/modelVersion&gt;

    &lt;artifactId&gt;-modules&lt;/artifactId&gt;
    &lt;packaging&gt;pom&lt;/packaging&gt;
    &lt;modules&gt;
        &lt;module&gt;-system&lt;/module&gt;
        &lt;module&gt;-flowable&lt;/module&gt;
        &lt;module&gt;-devtools&lt;/module&gt;
        &lt;module&gt;-ureport&lt;/module&gt;
        &lt;module&gt;-wps&lt;/module&gt;
        &lt;module&gt;-file&lt;/module&gt;
        &lt;module&gt;-xxl-job-admin&lt;/module&gt;
        &lt;module&gt;-xxl-job-executor-sample&lt;/module&gt;
        &lt;module&gt;-admin-server&lt;/module&gt;
        &lt;module&gt;-test&lt;/module&gt;
	&lt;module&gt;-xxxx&lt;/module&gt;
    &lt;/modules&gt;

&lt;/project&gt;


</code></pre>
    <h2><a id="3_webpomxml_92"></a>3 修改-web/pom.xml</h2>
    <p>将业务模块添加到启动工程-web的依赖中。<br>
      在pom中添加依赖</p>
    <pre><code>    &lt;dependency&gt;
        &lt;groupId&gt;org.&lt;/groupId&gt;
        &lt;artifactId&gt;-test&lt;/artifactId&gt;
        &lt;version&gt;${project.parent.version}&lt;/version&gt;
    &lt;/dependency&gt;
</code></pre>
    <p>需要启动、打包、发布的模块都需要添加到-web的依赖中，否则在-web下运行install，打包发布时，该模块不会被打进war中。</p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom50"
}
</script>

<style scoped>

</style>
