<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="MapStruct_0"></a>创建MapStruct</h3>
    <h4><a id="MapStruct__2"></a>MapStruct 是什么？</h4>
    <p>MapStruct 是一个代码生成器，它基于约定优于配置方法极大地简化了 Java Bean 类型之间映射的实现。自动生成的映射转换代码只使用简单的方法调用，因此速度快、类型安全而且易于理解阅读。总的来说，有如下三个特点：</p>
    <ol>
      <li>基于注解</li>
      <li>在编译期自动生成映射转换代码</li>
      <li>类型安全、高性能、无依赖性</li>
    </ol>
    <h4><a id="_9"></a>详解</h4>
    <p>MapStruct是满足JSR269规范的一个Java注解处理器，用于为Java Bean生成类型安全且高性能的映射。它基于编译阶段生成get/set代码，此实现过程中没有反射，不会造成额外的性能损失。</p>
    <p>您所要做的就是定义一个mapper接口（@Mapper），该接口用于声明所有必须的映射方法。在编译期间MapStruct会为该接口自动生成实现类。该实现类使用简单的Java方法调用来映射source-target对象，在此过程中没有反射或类似的行为发生。</p>
    <h4><a id="_13"></a>性能</h4>
    <p><img src="../../../assets/word/178.png" alt=""></p>
    <h4><a id="_15"></a>优点</h4>
    <p>与手工编写映射代码相比</p>
    <p>MapStruct通过生成冗长且容易出错的代码来节省时间。<br>
      与动态映射框架相比</p>
    <p>简单泛型智能转换；<br>
      效率高：无需手动 set/get 或 implements Serializable 以达到深拷贝；<br>
      性能更高：使用简单的Java方法调用代替反射；<br>
      编译时类型安全：只能映射相同名称或带映射标记的属性；<br>
      编译时产生错误报告：如果映射不完整（存在未被映射的目标属性）或映射不正确（找不到合适的映射方法或类型转换）则会在编译时抛出异常。</p>
    <h4><a id="MapStructDTOVO__Domain_30"></a>使用MapStruct映射DTO、VO 和 Domain</h4>
    <p>由于DTO看起来很像实体，因此经常需要一种解决方案来自动相互映射。</p>
    <p>在中选择的解决方案是使用MapStruct。这是一个插入Java编译器的注解处理器，它将自动生成所需的映射。</p>
    <p>我们发现它非常干净和高效，并且喜欢它不使用反射（当像在mapper中一样频繁使用时，这对性能不利）。</p>
    <h4><a id="MapStructIDE_37"></a>为MapStruct配置IDE</h4>
    <p>如果使用的是Maven，则需要在IntelliJ中激活IDE配置文件。这是用于应用IDE特定的调整，目前仅包括应用MapStruct注解处理器。</p>
    <p>打开”Maven Projects”工具窗口（View -&gt; Tool Windows），检查IDEmaven配置文件并将其激活。</p>
    <h4><a id="MapStuct_42"></a>MapStuct示例</h4>
    <pre><code class="lang-language">/**
 * Copyright © 2021-2025 &lt;a href="http://www..org/"&gt;&lt;/a&gt; All rights reserved.
 */
package com..test.one.service.mapstruct;


import com..core.mapstruct.EntityWrapper;
import com..test.one.service.dto.TestFormLeaveDTO;
import com..test.one.domain.TestFormLeave;
import org.mapstruct.Mapper;
import org.mapstruct.Mapping;
import org.mapstruct.Mappings;
import org.mapstruct.ReportingPolicy;
import org.mapstruct.factory.Mappers;

/**
 * TestFormLeaveWrapper
 * @author 刘高峰
 * @version 2022-07-26
 */
@Mapper(componentModel = "spring", unmappedTargetPolicy = ReportingPolicy.IGNORE, uses = {} )
public interface TestFormLeaveWrapper extends EntityWrapper&lt;TestFormLeaveDTO, TestFormLeave&gt; {

    TestFormLeaveWrapper INSTANCE = Mappers.getMapper(TestFormLeaveWrapper.class);
     @Mappings({
            @Mapping(source = "office.id", target = "officeId"),
            @Mapping(source = "user.id", target = "userId"),
            @Mapping(source = "createBy.id", target = "createById"),
            @Mapping (source = "updateBy.id", target = "updateById")})
    TestFormLeave toEntity(TestFormLeaveDTO dto);


    @Mappings({
            @Mapping(source = "officeId", target = "office.id"),
            @Mapping(source = "userId", target = "user.id"),
            @Mapping (source = "createById", target = "createBy.id"),
            @Mapping (source = "updateById", target = "updateBy.id")})
    TestFormLeaveDTO toDTO(TestFormLeave entity);
}


</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom55"
}
</script>

<style scoped>

</style>
