<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>数据大屏</h1>
    <p>主要介绍如何为大屏中的组件配置数据源。</p>
    <ul>
      <li><a href="#">静态数据源配置图表</a></li>
      <li><a href="#">SQL数据源配置图表</a></li>
      <li><a href="#">数据集配置图表</a></li>
      <li><a href="#">API接口配置图表</a></li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom126"
}
</script>

<style scoped>

</style>
