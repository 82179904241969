<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>文件服务</h1>
    <p>支持三种文件存储方式，可以通过配置自由切换，前端调用统一接口进行上传操作。</p>
    <ul>
      <li><a href="#">使用minio</a></li>
      <li><a href="#">使用阿里云</a></li>
      <li><a href="#">使用本地存储</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom120"
}
</script>

<style scoped>

</style>
