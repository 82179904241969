<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>新增组件</h3>
    <p>全局组件请定义在@/components中，例如上传，用户，角色等组件, 全局组件的注册在src/components.js中。<br>
      例如：</p>
    <pre><code class="lang-language">app.component('ImageUpload', ImageUpload);
</code></pre>
    <p>每个页面或者模块特定的业务组件则会写在当前 views 下面。如：@/views/user/components/xxx.vue。</p>
    <p>实现自定义组件请实现v-model</p>
    <p>目的是为了增加组件的可维护性。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom20"
}
</script>

<style scoped>

</style>
