<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_1"></a>表单页面</h3>
    <p><img src="../../../assets/word/105.png" alt=""></p>
    <ul>
      <li>表单显示：是否包含在弹出框中，如果勾选表示在弹出框显示，该字段对用户是可输入可编辑的。</li>
      <li>表单类型：表单中字段生成的控件样式，如：input，select，treeselect，areatext等等</li>
      <li>字典类型：如果字段生成方案为：下拉框、复选框、单选框，则该字段必须指定一个字典类型。（请在：系统管理-》字典管理中添加字典）</li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom78"
}
</script>

<style scoped>

</style>
