<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="minio_0"></a>使用minio</h3>
    <h4><a id="_minio_2"></a>步骤一 安装minio</h4>
    <p>参照 <a href="/#/doc/detail/1585460201252515842/1585891269780742146">安装minio</a></p>
    <h4><a id="_minio_6"></a>步骤二 修改配置使用minio存储</h4>
    <p>编辑application-development.yml文件，将config.accessory.type类型设置为 minIO</p>
    <pre><code class="lang-language">#oss配置
config:
  accessory:
    #local, aliyun, minIO
    type: minIO
    baseDir: file
    minIO:
      endpoint: http://localhost:9000
      accessKey: admin
      secretKey: admin123456
      bucketName:
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom121"
}
</script>

<style scoped>

</style>
