<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h3><a ></a>新增view</h3>
    <p>新增完路由之后不要忘记在 @/views 文件下 创建对应的文件夹，一般一个路由对应一个文件，该模块下的功能组件或者方法就建议在本文件夹下创建一个utils或components文件夹，各个功能模块维护自己的utils或components组件。如：</p>
    <pre><code class="lang-language">
src/views/test/one/TestFormLeaveForm.vue

src/views/test/one/TestFormLeaveList.vue
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom18"
}
</script>

<style scoped>

</style>
