<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>用户</h3>
    <p>引入</p>
    <pre><code class="lang-language">import UserSelect from '@/components/userSelect'
</code></pre>
    <p>使用</p>
    <pre><code class="lang-language">&lt;user-select v-model="inputForm.user" :limit="1"&gt;&lt;/user-select&gt;
</code></pre>
    <p>参数</p>
    <table>
      <thead>
      <tr>
        <th>参数</th>
        <th>类型</th>
        <th>默认值</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>limit</td>
        <td>Number</td>
        <td>无限制</td>
        <td>允许选择的最多人数</td>
      </tr>
      <tr>
        <td>tenantId</td>
        <td>String</td>
        <td>null</td>
        <td>不设置，只会访问当前租户内的人员，默认租户访问全部租户人员</td>
      </tr>
      <tr>
        <td>printRead</td>
        <td>Boolean</td>
        <td>false</td>
        <td>打印阅读模式</td>
      </tr>
      <tr>
        <td>clearable</td>
        <td>Boolean</td>
        <td>true</td>
        <td>是否允许清除</td>
      </tr>
      <tr>
        <td>readonly</td>
        <td>Boolean</td>
        <td>false</td>
        <td>只读模式</td>
      </tr>
      <tr>
        <td>disabled</td>
        <td>Boolean</td>
        <td>false</td>
        <td>禁止模式</td>
      </tr>
      </tbody>
    </table>
    <p><img src="../../../assets/word/152.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom24"
}
</script>

<style scoped>

</style>
