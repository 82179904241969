<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a ></a>表格相关</h2>
    <ul>
      <li><a href="#">默认排序/自定义排序</a></li>
      <li><a href="#">表格行权限</a></li>
      <li><a href="#">表格列权限</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom31"
}
</script>

<style scoped>

</style>
