<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>快速开发</h1>
    <ul>
      <li><a href="#">代码生成</a></li>
      <li><a href="#">多数据配置</a></li>
      <li><a href="#">修改/添加包名</a></li>
      <li><a href="#">接口开发</a></li>
      <li><a href="#">登录超时配置</a></li>
      <li><a href="#">定时任务</a></li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom72"
}
</script>

<style scoped>

</style>
