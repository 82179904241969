<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="Redis_0"></a>Redis缓存使用</h1>
    <p>默认使用Redis缓存，通常有两种调用方式，如下：</p>
    <h3><a id="1__4"></a>1 注解方式</h3>
    <p>注解方式只能使用在service类的方法上。</p>
    <p>示例如下：</p>
    <p>添加缓存</p>
    <pre><code class="lang-">	@Cacheable(cacheNames = CacheNames.XXX, key = "#userId")
	public List &lt;DataRuleDTO&gt; findByUserId(String userId) {
		return baseMapper.findByUserId (userId );
	}
</code></pre>
    <p>删除缓存</p>
    <pre><code class="lang-">	@CacheEvict(cacheNames = CacheNames.XXX, allEntries = true)
	public void delete(String id) {

	}
</code></pre>
    <h3><a id="2__27"></a>2 编码方式</h3>
    <p>注解的方式只能在service中使用，如果是controller或者其他非service类中使用该怎么办呢？</p>
    <p>这个时候请使用缓存工具类</p>
    <p>示例如下：</p>
    <p>添加缓存并设置过期时间</p>
    <pre><code class="lang-language">   RedisUtils.getInstance ().set ( CacheNames.USER_CACHE_TOKEN ,  token, newAccessToken  );
   RedisUtils.getInstance ().expire ( CacheNames.USER_CACHE_TOKEN , token,Properties.newInstance ( ).getEXPIRE_TIME ( ) );
</code></pre>
    <p>清除缓存</p>
    <pre><code class="lang-language">  RedisUtils.getInstance ().delete ( CacheNames.USER_CACHE_TOKEN ,  token );
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom66"
}
</script>

<style scoped>

</style>
