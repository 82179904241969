<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>使用本地存储</h3>
    <h4><a id="_minio_2"></a>步骤一 修改配置使用minio存储</h4>
    <p>编辑application-development.yml文件，将config.accessory.type类型设置为 local</p>
    <pre><code class="lang-language">#oss配置
config:
  accessory:
    #local, aliyun, minIO
    type: minIO
    baseDir: file
    local:
      location: d:\\accessory
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom"
}
</script>

<style scoped>

</style>
