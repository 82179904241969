<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>表格列权限</h2>
    <h3><a id="_2"></a>在前端控制哪些列可见。</h3>
    <p>在后端控制返回的数据</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom65"
}
</script>

<style scoped>

</style>
