import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state:{
        components:'home',
        // 背景颜色
        isbackground:false,
        // 字体颜色
        iscolor:false,
        // logo颜色
        islogored:false,
        wordName:'wordcom0'
    },
    getters:{
      getSongid:  state=>{
          return state.components
      }
    },
    mutations:{
        setUser (state, name) {
            state.components = name
        },
    }
})

