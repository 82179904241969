<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_1"></a>动态表单</h1>
    <p>动态是用来0代码所见即所得快速开发一个功能页面的，和代码生成器的区别是无需生成代码，因此不需要重新发布war包，可以在线上环境使用。</p>
    <h4><a id="__7"></a># 设计表单</h4>
    <p>创建一个新的表单，设计完之后保存。</p>
    <p><img src="../../../assets/word/96.png" alt=""></p>
    <h4><a id="__12"></a># 发布</h4>
    <p>发布表单，创建菜单将功能添加到系统菜单之中。</p>
    <p><img src="../../../assets/word/97.png" alt=""></p>
    <h4><a id="__17"></a># 角色授权</h4>
    <p>授权可以使用该表单的用户。</p>
    <p><img src="../../../assets/word/98.png" alt=""></p>
    <p>关联现有的表</p>
    <p>数据权限</p>
    <p>子表 多表关联</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom96"
}
</script>

<style scoped>

</style>
