<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>左树右表</h2>
    <p>左树右表</p>
    <h3><a id="1__2"></a>1 首先创建一个左树（主表），如下所示：</h3>
    <h4><a id="11__3"></a>1.1 配置表单</h4>
    <p><img src="../../../assets/word/46.png" alt=""></p>
    <h4><a id="12_JAVA_5"></a>1.2 JAVA实体设置</h4>
    <p><img src="../../../assets/word/55.png" alt=""></p>
    <h4><a id="13__7"></a>1.3 表单页面</h4>
    <p><img src="../../../assets/word/65.png" alt=""></p>
    <h4><a id="14__9"></a>1.4 表单校验</h4>
    <p><img src="../../../assets/word/67.png" alt=""></p>
    <h3><a id="2__12"></a>2 创建右表（附表）</h3>
    <h4><a id="21_14"></a>2.1配置数据库属性</h4>
    <p><img src="../../../assets/word/47.png" alt=""></p>
    <h4><a id="22_Java_16"></a>2.2 Java实体设置</h4>
    <p>外键的java类型：string。<br>
      外键的java属性名称：kind.id|name<br>
      <img src="../../../assets/word/56.png" alt=""></p>
    <h4><a id="23__20"></a>2.3 表单页面</h4>
    <p>外键的显示表单类型：树选择控件<br>
      <img src="../../../assets/word/48.png" alt=""></p>
    <h3><a id="3_25"></a>3生成代码</h3>
    <p>选择主表，生成代码，代码风格选择:左树右表<br>
      <img src="../../../assets/word/57.png" alt=""></p>
    <h3><a id="4_30"></a>4运行效果</h3>
    <p><img src="../../../assets/word/58.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom90"
}
</script>

<style scoped>

</style>
