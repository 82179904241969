<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="DTO_0"></a>创建DTO</h2>
    <h3><a id="_2"></a>介绍</h3>
    <p>默认情况下，可以直接在前后端传输数据过程中使用Domain对象，主要的好处是代码包含更少的层，也更易于理解， domain对象也可以直接被mybatis plus处理等等。</p>
    <p>但是对于复杂用例，您可能需要使用对外部公开的数据传输对象（DTO），例如对多表的关联处理，domain对象无能为力（domain对象的字段都是基本型，和数据库表字段一一对应，不能用来处理关联关系），这时候我们就需要引入DTO聚合多个domain对象。</p>
    <p>在代码生成器生成的示例中，使用DTO进行数据传输成了标准默认方案。</p>
    <h4><a id="1__9"></a>1 客户端提交数据</h4>
    <p>客户端提交的数据被DTO接收，然后通过<a href="/#/doc/detail/1585460201252515842/1590343480426373122">MapStruct</a>转换成可以被mybatis plus操作的单表对象domain。再通过service保存进数据库。</p>
    <h4><a id="2__13"></a>2 服务端返回数据</h4>
    <p>服务端返回数据，默认也是转换成DTO传递给前端显示，如果需要多个视图，那需要使用<a href="/#/doc/detail/1585460201252515842/1590309674948587521">VO</a>。</p>
    <h3><a id="DTO_18"></a>DTO示例</h3>
    <pre><code class="lang-language">package com..test.one.service.dto;

import com..sys.service.dto.OfficeDTO;
import javax.validation.constraints.NotNull;
import com..sys.service.dto.UserDTO;
import java.util.Date;
import com.fasterxml.jackson.annotation.JsonFormat;
import com..core.query.Query;
import com..core.query.QueryType;
import com..core.service.dto.BaseDTO;
import lombok.Data;
import lombok.EqualsAndHashCode;
/**
 * 请假DTO
 * @author 刘高峰
 * @version 2022-07-26
 */
@Data
@EqualsAndHashCode(callSuper = false)
public class TestFormLeaveDTO extends BaseDTO {

	private static final long serialVersionUID = 1L;

        /**
         * 归属部门
         */
	@NotNull(message="归属部门不能为空")
<!--    	@Query(tableColumn = "a.office_id", javaField = "office.id", type = QueryType.LIKE)-->
	private OfficeDTO office;

	/**
         * 员工
         */
	private UserDTO user;

	/**
         * 地区
         */
	private String area;

	/**
         * 请假开始日期
         */
	@JsonFormat(pattern = "yyyy-MM-dd HH:mm:ss")
	private Date beginDate;

	/**
         * 请假结束日期
         */
	@JsonFormat(pattern = "yyyy-MM-dd HH:mm:ss")
	private Date endDate;

}

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom53"
}
</script>

<style scoped>

</style>
