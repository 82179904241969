<template>
<div class="footer">
  <section>
    <div class="dllink">
      <dl>
        <dt>免费源码下载</dt>
        <dd>低代码开发平台</dd>
        <dd>移动开发框架</dd>
        <dd>更多插件下载</dd>
      </dl>
      <dl>
          <dt>法律声明</dt>
          <dd @click="footerclick1()">隐私保护指引</dd>
          <dd @click="footerclick()">软件许可及服务协议</dd>
      </dl>
      <dl>
        <dt>联系方式</dt>
        <dd>商务热线：<a href="tel:&{company.businessHotline}" style="color: #fff;text-decoration: none;font-size: 1.4rem; font-style: normal ">{{ company.businessHotline }}</a></dd>
        <dd>商务QQ：{{ company.businessQq }}</dd>
        <dd>商务邮箱：{{company.businessEmail}}</dd>
      </dl>

    </div>
    <div style="display: flex; align-items: center;flex-flow: column;justify-content: center; margin: auto">
      <img :src=company.wechatImg style="width: 16.8rem">
      <p style="text-align: center; margin-top: 1rem;font-weight: 400;color: #FFFFFF;line-height: 1.5rem; font-size: 1.2rem; display: block">关注微信公众号，查看更多资讯</p>
    </div>
  </section>
  <section >
    <div class="beian" style="display: flex; align-items: center; text-align: center; justify-content: center">
      <img src="../../assets/wj.png" style="width: 1.8rem; margin-right: 1rem"/> {{ company.prove }}
    </div>
  </section>

</div>
</template>

<script>
export default {
  name: "footerIndex",
    created() {
    },
    components:{},
    props:{
      company:{
          type: Object,
          default(){
              return {}
          }
      }
    },
    data(){
    return {
      // qq:"33534482",
      // iphone:'0591-12345678',
      // emile:'zoomhan@163.com',
      // beian:'版权所有©2023 福建纵横云创 闽ICP备2023004428',
    }
  },
  methods:{
      agreementzong(){
          this.$store.state.isbackground=false
          this.$store.state.iscolor=true
          this.$store.state.islogored=true
          document.body.scrollTop=document.documentElement.scrollTop=0
      },
      footerclick(){
          this.$store.state.components='xieyi1'
          this.agreementzong()
          // this.$emit('footerclick',val)
      },
      footerclick1(){
          this.$store.state.components='xieyi2'
          this.agreementzong()
          // this.$emit('footerclick',val)
      }
  }
}
</script>

<style scoped>
.footer{
  background: #232323;
  color: #fff;
  padding-top: 5rem;
}
section{
  width: 120rem;
  margin: 0rem auto;
  min-width: 120rem;
  display: flex;
  justify-content: space-between;
}
section .dllink{
  padding: 5rem 0;
  display: flex;
  flex: 1;
}
dl{
  margin-right: 10rem;
}
dt{
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}
dd{
  line-height: 4rem;
cursor: pointer;
  font-size: 1.4rem;
}
dd:hover{
  text-decoration: underline;
}
.beian{
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  padding: 5rem 0;
  width: 100%;
  border-top: .1rem solid rgba(216,216,216,0.1);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
</style>
