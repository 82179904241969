<template>
    <div class="wordhomw-wrap">
        <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="API_2"></a>自定义新增大屏组件</h2>
            <p>1.public/datav/config.js 下添加需要新增的组件</p>
            <p>2.src/datav/echart/packages/   下新增页面</p>
            <p>3.src/datav/echart/index.js  里注册该页面组件</p>
            <p>4.src/datav/option/components/  里添加页面的右侧选择栏页面</p>
            <p>5.src/datav/option/config.js  里在eventlist和datalist里条件需要新增的组件名</p>
            <p> </p>
            <h3>新增时注意：</h3>
            <p>1.监听dataChart数据变化，由于数据传输有延迟，所以要进行监听</p>
            <pre><code class="lang-language">
                 watch: {
                    dataChart(){
                      this.initVue()
                    },
                    content () {
                      this.initVue()
                    }
                  },
            </code></pre>
            <p>2.初始化时需要进行挂载</p>
            <pre><code class="lang-language">
                 var dom = document.getElementById('testBar');
                 var eChart = echarts.init(dom, null, {});
            </code></pre>
            <p>3.数据为 data: this.dataChart</p>
            <p>4.option底部 </p>
            <pre><code class="lang-language">
                if (option && typeof option === 'object') {
                    eChart.setOption(option);
                  };
                window.addEventListener('resize', eChart.resize);
                this.myChart.resize();
                this.myChart.setOption(option, true);
                this.reload = false
            </code></pre>
            <p> 5.！注意 id 必须为注册时的id  （如注册时使用testBar，div的id就要为testBar）</p>
            <p>6.非data 格式的数据 </p>
            <pre><code class="lang-language">
                 xAxis:  this.dataChart.xAxis ,
                 legend:  this.dataChart.legend,
                 series: this.dataChart.series,
                （后端须按格式传输数据，否则无法展示）
                 配置文件中data：{
                    xAxis：xxxx
                    legend：xxxx
                    series：xxxx
                 }
            </code></pre>
            <p>7.由于数据加载有延迟所以需要配置加载时间否则无法展示</p>
            <pre><code class="lang-language">
                （1）在最外层div v-if="isReloadData"
                （2）data isReloadData: true,
                （3）监听（缺一不可） styleSizeName(){
                    this.reloadSize()
                    },
                    styleChartName(){
                    this.reloadSize()
                    },
                （4）reload方法 设置延迟时间等待传输后再加载
                    reloadSize() {
                    this.isReloadData = false;
                    setTimeout(() => {
                    this.isReloadData = true
                    this.initVue()
                      }, "100");
                    }
            </code></pre>
        </div>
    </div>
</template>

<script>
export default {
    name: "wordCom131"
}
</script>

<style scoped>

</style>