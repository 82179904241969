<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="Controller_0"></a>创建Controller</h3>
    <p>Controller是SpringBoot的基本组件，也是MVC结构的组成部分，其作用是将用户提交来的请求通过URL匹配，分发给不同的接收器（具体的Controller），该接收器会对其进行相应处理，然后返回处理结果。</p>
    <p>一个典型的Controller如下所示</p>
    <pre><code class="lang-language">/**
 * Copyright © 2021-2025 &lt;a href="http://www..org/"&gt;&lt;/a&gt; All rights reserved.
 */
package com..test.one.controller;

import javax.validation.Valid;
import com.google.common.collect.Lists;
import com..core.query.QueryWrapperGenerator;
import com..logging.annotation.ApiLog;
import io.swagger.annotations.Api;
import io.swagger.annotations.ApiOperation;
import org.springframework.http.ResponseEntity;
import org.springframework.security.access.prepost.PreAuthorize;
import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.web.bind.annotation.*;
import com.baomidou.mybatisplus.core.conditions.query.QueryWrapper;
import com.baomidou.mybatisplus.core.metadata.IPage;
import com.baomidou.mybatisplus.extension.plugins.pagination.Page;
import com..test.one.service.dto.TestFormLeaveDTO;
import com..test.one.service.mapstruct.TestFormLeaveWrapper;
import com..test.one.service.TestFormLeaveService;

/**
 * 请假Controller
 * @author 刘高峰
 * @version 2022-07-26
 */

@Api(tags ="请假")
@RestController
@RequestMapping(value = "/test/one/testFormLeave")
public class TestFormLeaveController {

	@Autowired
	private TestFormLeaveService testFormLeaveService;

	@Autowired
	private TestFormLeaveWrapper testFormLeaveWrapper;

	/*** 请假列表数据	 */
	@ApiLog("查询请假列表数据")
	@ApiOperation(value = "查询请假列表数据")
	@PreAuthorize("hasAuthority('test:one:testFormLeave:list')")
	@GetMapping("list")
	public ResponseEntity&lt;IPage&lt;TestFormLeaveDTO&gt;&gt; list(TestFormLeaveDTO testFormLeaveDTO, Page&lt;TestFormLeaveDTO&gt; page) throws Exception {
		QueryWrapper queryWrapper = QueryWrapperGenerator.buildQueryCondition (testFormLeaveDTO, TestFormLeaveDTO.class);
		IPage&lt;TestFormLeaveDTO&gt; result = testFormLeaveService.findPage (page, queryWrapper);
		return ResponseEntity.ok (result);
	}


	/**	 * 根据Id获取请假数据	 */
	@ApiLog("根据Id获取请假数据")
	@ApiOperation(value = "根据Id获取请假数据")
	@PreAuthorize("hasAnyAuthority('test:one:testFormLeave:view','test:one:testFormLeave:add','test:one:testFormLeave:edit')")
	@GetMapping("queryById")
	public ResponseEntity&lt;TestFormLeaveDTO&gt; queryById(String id) {
		return ResponseEntity.ok ( testFormLeaveService.findById ( id ) );
	}

	/**	 * 保存请假	 */
	@ApiLog("保存请假")
	@ApiOperation(value = "保存请假")
	@PreAuthorize("hasAnyAuthority('test:one:testFormLeave:add','test:one:testFormLeave:edit')")
	@PostMapping("save")
	public  ResponseEntity &lt;String&gt; save(@Valid @RequestBody TestFormLeaveDTO testFormLeaveDTO) {
		//新增或编辑表单保存
		testFormLeaveService.saveOrUpdate (testFormLeaveWrapper.toEntity (testFormLeaveDTO));
        return ResponseEntity.ok ( "保存请假成功" );
	}


	/**	 * 删除请假	 */
	@ApiLog("删除请假")
	@ApiOperation(value = "删除请假")
	@PreAuthorize("hasAuthority('test:one:testFormLeave:del')")
	@DeleteMapping("delete")
	public ResponseEntity &lt;String&gt; delete(String ids) {
		String idArray[] = ids.split(",");
        testFormLeaveService.removeByIds ( Lists.newArrayList ( idArray ) );
		return ResponseEntity.ok( "删除请假成功" );
	}

}

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom58"
}
</script>

<style scoped>

</style>
