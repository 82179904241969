<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_1"></a>免登陆配置</h2>
    <p>如何匿名访问api接口呢？</p>
    <h3><a id="__4"></a>第一步 删除权限标志</h3>
    <p>@PreAuthorize(“hasAnyAuthority(‘form:make:add’,‘form:make:edit’)”)</p>
    <h3><a id="__6"></a>第二步 添加匿名访问登录接口</h3>
    <p>修改 com..security.config.WebSecurityConfig.java ，在 .antMatchers 方法中添加接口。</p>
    <pre><code class="lang-language">    @Override
    protected void configure(HttpSecurity http) throws Exception {
        http
                .headers ( ).frameOptions ( ).disable ( ).and ( )
                .exceptionHandling ( ).accessDeniedHandler ( accessDeniedHandler ).and ( )
                // 由于使用的是JWT，我们这里不需要csrf
                .csrf ( ).disable ( )
                .exceptionHandling ( ).authenticationEntryPoint ( unauthorizedHandler ).and ( )
                // 基于token，所以不需要session
                .sessionManagement ( ).sessionCreationPolicy ( SessionCreationPolicy.STATELESS ).and ( )
                .authorizeRequests ( )
                .antMatchers ( "/401"
                        , "/404",
                        "/app/rest/**",
                        "/druid/**",
                        "/v1/**",
                        "/v2/**",
                        "/weboffice/**",
                        "/ReportServer/**",
                        "/sys/login",
                        "/app/sys/login",
                        "/sys/sysConfig/getConfig",
                        "/getAppFlowChart",
                        "/sys/getCode",
                        "/app/sys/getCode",
                        "/sys/casLogin" ).permitAll ( ) // 允许请求无需认证
                .antMatchers ( HttpMethod.OPTIONS, "/**" ).permitAll ( )
                .anyRequest ( ).authenticated ( ) // 所有请求都需要验证
                .and ( )
                .apply ( securityConfigurerAdapter ( ) );
    }

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom61"
}
</script>

<style scoped>

</style>
