<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1>相关配置</h1><a style="margin-top:5px" href="#">动态表单</a><a style="margin-top:5px" href="#">邮件配置</a><a style="margin-top:5px" href="#">短信配置</a></div>

</div>
</template>

<script>
export default {
  name: "wordCom95"
}
</script>

<style scoped>

</style>
