<template>
<div class="wordhomw-wrap">

  <div class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>文件上传</h3>
    <p>引入</p>
    <pre><code class="lang-language"> FileUpload 已注册成全局组件，无需单独引入。
</code></pre>
    <p>使用</p>
    <pre><code class="lang-language"> &lt;file-upload v-model="inputForm.files" :limit="3" tip="最多上传3个文件,单个文件不要超过10M,请上传xlsx/docx格式文件"&gt;
</code></pre>
    <p>参数</p>
    <table>
      <thead>
      <tr>
        <th>参数</th>
        <th>类型</th>
        <th>默认值</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>limit</td>
        <td>number</td>
        <td>null</td>
        <td>限制上传文件的个数，默认无限制</td>
      </tr>
      <tr>
        <td>maxSize</td>
        <td>number</td>
        <td>10</td>
        <td>单位是MB,限制上传单个文件的大小</td>
      </tr>
      <tr>
        <td>accept</td>
        <td>String</td>
        <td>“”</td>
        <td>限制上传文件的类型,默认无限制</td>
      </tr>
      <tr>
        <td>drag</td>
        <td>Boolean</td>
        <td>false</td>
        <td>是否允许拖拽上传</td>
      </tr>
      <tr>
        <td>disabled</td>
        <td>Boolean</td>
        <td>false</td>
        <td>禁止模式</td>
      </tr>
      <tr>
        <td>onSuccess</td>
        <td>Function</td>
        <td>null</td>
        <td>上传成功之后的回调函数</td>
      </tr>
      </tbody>
    </table>
    <p>演示<br>
      <img src="../../../assets/word/148.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom28"
}
</script>

<style scoped>

</style>
