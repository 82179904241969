<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>修改包名</h1>
    <p>修改包名，例如修改为com.boot<br>
      修改包名分为2种：<br>
      1 将系统包名全部修改为com.boot，新添加的模块也使用新包名com.boot<br>
      2 不修改系统包名(仍使用com.)，新添加的模块使用新包名com.boot</p>
    <p>修改步骤如下</p>
    <p>1 修改yml文件<br>
      <img src="../../../assets/word/8.png" alt=""><br>
      修改2个位置，首先修改mapper-locations, 扫描所有mapper.xml文件<br>
      其次修改type-aliases-package， 添加com.boot.modules。</p>
    <p>注：（即使修改了系统包名，也要保留com..modules这个配置，原因是org.gencode这个非开源包使用了com.目录，这个目录需要扫描。）</p>
    <p>2 修改application<br>
      <img src="../../../assets/word/9.png" alt=""><br>
      首先修改componentScan，添加com.boot<br>
      其次添加注解MapperScan,扫描2个位置的mapper类。</p>
    <p>3 修改yml文件的plugin类<br>
      将以下类的包名修改为com.boot, 特别注意此处修改只针对第一种修改系统包名的情况，如果不修改系统包名，只是添加新的模块时使用新包名，则无需修改此处。<br>
      <img src="../../../assets/word/10.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom92"
}
</script>

<style scoped>

</style>
