<template>
  <div>
    <div v-if="pageflag === 'list'" class="wwwd">
      <div class="v-note-wrapper markdown-body shadow" style="margin-top: 2rem">
        <div v-if="nohaslist === true" style="font-size: 2.5rem; color: #409eff">没有找到有关内容</div>
        <!--      查询内容-->
        <div class="textContent" style="margin-top: 0.2rem" v-for="item in newClList" :key="item.id" @click="getClOne(item.id)">
          <div class="lisTitle" style="font-size: 1.8rem; color: #409eff; text-decoration: underline">
            {{ item.title }}
          </div>
          <p>{{ item.synopsis }}</p>
        </div>
      </div>
      <page-component
        v-if="total > 0"
        :total="total"
        :limit.sync="pageSize"
        :page.sync="pageNum"
        @pagination="getClList"
        style="text-align: center; padding-left: 0.4rem"></page-component>
    </div>

    <div v-if="pageflag === 'one'" class="wwwd" style="height: 100%">
      <div v-if="clOnemessage === '暂无数据请联系等待管理员添加'" style="margin-top: 0.5rem">
        <div style="font-size: 3rem; color: #409eff">暂无数据请联系管理员添加</div>
      </div>
      <template v-else>
        <el-button @click="retuenlist" type="primary" style="border-radius: 1rem; font-size: 1.7rem; z-index: 12; margin-top: 0.5rem"
          >返回查询列表</el-button
        >
        <div style="font-size: 2.4rem; text-align: center; color: #409eff; margin-bottom: 1rem">
          {{ clOne.title }}
        </div>
        <div v-html="clOne.detail" v-if="clOne.detail"></div>
        <div class="photo-wrap" v-if="photoList.length > 0" @mousewheel="srcollFun">
          <div class="img-wrap">
            <div class="item" v-for="e in photoList" :key="e.step" :name="e.step + ''" :id="`item${e.step}`">
              <img :src="e.url" alt="" width="100%" height="95%" />
            </div>
          </div>
          <div class="indicator-wrap">
            <el-button class="shareBtn" type="text" @click="shareBtn">链接分享</el-button>
            <span
              class="indicator"
              :class="{ active: +e === +activeNum }"
              v-for="e in photoList.length"
              :key="e"
              @click.prevent="toStep(e)"
              >{{ e }}</span
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import newService from '@/api/newService'
import pageComponent from '@/components/clword/pageComponent.vue'

export default {
  name: 'ClWordRightList',
  props: {
    queryString: {
      type: String,
      default: '',
    },
  },
  components: {
    pageComponent,
  },
  data() {
    return {
      nohaslist: false,
      clOnemessage: '',
      clOne: {},
      pageflag: 'list',
      newClList: [],
      pageNum: 1,
      pageSize: 6,
      total: 0,
      photoList: [],
      activeNum: '1',
      scrollTop: 0,
    }
  },
  created() {
    this.getClList()
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val //当前页
      this.getClList()
    },
    getClList() {
      this.pageflag = 'list'

      let query = location.search
      let searchParams = new URLSearchParams(query)
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        queryStr: this.queryString,
        zhClToken: searchParams.get('zhClToken'),
      }
      newService.getClHelpList(obj).then(res => {
        this.newClList = res.rows
        this.total = res.total
        if (res.total === 0) {
          this.nohaslist = true
        }
      })
    },
    getClOne(id) {
      this.pageflag = 'one'

      let query = location.search
      let searchParams = new URLSearchParams(query)

      const obj = {
        id: id,
        zhClToken: searchParams.get('zhClToken'),
      }

      newService.getCLHelpOneByList(obj).then(res => {
        if (res === '暂无数据请联系等待管理员添加') {
          this.clOnemessage = res
          return
        }
        this.clOnemessage = 'hasdata'
        this.clOne = res
        this.$emit('setMenuTree', this.clOne)
        this.formatForPhotoList()
      })
    },
    retuenlist() {
      this.getClList()
    },
    formatForPhotoList() {
      if (this.clOne.photoList && this.clOne.photoList.length > 0) {
        this.photoList = this.clOne.photoList
      }
    },
    // 滚动方法
    srcollFun() {
      const myCarousel = document.querySelector('.img-wrap')
      if (!myCarousel) return

      const scrollTop = myCarousel.scrollTop

      // 获取内元素高
      const items = document.querySelectorAll('.img-wrap .item')
      const h = items[0].clientHeight

      // 比较滑动了几个 h 的倍数
      const lev = scrollTop / h
      this.activeNum = Math.floor(lev) + 1 + ''
    },
    // 跳转
    toStep(step) {
      this.activeNum = step + ''
      // 滑动动作
      const targetId = `item${this.activeNum}`
      const target = document.getElementById(targetId)

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    // 链接分享
    async shareBtn() {
      try {
        // 检查 navigator.clipboard 是否可用
        const url = this.clOne.friendLink
        if (!url) {
          this.$message.error('复制失败，请尝试其他方法或更换浏览器')
        } else {
          if (navigator.clipboard) {
            await navigator.clipboard.writeText(url)
            this.$message.success('已复制到剪贴板中')
          } else {
            // 使用回退方案
            const tempInput = document.createElement('input')
            tempInput.value = url
            document.body.appendChild(tempInput)
            tempInput.select()
            document.execCommand('copy') // 已废弃
            document.body.removeChild(tempInput)
            this.$message.success('已复制到剪贴板中')
          }
        }

      } catch (err) {
        console.error('无法复制文本: ', err)
        this.$message.error('复制失败，请尝试其他方法或更换浏览器')
      }
    },
  },
}
</script>

<style lang="scss">
.wwwd {
  padding: 2rem 5rem;
  min-height: 300px;
  h1 {
    padding-bottom: 2rem;
    font-size: 3rem;
    border-bottom: 1px solid #eaecef;
    margin-bottom: 3rem;
    font-weight: 800;
    line-height: 1.25;
  }
  h3 {
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 1.6rem;
    line-height: 1.25;
  }
  .markdown-body .highlight pre,
  .markdown-body pre {
    padding: 1.6rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 0.3rem;
    margin-bottom: 1.6rem;
    font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
  .markdown-body table {
    display: table;
    margin-bottom: 10px;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .markdown-body table td,
  .markdown-body table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
    font-size: 2rem;
  }
  .markdown-body table th {
    font-weight: 600;
  }
  .markdown-body table tr {
    background-color: #fff;
    border-top: 1px solid white;
  }
  .markdown-body img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fffeff;
    margin-top: 10px;
  }
  .markdown-body pre code {
    display: inline;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    font-size: 2rem;
  }
  .markdown-body > :first-child {
    margin-top: 0 !important;
  }
  h2 {
    padding-bottom: 0.3em;
    font-size: 3rem;
    margin-bottom: 2.4rem;
    border-bottom: 1px solid #eaecef;
  }
  h4 {
    font-size: 2.4rem;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    font-weight: 600;
    line-height: 1.25;
  }
  ol，ul,
  li {
    padding-left: 0.4rem;
    margin-top: 0;
    font-size: 1.6rem;
    line-height: 1.7;
    font-weight: 300;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.6rem;
    font-size: 2.2rem;
  }

  a {
    color: #0366d6;
    font-size: 2.4rem;
    line-height: 2;
    font-weight: 400;
    text-decoration: none;
  }

  .hljs-comment,
  .hljs-deletion,
  .hljs-meta,
  .hljs-quote {
    color: #75715e;
  }

  .hljs-doctag,
  .hljs-keyword,
  .hljs-literal,
  .hljs-section,
  .hljs-selector-id,
  .hljs-selector-tag,
  .hljs-title,
  .hljs-type {
    font-weight: 700;
  }

  .hljs-keyword,
  .hljs-literal,
  .hljs-name,
  .hljs-selector-tag,
  .hljs-strong,
  .hljs-tag {
    color: #f92672;
  }

  .hljs-addition,
  .hljs-built_in,
  .hljs-bullet,
  .hljs-emphasis,
  .hljs-section,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-string,
  .hljs-subst,
  .hljs-template-tag,
  .hljs-template-variable,
  .hljs-title,
  .hljs-type,
  .hljs-variable {
    color: #a6e22e;
  }

  .textContent {
    cursor: pointer;
    line-height: 24px;
    padding-left: 1rem;

    &:hover {
      background: white;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }

    p {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 400;

      em {
        color: #5d7dc3;
        font-style: normal;
        font-size: 1.4rem;
      }
    }

    .lisTitle {
      position: relative;
      min-height: 18px;
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: 400;
      padding: 20px 0;
      padding-left: 10px;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        color: #0366d6;
      }

      &:hover::before {
        background-color: #0366d6;
      }

      &::before {
        position: absolute;
        top: 22px;
        left: 0;
        content: '';
        display: block;
        width: 4px;
        height: 20px;
        background-color: #333;
        border-radius: 4px;
      }

      em {
        color: #5d7dc3;
        font-style: normal;
        font-size: 16px;
      }
    }
  }
}
</style>
<style lang="scss">
.wwwd {
  height: calc(100vh - 6.4rem - 4rem) !important;
  /* 隐藏滚动条 */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari 和 Chrome */
  }
  p {
    font-size: 2rem !important;
  }
  .photo-wrap {
    width: 97%;
    height: calc(100% - 33px - 38px - 48px);
    display: flex;
    .img-wrap {
      width: 95%;
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari 和 Chrome */
      }
      .item {
        width: 100%;
        height: 100%;
        /* 隐藏滚动条 */
      }
    }
    .indicator-wrap {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;
      .shareBtn {
        position: absolute;
        top: 0;
        font-size: 2.4rem;
      }
      .indicator {
        display: block;
        width: 24px;
        height: 24px;
        font-size: 14px;
        border-radius: 50%;
        background-color: #fff;
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        &.active {
          background-color: #409eff;
          color: #fff;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
