<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a ></a>工具类</h2>
    <ul>
      <li><a href="#">标签操作</a></li>
      <li><a href="#">获取当前登录人信息</a></li>
      <li><a href="#">字典操作</a></li>
      <li><a href="#">时间格式化</a></li>
      <li><a href="#">拷贝</a></li>
      <li><a href="#">权限指令</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom35"
}
</script>

<style scoped>

</style>
