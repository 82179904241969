<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>短信配置</h1>
    <p> vue 集成 <a href="https://www.aliyun.com/product/smsm?spm=a3142.7791109.724463.3.34691fd2uYrEzR" title="阿里大鱼短信" target="_blank">阿里大鱼短信</a>，请先去官网注册账号。</p>
    <p>配置阿里大鱼账号<br>
      <img src="../../../assets/word/70.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom98"
}
</script>

<style scoped>

</style>
