<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="API_2"></a>通过API接口配置图表</h2>
    <p>接口返回的数据必须满足[{name, value}] 格式</p>
    <p>选择刚刚配置的数据集生成数据接口，复制json接口<br>
      <img src="../../../assets/word/142.png" alt="截屏20230119 21.10.23.png"></p>
    <p>配置图表<br>
      <img src="../../../assets/word/158.png" alt="截屏20230119 21.12.26.png"></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom130"
}
</script>

<style scoped>

</style>
