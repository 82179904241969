<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>内容国际化</h3>
    <p>页面内容国际化，使用$t2调用。</p>
    <p>例如:</p>
    <pre><code class="lang-language">$t2("t_login")
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom44"
}
</script>

<style scoped>

</style>
