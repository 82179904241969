<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>自定义样式</h3>
    <p>全局样式请定义在 @/style 目录下。<br>
      参照 <a href="/#/doc/detail/1585460201252515842/1588016920499388418" target="_blank">样式</a></p>
    <p>每一个页面的样式就写在当前 views下面，请记住加上scoped 或者命名空间，避免造成全局的样式污染。</p>
    <pre><code class="lang-">&lt;style lang="less"&gt;
/* 全局样式 */
&lt;/style&gt;

&lt;style lang="less" scoped&gt;
/* 当前文件有效 */
.xxx-container{
  /* name scoped */
  xxx
}
&lt;/style&gt;
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom21"
}
</script>

<style scoped>

</style>
