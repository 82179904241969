<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>国际化支持</h2>
    <ul>
      <li><a href="#">标签国际化</a></li>
      <li><a href="#">vxe-table的国际化</a></li>
      <li><a href="#">菜单国际化</a></li>
      <li><a href="#">内容国际化</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom42"
}
</script>

<style scoped>

</style>
