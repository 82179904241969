<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_2"></a>菜单按钮权限</h2>
    <p>采用的是前后端双重权限验证，前端用来控制显示，后端用来控制安全性（防止postman等工具直接调用api进行攻击等行为）。</p>
    <h3><a id="1__7"></a>1. 实现原理</h3>
    <h4><a id="SpringScurity_8"></a>SpringScurity是什么？</h4>
    <p>Spring Security是一个能够为基于Spring的企业应用系统提供声明式的安全访问控制解决方案的安全框架。它提供了一组可以在Spring应用上下文中配置的Bean，充分利用了Spring IoC，DI（控制反转Inversion of Control ,DI:Dependency Injection 依赖注入）和AOP（面向切面编程）功能，为应用系统提供声明式的安全访问控制功能，减少了为企业系统安全控制编写大量重复代码的工作。</p>
    <h3><a id="2_SpringSecurity_11"></a>2. SpringSecurity在中的应用</h3>
    <h4><a id="_PreAuthorize_13"></a>步骤一 在后台使用注解@PreAuthorize注解控制方法的访问权限</h4>
    <pre><code class="lang-language">@PreAuthorize("hasAnyAuthority('test:one:testFormLeave:view','test:one:test')
</code></pre>
    <p>添加多个权限标志代表只要拥有其中任何一个权限标志就可以访问该方法。</p>
    <h4><a id="_hasPermission_19"></a>步骤二 在前台通过hasPermission方法控制按钮的显示</h4>
    <pre><code class="lang-language"> &lt;el-button  size="small" v-if="hasPermission(test:one:testFormLeave:view')" @click="edit(row.id)"&gt;修改&lt;/el-button&gt;
</code></pre>
    <h4><a id="__26"></a>步骤三 给菜单设置权限标志</h4>
    <p><img src="../../../assets/word/159.png" alt=""></p>
    <h4><a id="__29"></a>步骤四 将包含权限标志的菜单分配给角色</h4>
    <p><img src="../../../assets/word/147.png" alt=""></p>
    <h4><a id="__31"></a>步骤五 给人员分配角色。</h4>
    <p><img src="../../../assets/word/176.png" alt=""></p>
    <p>如此就实现了控制不同的人员访问不同的功能。</p>
    <h3><a id="3__35"></a>3. 关键代码解析</h3>
    <p>整体逻辑，用户登录之后通过角色菜单关联关系获取权限标记集合，将该权限赋给springSecurity,以及将该权限集合信息通过获取用户信息接口返回给前台存储在localStorage中，然后就实现了前后台双重权限控制，下面是关键实现代码。</p>
    <p>后端代码</p>
    <pre><code class="lang-language">    public static Authentication getAuthentication(String token) {
        DecodedJWT jwt = JWT.decode(token);
        String username =  jwt.getClaim("username").asString();

        // 权限集合
        List &lt;GrantedAuthority&gt; authorities = new ArrayList &lt;&gt; ();
        // 得到用户角色
        String userName = TokenProvider.getLoginName(token);
        Set &lt;String&gt; permissions = SpringUtil.getBean ( IUserApi.class ).getPermissions (userName);
        // 添加基于Permission的权限信息
        for (String permission : permissions){
            authorities.add(new SimpleGrantedAuthority (permission));
        }

        org.springframework.security.core.userdetails.User principal = new org.springframework.security.core.userdetails.User (username, "", authorities);

        return new UsernamePasswordAuthenticationToken (principal, token, authorities);
    }
</code></pre>
    <p>前端代码</p>
    <pre><code class="lang-language">export function hasPermission(permission) {
	let permissions = tool.data.get("PERMISSIONS");
	if(!permissions){
		return false;
	}
	let isHave = permissions.includes(permission);
	return isHave;
}
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom63"
}
</script>

<style scoped>

</style>
