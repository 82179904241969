<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a ></a>java环境搭建</h1>
    <h2><a ></a>开发工具：idea/eclipse/myeclipse + mysql/oracle/sqlserver/postgresql + tomcat6/7/8/9。</h2>
    <p>下面以eclipse/idea为例，讲述的部署，其他工具类似, 在这里强烈推荐idea。</p>
    <h3><a ></a>1 导入maven项目</h3>
    <h4><a ></a>1.1 Eclipse</h4>
    <p>在Eclipse中，选择File-&gt;Import；接着如下图所示<br>
      <img src="../../../assets/word/114.png" alt=""></p>
    <p>点击 Browse,选择项目所在的文件夹，然后Finish即将项目添加进来。</p>
    <p><img src="../../../assets/word/112.png" alt=""></p>
    <h4><a ></a>1.2 Idea</h4>
    <p>在IDEA中， File-&gt;Open, 选择后台工程目录，打开<br>
      <img src="../../../assets/word/166.png" alt=""></p>
    <h3><a ></a>2 修改数据库链接</h3>
    <p>打开/-web/src/main/resources/application.yml<br>
      <img src="../../../assets/word/167.png" alt=""></p>
    <h3><a ></a>3 启用redis缓存配置</h3>
    <p><img src="../../../assets/word/179.png" alt=""></p>
    <p>启动redis<br>
      <img src="../../../assets/word/113.jpg" alt=""></p>
    <p><img src="../../../assets/word/115.png" alt=""></p>
    <h3><a></a>4 使用application命令启动</h3>
    <p>直接运行/-web/src/main/java/com//web/WebApplication.java，启动.</p>
    <p>启动画面<br>
      <img src="../../../assets/word/5.png" alt=""><br>
      直到控制台输出“–启动成功–",项目就可以成功访问了。</p>
    <h3><a id="4_license_40"></a>4 输入license。</h3>
    <p>登录系统，打开表单配置，可以看见机器码，使用序列号获取license，打开application-development.yml，输入productID和license就可以正常使用代码生成器功能了。<br>
      <img src="../../../assets/word/16.png" alt=""><br>
      注意：license仅仅授权代码生成器（敏捷开发-表单配置）是否可用。发布、部署、交付给第三方，无需license。开发出的项目可以任意部署，不受license控制。平台底层代码、业务功能代码都是开源的。</p>
    <h3><a id="5__44"></a>5 打包部署。</h3>
    <ol>
      <li>先在根目录下执行 install命令，将各个模块打包到maven仓库，如果不执行这个命令，在打war包时，可能报依赖的模块找不到的错误，或者将旧的jar包模块打进war包中。</li>
      <li>然后在-web目录下执行install命令，打出war包。</li>
      <li>部署到云服务器后，更新插件或者模块只需要替换/lib目录下对应的jar包即可，无需整个war包替换。</li>
    </ol>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom7"
}
</script>

<style scoped>

</style>
