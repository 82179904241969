<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a ></a>开发环境准备</h2>
    <p>开发环境除了后端开发工具idea（推荐)、前端开工具vscode(推荐)外，还需要安装以下服务，推荐以docker方式安装。</p>
    <h3><a ></a>1. 安装minio （非必须，可跳过，参考<a href="#" target="_blank">文件服务</a>文档,如果采用本地硬盘存储文件可跳过）</h3>
    <p>文件服务默认使用minio， 也可以使用本地存储/阿里云存储。</p>
    <h4><a id="11__11"></a>1.1 拉取镜像</h4>
    <pre><code class="lang-">docker pull minio/minio
</code></pre>
    <h4><a id="12_15"></a>1.2建立目录</h4>
    <p>在E盘下建一个minio目录，然后进入minio目录，建立config和data目录。</p>
    <h4><a id="13_minio_19"></a>1.3 启动minio</h4>
    <pre><code class="lang-">docker run --name minio -p 9000:9000 -p 9001:9001 -d --restart=always -e "MINIO_ACCESS_KEY=admin" -e "MINIO_SECRET_KEY=admin123456" -v "E:\minio\data":"/data" -v "E:\minio\config":"/root/.minio" minio/minio server /data --console-address ":9001"
</code></pre>
    <h4><a id="14__26"></a>1.4 查看服务</h4>
    <pre><code class="lang-">docker ps -a
</code></pre>
    <p>如果发现报错，可以先删除这个容器，重新创建即可。<br>
      docker rm -f 容器id</p>
    <h4><a id="15__34"></a>1.5 登录后台</h4>
    <p>通过地址栏输入http://localhost:9001，然后输入用户名密码admin/admin123456，即可访问到后台，然后创建name为的bucket。<br>
      <img src="../../../assets/word/110.png" alt=""></p>
    <h4><a id="16_fileserverminio_38"></a>1.6 在file-server中使用minio存储。</h4>
    <p>在-file中文件存储<a href="#">使用minio</a></p>
    <h3><a id="2_redis_44"></a>2. 安装redis</h3>
    <p>缓存服务使用redis。</p>
    <h4><a id="21_redis_46"></a>2.1 拉取redis镜像</h4>
    <p>docker pull redis</p>
    <h4><a id="22_redis_48"></a>2.2 安装redis</h4>
    <p>执行以下命令安装<br>
      创建映射目录和配置文件</p>
    <pre><code class="lang-">mkdir -p /mydata/redis/conf
touch /mydata/redis/conf/redis.conf
</code></pre>
    <p>映射目录和docker容器中的目录关联映射</p>
    <pre><code class="lang-">docker run -p 6379:6379 --name redis -v /mydata/redis/data:/data \
-v /mydata/redis/conf/redis.conf:/etc/redis/redis.conf \
-d redis redis-server /etc/redis/redis.conf
</code></pre>
    <p>检查是否安装完成</p>
    <pre><code class="lang-">docker ps
</code></pre>
    <p>修改配置文件 redis.conf 选择对应redis版本下载redis.conf<br>
      我的redis.conf 内容初步如下（需要其他什么配置，以后再配）</p>
    <pre><code class="lang-">bind 0.0.0.0 #支持任意ip访问
daemonize yes #开启支持redis后台运行
appendonly yes #开启aof
protected-mode no#关闭protected-mode模式，此时外部网络可以直接访问 开启protected-mode保护模式，需配置bind ip或者设置访问密码
requirepass !@WxzePt$ #设置redis密码，不要设置太过简单
</code></pre>
    <h4><a id="23_redis_81"></a>2.3 重启redis命令</h4>
    <pre><code class="lang-">docker restart redis
</code></pre>
    <h4><a id="24_redis_86"></a>2.4 进入redis控制台命令</h4>
    <pre><code class="lang-">docker exec -it redis redis-cli
</code></pre>
    <h4><a id="25_dockerredis_91"></a>2.5 设置docker重启时，redis容器也自动重启</h4>
    <pre><code class="lang-">docker update redis --restart=always
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom5"
}
</script>

<style scoped>

</style>
