<template>
  <div class="ccddd">
    <!-- 左侧头部 -->
    <!-- <header>
      <img src="../../assets/logored.png" @click="homeclick" />
      <span style="font-size: 1.5rem; color: #409eff; margin-left: 1rem; overflow-x: hidden; overflow-y: hidden">智慧差旅操作手册</span>
      <div style="flex: 1; display: flex; margin-left: 20rem"></div>
    </header> -->
    <div class="wordcontnt" style="background-color: #f5f7f9">
      <splitpanes class="default-theme" style="background-color: #f5f7f9">
        <pane size="20" style="overflow-y: auto; height: 100%; background-color: #f5f7f9">
          <el-container class="jp-container" style="background-color: #f5f7f9">
            <span style="font-size: 2rem; color: #409eff; padding: 10px 20px;">智慧差旅操作手册</span>
            <div style="margin-top: 5px; margin-left: 1rem; display: flex; align-items: center;">
              <el-autocomplete
                style="border-color: #000; border-radius: 3rem; width: 85%;"
                class="inline-input"
                size="small"
                v-model="queryStr"
                :fetch-suggestions="querySearch"
                placeholder="请输入关键字查询"
                :trigger-on-focus="true"
                @keyup.enter.native="getClList"></el-autocomplete>
              <div
                class="el-icon-search"
                style="margin-left: 5px; font-size: 2rem; flex: 1; height: 100%; cursor: pointer"
                @click="getClList"></div>
            </div>
            <el-tree
              style="margin-left: 1rem; background-color: #f5f7f9"
              class="filter-tree"
              :data="menuList"
              :props="defaultProps"
              node-key="name"
              :default-expanded-keys="defaultExpandedKeys"
              :default-checked-keys="defaultCheckedKeys"
              :current-node-key="currentNodeKey"
              @node-click="handleNodeClick"
              ref="menuTree">
            </el-tree>
          </el-container>
        </pane>
        <pane size="85" style="height: 100%; overflow: hidden; background-color: white">
          <el-container class="jp-container" style="background-color: white">
            <ClWordRight
              v-if="flag === 'one' && loadingOne"
              style="width: calc(100% - 9rem); height: 100%; display: block; overflow-y: auto; background-color: #f2f6fc"
              :clDetail="clOne"
              :Clmessage="clOnemessage">
            </ClWordRight>
            <ClWordRightList
              v-if="flag === 'list' && loadingList"
              style="width: calc(100% + 5rem); height: 100%; display: block; overflow-y: auto; background-color: white"
              :queryString="queryStr"
              @setMenuTree="setMenuTree">
            </ClWordRightList>
          </el-container>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import newService from '@/api/newService'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import ClWordRight from '@/components/clword/ClWordRight.vue'
import ClWordRightList from '@/components/clword/ClWordRightList.vue'

export default {
  name: 'ClWordIndex',
  components: {
    Splitpanes,
    Pane,
    ClWordRight,
    ClWordRightList,
  },
  data() {
    return {
      treeFilterText: '',
      clOnemessage: '',
      loadingList: false,
      flag: 'one',
      loadingOne: false,
      queryStr: '',
      restaurants: [],
      rightdata: [],
      menuList: [],
      clOne: {},
      cehtml: '',
      checkedkeys: [],
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        id: 'id',
      },
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      currentNodeKey: '',
    }
  },
  created() {
    this.getMenuList()
  },
  mounted() {
    // this.isGetClHelpOne()
  },
  methods: {
    isGetClHelpOne(indexId) {
      let query = location.search
      let searchParams = new URLSearchParams(query)

      if (!searchParams.has('id')) {
        if (searchParams.has('queryStr')) {
          const queryString = searchParams.get('queryStr')
          this.queryStr = queryString
          this.getClList()
          return
        }
        // 默认查询 出差申请-一般员工出差
        this.getClHelpOne(indexId)
        return
      }
      //到这表示有id但无模糊查
      const id = searchParams.get('id')
      this.getClHelpOne(id)
    },
    // 只配置了一个
    getClHelpOne(id) {
      this.loadingList = false
      this.loadingOne = false
      let query = location.search
      let searchParams = new URLSearchParams(query)
      let zhClToken = searchParams.get('zhClToken')
      const obj = {
        id: id,
        zhClToken: zhClToken,
      }
      newService.getCLHelpOne(obj).then(res => {
        if (res === '无权限，请重新由差旅系统访问' || res === null) {
          this.$message.error(res)
          return
        }
        if (res === '暂无数据请联系等待管理员添加') {
          this.clOnemessage = res
          this.flag = 'one'
          setTimeout(() => {
            this.loadingOne = true
          }, 1500)
          return
        }
        this.clOnemessage = 'hasdata'
        this.clOne = res
        this.setMenuTree(this.clOne)
      })
      this.flag = 'one'
      setTimeout(() => {
        this.loadingOne = true
      }, 1500)
    },
    // 设置左侧tree选中和展开
    setMenuTree(res) {
      this.defaultExpandedKeys = []
      this.defaultCheckedKeys = []
      const nodes = this.$refs.menuTree.getNode(res.flabelName)
      this.defaultExpandedKeys = [nodes.data.name]
      this.defaultCheckedKeys = [nodes.data.name]
    },
    // 左侧菜单
    getMenuList() {
      let query = location.search
      let searchParams = new URLSearchParams(query)
      const zhClToken = searchParams.get('zhClToken')

      newService.getClLabelList(zhClToken).then(res => {
        if (res === '无权限，请重新由差旅系统访问' || res === null) {
          this.$message.error(res)
          return
        }
        const reg = new RegExp('\\,"children":\\[]', 'g')
        // 反斜杠
        // 在对树结构进行替换
        this.menuList = JSON.parse(JSON.stringify(res).replace(reg, ''))
        // 自动获取第一页菜单数据有菜单时（仅支持2级菜单）
        if (this.menuList && this.menuList.length > 0) {
          if (this.menuList[0].children && this.menuList[0].children.length > 0) {
            // 有子项时
            this.isGetClHelpOne(this.menuList[0].children[0].id)
          } else {
            // 无子项取第一菜单的id
            this.isGetClHelpOne(this.menuList[0].id)
          }
        }
      })
    },
    getClList() {
      this.loadingList = false
      this.loadingOne = false
      this.flag = 'list'
      setTimeout(() => {
        this.loadingList = true
      }, 1500)
    },
    textContentclick() {
      this.loadingList = false
      this.loadingOne = false
      let query = location.search
      let searchParams = new URLSearchParams(query)

      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        queryStr: this.queryStr,
        zhClToken: searchParams.get('zhClToken'),
      }
      newService.getClHelpList(obj).then(res => {
        this.rightdata = res.rows
      })
      this.flag = 'list'
      setTimeout(() => {
        this.loadingList = true
      }, 1500)
    },
    homeclick() {
      this.$emit('homeclick')
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleNodeClick(e) {
      const a = this.$refs.menuTree.getNode(e.name)
      if (a.childNodes.length === 0) {
        this.getClHelpOne(e.id)
        this.loadingOne = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ccddd {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  // header {
  //   height: 6.4rem;
  //   line-height: 6.4rem;
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   z-index: 99;
  //   display: flex;
  //   justify-content: start;
  //   color: #000;
  //   box-shadow: 0px 2px 6px 0px white;
  //   img {
  //     height: 2.7rem;
  //     margin: 2rem 3rem 2rem 4rem;
  //     cursor: pointer;
  //   }
  // }
  .wordcontnt {
    // margin-top: 6.4rem;
    // height: calc(100vh - 6.4rem);
    height: 100vh;
    display: flex;
    overflow: hidden;
  }
}
.jp-container {
  // margin: -10px;
  display: flex;
  flex-direction: column;
}
.el-tree {
  background: #f5f7f9 !important;
  padding: 1rem;
}
.el-tree-node__content {
  height: 6rem !important;
  font-size: 2.4rem;
}
.el-tree-node:focus > .el-tree-node__content {
  color: #409eff !important;
}
.is-current {
  color: #007aff;
}
.is-checked {
  color: #007aff;
}
</style>
