<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>打包发布</h1>
    <h2><a ></a>1.  tomcat部署</h2>
    <h3><a ></a>一、 后台工程 vue 打包</h3>
    <p>1 修改application-production.yml文件</p>
    <p>1.1 修改数据库连接为线上服务器的数据库连接</p>
    <pre><div class="hljs"><code class="lang-xml">      datasource:
        master:
          username: root
          password: root
          url: jdbc:mysql://127.0.0.1:3306/useUnicode=true&amp;characterEncoding=utf-8&amp;useSSL=false&amp;nullCatalogMeansCurrent=true&amp;serverTimezone=GMT
          driver-class-name: com.mysql.cj.jdbc.Driver
</code></div></pre>
    <p>1.2 修改log级别为ERROR</p>
    <pre><div class="hljs"><code class="lang-xml">logging:
  level:
    root: ERROR
    #    org.flowable: ERROR
    com.: ERROR
    org.apache.shiro.cache.ehcache.EhCacheManager: ERROR
</code></div></pre>
    <p>1.3 上传文件路径设置</p>
    <pre><div class="hljs"><code class="lang-xml">userfiles.basedir: /opt/dir           //设置linux上的实际路径，如果是windowsserver 服务器，同样请设置绝对路径



</code></div></pre>
    <p>注意事项：<br>
      如果只使用tomcat同时部署前后端，需要配置tomcat的虚拟映射路径(如果是nginx部署前端请忽略此配置，在nginx中配置)。<br>
      打开server.xml, 在<code>&lt;HOST&gt;&lt;/HOST&gt;</code>节点下添加虚拟映射路径<br>
      <code>&lt;Context docBase="/opt/dir/userfiles/" path="/userfiles/" /&gt;</code>，如果不添加则文件不可访问。</p>
    <p>2 修改 application.yml文件<br>
      切换配置为生产环境配置</p>
    <pre><div class="hljs"><code class="lang-xml">spring:
  profiles:
    active: production
</code></div></pre>
    <p>3 打包</p>
    <p>首先 ，在根目录vue目录下，执行install</p>
    <p>然后，在web目录下，执行package</p>
    <p>在web目录下的target目录下会生成一个vue.war文件，这就是后台部署程序。</p>
    <p>二、前台工程ui打包发布</p>
    <p>进入ui根目录</p>
    <p>1 先执行cnpm install命令。</p>
    <p>2 打开.env.production文件，进行生产环境配置</p>
    <pre><div class="hljs"><code class="lang-xml"># 生产环境配置
ENV = 'production'

#快速开发平台/后台地址
VUE_APP_SERVER_URL = 'http://xxxx/vue'
</code></div></pre>
    <p>3 在ui目录下，执行打包命令：npm run build， 生成一个dist目录就是前台的部署程序。</p>
    <p>三、线上部署。</p>
    <p>本简化版本部署方案只使用tomcat + mysql。</p>
    <p>1 在linux服务器上，安装tomcat，mysql。</p>
    <p>2 设置mysql数据库不区分大小写。<br>
      Linux 系统默认情况下MySQL数据库是区分大小写的；因此要将mysql设置成不区分大小写，在/etc/my.cnf 配置后面加上一行（0：大小写敏感；1：大小写不敏感）</p>
    <pre><div class="hljs"><code class="lang-xml">lower_case_table_names=1
</code></div></pre>
    <p>最后重启一下MySql服务即可</p>
    <p>3 用utf8编码在服务器上创建数据库，并执行.sql脚本将数据导入。</p>
    <pre><div class="hljs"><code class="lang-xml">CREATE DATABASE  ``  DEFAULT CHARACTER SET utf8 COLLATE utf8_general_ci;
use ;
source .sql;
</code></div></pre>
    <p>4 将dist目录下的前端部署程序（不包括dist目录）拷贝到tomcat的webapps/ROOT目录下。</p>
    <p>5  将vue.war拷贝到tomcat的webapps目录下。（注意后台的访问路径应该和.env.production中配置的后台访问路径完全一致）</p>
    <p>6 如果你使用了redis缓存，请启动redis服务器，没使用请跳过。（在application.yml中默认配置使用了ehcache缓存，你可以切换成redis缓存)</p>
    <p>7 启动tomcat。</p>
    <p>注意：如果你部署在公网，强力推荐使用cdn对前端资源进行加速，可以大大提高用户首次访问系统的速度。</p>
    <h2><a id="2__nginxtomcat_102"></a>2.  nginx+tomcat部署</h2>
    <p>你可以分开部署vue后端程序和ui前端程序。</p>
    <p>后端部署在tomcat请参照章节1.</p>
    <p>本部分讲解如何将ui部署在nginx。</p>
    <p>1 拷贝dist下的内容到nginx目录下的html目录下。</p>
    <p>2 nginx配置</p>
    <p>nginx配置, 打开conf/nginx.conf<br>
      开启nginx压缩</p>
    <pre><div class="hljs"><code class="lang-xml">  gzip on;
    gzip_min_length 1k;
    gzip_comp_level 9;
    gzip_types text/plain application/javascript application/x-javascript text/css application/xml text/javascript application/x-httpd-php image/jpeg image/gif image/png;
    gzip_vary on;
    gzip_disable "MSIE [1-6]\.";
</code></div></pre>
    <p>注意，一定要配置userfiles，否则附件将不能正常显示。</p>
    <pre><div class="hljs"><code class="lang-xml">        server {
        listen    80;
        server_name  xxxxxx.com;
        client_max_body_size 100m;

        location /vue/ {
            proxy_pass              http://127.0.0.1:8080/vue/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }

        location /userfiles/ {
            proxy_pass              http://127.0.0.1:8080/vue/userfiles/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }



        location / {
                root   /usr/share/nginx/html;
                index  index.html index.htm;
        }
    }
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom100"
}
</script>

<style scoped>

</style>
