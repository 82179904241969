<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><p>流程提供了强大的自定义按钮来实现流程节点的任意跳转。</p>
    <p>背景：系统提供的默认按钮，提交，驳回，驳回到任意节点，无法灵活的实现节点之间的任意跳转，现在我们通过自定义按钮来实现节点之间的灵活跳转。</p>
    <p>1 添加自定义按钮<br>
      <img src="../../../assets/word/137.png" alt=""><br>
      除了系统按钮【驳回】之外，我们定义了两个自定义按钮，【跳转到任务节点1】，【跳转到任务节点2】。切记，自定义按钮不能以_flow_开头。区别在于，_flow_开头的编码不会作为流程变量提交，所以系统按钮需要对应的代码实现功能，而自定义按钮可以通过提交流程变量实现对应功能。</p>
    <p>2  添加一个互斥网关，并设置流程跳转条件。<br>
      <img src="../../../assets/word/116.png" alt=""><br>
      <img src="../../../assets/word/117.png" alt=""></p>
    <p>流程条件{to_one}、{to_two}分别对应流程自定义按钮的编码。</p>
    <p>在【用户选择】节点，即可实现通过点击不同的按钮跳转到不同的任务节点的功能。</p>
    <p>原理解释：流程自定义按钮和系统按钮的区别在于：如果你定义了流程自定义按钮，在这个步骤，你点击任意按钮， 都会把流程自定义按钮对应的编码当作流程变量提交。<br>
      当点击编码对应的自定义按钮时，它对应的编码作为流程变量赋值为true，否则会false。以本例为例：<br>
      点击【驳回】按钮，系统会提交两个流程变量， to_one:false, to_two:false。<br>
      点击【跳转到任务节点1】，系统会提交两个流程变量， to_one:true, to_two:false。点击【跳转到任务节点2】，系统会提交两个流程变量， to_one:false, to_two:true。<br>
      因此实际是通过流程分支条件实现了节点的任意跳转。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom108"
}
</script>

<style scoped>

</style>
