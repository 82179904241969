<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h1><a ></a>设置环境</h1>
    <ul>
      <li><a href="#">开发环境准备</a></li>
      <li><a href="#">前端搭建运行</a></li>
      <li><a href="#">后端搭建运行</a></li>
      <li><a href="#">数据库配置</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom4"
}
</script>

<style scoped>

</style>
