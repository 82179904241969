<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>接口开发</h1>
    <p>集成JWT token ， 使用 swagger，进行接口管理和测试<br>
      <img src="../../../assets/word/72.png" alt=""></p>
    <h2><a id="1_4"></a>1.传输格式</h2>
    <p>传输格式均为JSON字符串，并自动通过fasterxml jackson工具类（JsonMapper.java, AjaxJson, PrintJSON）进行对象到JSON转换。<br>
      输出格式符合JSON标准，UTF-8编码。</p>
    <h2><a id="2_7"></a>2.账号登录</h2>
    <p>http://localhost:8080/sys/login</p>
    <h2><a id="POST_10"></a>POST参数:</h2>
    <blockquote></blockquote>
    <table>
      <thead>
      <tr>
        <th style="text-align:left">参数名</th>
        <th style="text-align:left">是否必须</th>
        <th style="text-align:left">类型</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style="text-align:left">userName</td>
        <td style="text-align:left">是</td>
        <td style="text-align:left">string</td>
        <td>用户名</td>
      </tr>
      <tr>
        <td style="text-align:left">password</td>
        <td style="text-align:left">是</td>
        <td style="text-align:left">string</td>
        <td>密  码</td>
      </tr>
      </tbody>
    </table>
    <h3><a id="_18"></a>返回结果</h3>
    <h4><a id="_19"></a>登录成功</h4>
    <pre><div class="hljs"><code class="lang-json"><span class="hljs-punctuation">{</span>
  <span class="hljs-attr">"msg"</span><span class="hljs-punctuation">:</span> <span class="hljs-string">"操作成功"</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"code"</span><span class="hljs-punctuation">:</span> <span class="hljs-number">200</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"success"</span><span class="hljs-punctuation">:</span> <span class="hljs-keyword">true</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"token"</span><span class="hljs-punctuation">:</span> <span class="hljs-string">"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODYyMzg3MDgsInVzZXJuYW1lIjoiYWRtaW4ifQ.NBa-phR20nknKWb5wJK63yuOVdxckUHZrSM6jedZYcg"</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"refreshToken"</span><span class="hljs-punctuation">:</span> <span class="hljs-string">"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODY0MTE1MDgsInVzZXJuYW1lIjoiYWRtaW4ifQ.I7_x5Riky7OVBubkwt9XE5Q4jWN8RX7k8-k7Y-Uvbzw"</span>
<span class="hljs-punctuation">}</span>
</code></div></pre>
    <h4><a id="_29"></a>登录失败</h4>
    <pre><div class="hljs"><code class="lang-json"><span class="hljs-punctuation">{</span>
  <span class="hljs-attr">"msg"</span><span class="hljs-punctuation">:</span> <span class="hljs-string">"用户名或者密码错误!"</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"code"</span><span class="hljs-punctuation">:</span> <span class="hljs-number">200</span><span class="hljs-punctuation">,</span>
  <span class="hljs-attr">"success"</span><span class="hljs-punctuation">:</span> <span class="hljs-keyword">false</span>
<span class="hljs-punctuation">}</span>
</code></div></pre>
    <h2><a id="3_39"></a>3.账号退出</h2>
    <p>http://127.0.0.1:8080/sys/logout</p>
    <h2><a id="4__41"></a>4 请求数据</h2>
    <p>请求rest 接口，有2种，一种需要登录才能访问。</p>
    <h3><a id="41_45"></a>4.1登录访问</h3>
    <p>先调用登录接口，会获取token。<br>
      token支持三种方式传输。</p>
    <ul>
      <li>在header中添加token （推荐)</li>
      <li>在cookie中添加token</li>
      <li>在请求参数中附带token参数。<br>
        例如：http://127.0.0.1:8080/sys/user/getPermissions?token=xxxxxxx</li>
    </ul>
    <h3><a id="42_54"></a>4.2不登录访问</h3>
    <p>免登陆访问接口请参照<br>
      <a href="/#/doc/detail/1585460201252515842/1585462173653331969">免登陆配置</a></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom93"
}
</script>

<style scoped>

</style>
