<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a></a>开发规范</h2>
    <ul>
      <li><a href="#">新建路由</a></li>
      <li><a href="#">新建view</a></li>
      <li><a href="#">新建API</a></li>
      <li><a href="#">新建组件</a></li>
      <li><a href="#">自定义样式</a></li>
      <li><a href="#">自定义图标</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom16"
}
</script>

<style scoped>

</style>
