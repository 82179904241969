<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>打包部署</h1>
    <ul>
      <li><a href="#">Nginx配置</a></li>
      <li><a href="#">打包</a></li>
      <li><a href="#">快速部署</a></li>
      <li><a href="#">docker部署</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom99"
}
</script>

<style scoped>

</style>
