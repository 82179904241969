<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>单点登录流程</h1>
    <h2><a id="CAS_2"></a>CAS原理</h2>
    <p>用户通过浏览器访问系统A（CAS Client）,系统A 检查本地有没有token，就是登录信息，如果没有就带着访问地址去认证中心（CAS Service）检查有没有全局Session ,如果没有返回登录页面让用户登录，此时还带着那个访问系统A的地址，登录完成后认证中心保存全局的Session ,去刚刚访问系统A的地址，外加一个令牌(ticket)，系统A 收到令牌后向认证中心发起验证，通过后保存本地token. 有本地token后就可以直接和浏览器交互了。<br>
      用户访问系统B，系统B检查有没有本地token ,如果没有带着访问地址去认证中心，认证中心发现有全局session ，返回访问系统B 的地址，外加令牌。系统B 收到令牌，向认证中心校验，通过后保存本地token。</p>
    <h2><a id="_Vue__6"></a> Vue 单点登录流程</h2>
    <ul>
      <li>1 浏览器打开vue前端</li>
      <li>2 判断系统是否登录（token是否存在)，如果没登录，跳转到登录方法</li>
      <li>3 在登录方法中判断，如果开启了单点登录，跳转到cas服务器https://www.casserver.com:8443/cas/login?service=http://localhost:3000/#/casLogin</li>
      <li>4 cas服务器登录成功，返回到vue前端，并携带ticket参数 http://localhost:3000/?ticket=ST-1321-ao7yt-cxSmwPyrS6UnI8q96aVGMlocalhost#&amp;name=casLogin</li>
      <li>5 在当前返回页中casLogin.vue中，获取ticket令牌，并发送http请求去java后台验证ticket是否合法，如果非法，抛出ticket是无效的票根异常。</li>
      <li>6 ticket验证通过，解析ticket获取用户loginName, 如果loginName对应的用户不存在，抛出用户不存在异常，否则 创建并返回token。</li>
      <li>7 vue前端获取token参数 登录成功。</li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom113"
}
</script>

<style scoped>

</style>
