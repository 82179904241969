<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_1"></a>布局</h3>
    <p>布局入口文件：/src/layout/index.vue</p>
    <p> 提供了四套布局样式如下：</p>
    <h4><a id="_8"></a>布局一</h4>
    <p><img src="../../../assets/word/7.png" alt=""></p>
    <h4><a id="_11"></a>布局二</h4>
    <p><img src="../../../assets/word/2.png" alt=""></p>
    <h4><a id="_13"></a>布局三</h4>
    <p><img src="../../../assets/word/181.png" alt=""></p>
    <h4><a id="_15"></a>布局四</h4>
    <p><img src="../../../assets/word/144.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom46"
}
</script>

<style scoped>

</style>
