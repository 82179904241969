<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a></a>新建路由（动态路由）</h3>
    <h4><a id="srcrouterindexjs_6"></a>文件位置：src/router/index.js</h4>
    <p>动态路由需要在【系统管理】-【菜单管理】进行添加/修改/删除操作，用户登录时，会读取有权限访问的路由表，前端根据从后台获取的路由表，动态构建用户可以访问的路由。</p>
    <p>例如：<br>
      前端文件 src/views/echarts/dashboard/DashboardList.vue；<br>
      在菜单管理中 配置链接地址</p>
    <pre><code class="lang-language">/database/datalink/DataSourceList
</code></pre>
    <p>即可动态添加该路由。</p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom17"
}
</script>

<style scoped>

</style>
