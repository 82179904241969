<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_1"></a>数据库表设置</h3>
    <p><img src="../../../assets/word/104.png" alt=""></p>
    <ul>
      <li>表名：物理表表名</li>
      <li>所属数据库：表所在的数据库。（数据库链接请在：数据管理-》数据库连接中添加）</li>
      <li>说明：物理表表描述</li>
      <li>主键策略：支持2种主键类型，UUID和自增</li>
      <li>表类型：单表，主表/附表，数结构表，左树/右表</li>
      <li>类名：生成表关联的实体类名称(必须大写)</li>
      <li>主表表名：关联主表的表名</li>
      <li>当前表外键：当前表关联主表的主键，如果当前表为附表，需在此指定主表及外键。外键字段需在页面属性中设置Java属性名为对象.主键，代码生成器会自动转化格式，请勿修改。（例如：外键user_id对应的java属性为user.id）</li>
      <li>拖动：可以单击行选中拖拽进行排序。</li>
      <li>列名：数据表定义的字段名称</li>
      <li>说明：数据表定义的字段注释</li>
      <li>物理类型：数据表定义字段类型（可以手动修改类型和大小，比如将varchar(64)修改为varchar(128)）</li>
      <li>主键：是否是主键字段</li>
    </ul>
    <h3><a id="_19"></a>默认创建的字段</h3>
    <ol>
      <li>id:主键  （必须有且只能用id）</li>
      <li>create_by_id:创建者  （可以删除，但是建议保留，通过此字段可以知道创建者，数据权限可能会使用到，比如只能查看自己的数据）</li>
      <li>create_date:创建时间 （可以删除，建议保留）</li>
      <li>update_by_id:更新者 （可以删除，建议保留）</li>
      <li>update_date:更新时间 （可以删除，建议保留）</li>
      <li>del_flag: 逻辑删除标记（0显示，1隐藏））</li>
      <li>remarks:备注信息（可以删除，建议保留).</li>
    </ol>
    <p>说明： 创建单表时，推荐使用默认的字段配置。<br>
      设置表名，字段名，物理类型名时，请注意符合数据库的规则，生成表单时，对这些字段未做合法性判断（因为存在各种情况，过于复杂）。如果存在非法的字段，同步数据库时会报错，请根据报错信息修改对应字段即可。<br>
      <br></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom76"
}
</script>

<style scoped>

</style>
