<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h2><a ></a>vue前端运行环境搭建</h2>
    <p><strong>1、安装node.js（https://nodejs.org/en/）</strong><br>
      <img src="../../../assets/word/11.png" alt=""><br>
      下载完毕后，可以安装node，建议不要安装在系统盘（如C：）。</p>
    <p><strong>2、下载安装vscode</strong></p>
    <h4><a id="21_ui_14"></a>2.1 打开前端项目-ui</h4>
    <p><img src="../../../assets/word/14.png" alt=""></p>
    <h4><a id="22envdevelopment_apigateway_17"></a>2.2、打开.env.development ，配置开发环境的后台api地址(后台-gateway的地址）</h4>
    <p><img src="../../../assets/word/3.png" alt=""></p>
    <pre><code class="lang-">VUE_APP_SERVER_URL = 'http://localhost:8088'
</code></pre>
    <h4><a id="23TERMIANL_25"></a>2.3、组织结构图（选择语言安装）</h4>
      <pre><code class="lang-">npm i vue3-tree-org -S / yarn add vue3-tree-org
</code></pre>
      <pre><code class="lang-">npm i vue3-tree-org -S --legacy-peer-deps
</code></pre>
      <h4><a id="23TERMIANL_25"></a>2.4、打印功能</h4>
      <p>// vue3 安装</p>
      <pre><code class="lang-">npm install vue3-print-nb
</code></pre>
      <p>// 或</p>
      <pre><code class="lang-">yarn add vue3-print-nb
</code></pre>
      <p>组织结构</p>
      <pre><code class="lang-">npm install --save html2canvas
</code></pre>
    <h4><a id="23TERMIANL_25"></a>2.5、打开TERMIANL控制台</h4>
    <p>
      执行以下命令安装package依赖包</p>
    <pre><code class="lang-">yarn install
</code></pre>
    <p>执行启动命令</p>
    <pre><code class="lang-">yarn dev

</code></pre>
    <p>启动成功界面<br>
      <img src="../../../assets/word/12.png" alt=""></p>
    <h3><a id="_44"></a>常见错误一</h3>
    <p>Error: error:0308010C:digital envelope routines::unsupported</p>
    <p>原因： node v17中的OpenSSL3.0对允许算法和密钥大小增加了严格的限制，可能会对生态系统造成一些影响。在node v17以前一些可以正常运行的的应用程序,但是在 V17 版本可能会抛出以下异常:</p>
    <p>解决办法：命令终端运行以下代码：export NODE_OPTIONS=–openssl-legacy-provider</p>
    <p>就可以启动项目了。<br>
<!--      <a href="https://blog.csdn.net/zjjxxh/article/details/127173968" target="_blank">链接</a></p>-->
    <h3><a id="_56"></a>常见错误二</h3>
    <p>the engine “node“ is incompatible with this module.</p>
    <p>error commander@9.2.0: The engine “node” is incompatible with this module. Expected version “^12.20.0 || &gt;=14”. Got “12.13.0” error Found incompatible module.<br>
      执行：yarn config set ignore-engines true</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom6"
}
</script>

<style scoped>

</style>
