<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="1__0"></a>1 新建流程表单</h1>
    <h2><a id="11_1"></a>1.1新增流程表单</h2>
    <p><img src="../../../assets/word/131.png" alt=""></p>
    <p><img src="../../../assets/word/75.png" alt=""></p>
    <h1><a id="2_6"></a>2新建流程模型</h1>
    <p>新建一个请假模型，然后点击在线设计，进入设计流程。<br>
      <img src="../../../assets/word/121.png" alt=""></p>
    <h2><a id="21_11"></a>2.1设置开始节点表单</h2>
    <p><img src="../../../assets/word/122.png" alt=""></p>
    <p>选择刚刚设计的流程表单-工单申请，并设置字段属性可读可写。</p>
    <h2><a id="22_16"></a>2.2设置任务节点</h2>
    <p>设置字段只读<br>
      <img src="../../../assets/word/123.png" alt=""></p>
    <p>分配执人员<br>
      <img src="../../../assets/word/124.png" alt=""><br>
      <img src="../../../assets/word/125.png" alt=""><br>
      分配按钮<br>
      <img src="../../../assets/word/130.png" alt=""><br>
      <img src="../../../assets/word/138.png" alt=""></p>
    <h2><a id="23_26"></a>2.3设置流程分支条件</h2>
    <p>流程条件字段来源于开始节点关联的动态表单字段。（如果是外置表单，请使用流程表达式）<br>
      <img src="../../../assets/word/126.png" alt=""></p>
    <h1><a id="3_31"></a>3测试执行</h1>
    <p><img src="../../../assets/word/127.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom106"
}
</script>

<style scoped>

</style>
