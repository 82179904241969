<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>图片管理</h2>
    <h3><a id="1__1"></a>1. 创建表单</h3>
    <p><img src="../../../assets/word/50.png" alt=""><br>
      图表路径（例如：pic)对应物理字段要设置大一些，比如1264，因为多个图片路径叠加可能导致数据库溢出，根据您实际情况设置（一次允许上传多少张图片*图片路径的最大长度）。</p>
    <h3><a id="2_6"></a>2.表单页面</h3>
    <p><img src="../../../assets/word/39.png" alt=""><br>
      表单类型：请选择”图片上传“控件。</p>
    <h3><a id="3_11"></a>3.运行效果</h3>
    <p><img src="../../../assets/word/61.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom86"
}
</script>

<style scoped>

</style>
