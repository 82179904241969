<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>主附表</h2>
    <h3><a id="1_1"></a>1.创建主表</h3>
    <p><img src="../../../assets/word/93.png" alt=""></p>
    <p>注意表类型：请选择【主表】，其他与创建单表时的方式相同。</p>
    <h3><a id="2_7"></a>2.创建附表</h3>
    <p><img src="../../../assets/word/94.png" alt=""><br>
      <img src="../../../assets/word/95.png" alt=""><br>
      注意创建附表时，表类型请选择【附表】，然后指定主表表名和当前表的外键（例如test_data_main_id)，当前表外键关联主表的主键。其余与创建单表的方式相同。</p>
    <h3><a id="3_13"></a>3.生成代码</h3>
    <p><img src="../../../assets/word/40.png" alt=""></p>
    <p>选择代码模板：主附表。其余与单表相同。</p>
    <p>中间步骤省略，创建菜单，角色授权，请参照单表…</p>
    <h3><a id="4_21"></a>4.运行效果</h3>
    <p>弹窗信息<br>
      <img src="../../../assets/word/36.png" alt=""></p>
    <p>列表可以展示详细的字表信息<br>
      <img src="../../../assets/word/37.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom83"
}
</script>

<style scoped>

</style>
