<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_1"></a>一对多（自定义树组件）</h2>
    <p>如果list和tree之间有关联关系, list的某个字段值来自于tree，就可以使用自定义树组件。</p>
    <h3><a id="2__4"></a>2 首先创建一个树表（商品类型表），如下所示：</h3>
    <h4><a id="21_6"></a>2.1配置表单</h4>
    <p><img src="../../../assets/word/62.png" alt=""></p>
    <h4><a id="22_9"></a>2.2运行效果</h4>
    <p><img src="../../../assets/word/63.png" alt=""></p>
    <h4><a id="23__13"></a>2.3 添加到自定义对象管理</h4>
    <p>选中test_category记录，点击自定义对象生成：<br>
      <img src="../../../assets/word/41.png" alt=""></p>
    <p>可以在代码生成器配置–》<a href="#" title="java类型" target="_blank">java类型</a>中查看:<br>
      <img src="../../../assets/word/42.png" alt=""></p>
    <h3><a id="3__21"></a>3 创建单表（商品）</h3>
    <h4><a id="31_23"></a>3.1配置数据库属性</h4>
    <p><img src="../../../assets/word/64.png" alt=""><br>
      category_id 即关联树表的外键。</p>
    <h4><a id="32_JAVA_27"></a>3.2 JAVA实体设置</h4>
    <p><img src="../../../assets/word/43.png" alt=""><br>
      Java类型：选择2.3步骤中生成的自定义对象Category<br><br>
      <img src="../../../assets/word/44.png" alt=""><br>
      java关联字段：选择id和name</p>
    <h4><a id="33__33"></a>3.3 表单页面</h4>
    <p><img src="../../../assets/word/52.png" alt=""><br>
      表单类型：树选择控件<br></p>
    <h3><a id="4_40"></a>4运行效果</h3>
    <p><img src="../../../assets/word/45.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom87"
}
</script>

<style scoped>

</style>
