<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h1><a id="helloword_0"></a>开发流程（第一个helloword)</h1>
    <h3><a id="helloword_2"></a>开发规范（第一个helloword)</h3>
    <p>这篇章将带领大家从创建第一个应用开始（请假），带大家认识如何在中开发后端应用，以及后端开发中需要注意的相关开发流程以及规范。此示例是由代码生成器100%生成的第一个标准示例（<a href="/#/doc/detail/1585460201252515842/1585917529105932289">单表</a>），所以适合以此示例作为开发的入门讲解案例。</p>
    <ul>
      <li><a href="#">创建模块</a></li>
      <li><a href="#">设计数据库表</a></li>
      <li><a href="#">创建Domain</a></li>
      <li><a href="#">创建DTO</a></li>
      <li><a href="#">创建VO</a></li>
      <li><a href="#">创建MapStruct</a></li>
      <li><a href="#">创建Mapper</a></li>
      <li><a href="#">创建Service</a></li>
      <li><a href="#">创建Controller</a></li>
      <li><a href="#">接口返回值规范</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom49"
}
</script>

<style scoped>

</style>
