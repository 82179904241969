<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_1"></a>标签操作</h3>
    <h4><a id="_4"></a>通过工具类操作标签</h4>
    <p>使用工具类前先引入</p>
    <pre><code class="lang-language">import useTabs from '@/utils/useTabs'
</code></pre>
    <p>关闭当前标签</p>
    <pre><code class="lang-language">useTabs.close()
</code></pre>
    <p>关闭其他标签</p>
    <pre><code class="lang-language">useTabs.closeOther()
</code></pre>
    <p>更改标签显示文字</p>
    <pre><code class="lang-language">useTabs.setTitle("修改后的标签文字")
</code></pre>
    <h4><a id="URL_23"></a>通过菜单URL控制标签显示</h4>
    <p>相同的路由打开的都是同一个页面实例，但是现实中我们需要根据参数的不同可以打开多个页签。</p>
    <p>例如：显示报表统计页面（/test/pie)，根据月份的不同，要显示7月份和8月份的报表数据，因为共用了同一页面，所以通常情况下，只有参数不同，但是path相同的情况下，我们调用router.push打开页面时不能同时打开2个页面实例。<br>
      但是，在中，做了以下处理: 即使path相同，但是如果参数不同，那就可以同时打开多个页签，并通过title设置页签的标题，虽然无法绕过vue同一path就是同一个页面实例的限制，但是我们成功通过打开多个页签模拟出了类似浏览器多个页面的效果。</p>
    <h5><a id="_31"></a>同一路由，打开两个不同标题的页签，代码中调用方式</h5>
    <p>month是参数，title是页签标题。title如果不设置，将使用菜单中配置的菜单名称当作页签标题。</p>
    <pre><code class="lang-language">  this.$router.push('/test/pie?month=7&amp;title=7月份报表数据')
  this.$router.push('/test/pie?month=8&amp;title=8月份报表数据')
</code></pre>
    <h5><a id="_39"></a>同一路由，打开两个不同标题的页签，在菜单栏中配置方式</h5>
    <pre><code class="lang-">/test/pie?month=7&amp;title=7月份报表数据

/test/pie?month=8&amp;title=8月份报表数据
</code></pre>
    <h4><a id="_47"></a>固定在首页</h4>
    <p>让标签一直固定在标签栏显示，标签栏不显示关闭按钮。</p>
    <p>打开菜单管理，【固定在标签栏】选【是】。</p>
    <p><img src="../../../assets/word/177.png" alt=""></p>
    <h4><a id="_56"></a>编码方式实现固定在首页</h4>
    <p>在代码中配置路由时，需要添加 affix参数实现标签页一直显示的功能 。</p>
    <pre><code class="lang-language">meta: {
  affix: true
}
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom36"
}
</script>

<style scoped>

</style>
