<template>
<div class="wordhomw-wrap"><div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="cas_0"></a>cas服务器搭建</h1>
  <h2><a id="_https_1"></a>生成 https证书</h2>
  <p>CAS Service 需要用https的方式，那么就需要证书。</p>
  <p>生成证书需要三步：</p>
  <ul>
    <li>生成密钥</li>
    <li>生成证书</li>
    <li>导入证书</li>
  </ul>
  <h3><a id="1__9"></a>1. 生成密钥</h3>
  <p><code>keytool -genkey -alias casserver -keyalg RSA -keystore E:/ssl/casserver.keystore</code><br>
    参数说明:</p>
  <blockquote>
    <p>-genkey 生成密钥<br>
      -keyalg 指定密钥算法，这时指定RSA<br>
      -alias 指定别名<br>
      -keystore 指定密钥库存储位置，这里存在 E:/ssl/目录下</p>
  </blockquote>
  <p><strong>在执行中会问你很多问题，当问到 ：您的名字与姓氏是什么?<br>
    此时需要填写域名 www.casserver.com，作为之后的访问地址，其他随意。</strong><br>
    执行完后生成一个密钥文件 casserver.keystore，这个文件有两个地方需要用到，需要保持一致，后面会讲到。</p>
  <h3><a id="2__22"></a>2. 生成证书</h3>
  <p><code>keytool -export -alias casserver -storepass 123456 -file E:/ssl/casserver.cer -keystore E:/ssl/casserver.keystore</code><br>
    参数说明:</p>
  <blockquote>
    <p>-storepass 刚刚生成密钥文件时候的设置的密码<br>
      -file 指定导出证书的文件名为casserver.cer<br>
      -keystore 指定之前生成的密钥文件的文件名执行完后目录下会生成一个casserver.cer证书</p>
  </blockquote>
  <h3><a id="3__30"></a>3. 导入证书</h3>
  <p><code>keytool -import -alias casserver -keystore C:/"Program Files"/Java/jdk1.8.0_181/jre/lib/security/cacerts -file E:/ssl/casserver.cer -trustcacerts</code><br>
    将证书导入到JDK信任库，把原来的$JAVA_HOME/jre/lib/security/cacerts文件要先删掉，否则会报出 Keystore was tampered with, or password was incorrect。</p>
  <p>下面是整个过程：</p>
  <pre><div class="hljs"><code class="lang-xml">PS E:\ssl&gt; keytool -genkey -alias casserver -keyalg RSA -keystore E:\ssl\casserver.keystore
输入密钥库口令:
再次输入新口令:
您的名字与姓氏是什么?
  [Unknown]:  www.casserver.com
您的组织单位名称是什么?
  [Unknown]:  casserver
您的组织名称是什么?
  [Unknown]:  casserver
您所在的城市或区域名称是什么?
  [Unknown]:  wx
您所在的省/市/自治区名称是什么?
  [Unknown]:  js
该单位的双字母国家/地区代码是什么?
  [Unknown]:  CN
CN=www.casserver.com, OU=cainian, O=casserver, L=wx, ST=js, C=CN是否正确?
  [否]:  y

输入 <span class="hljs-tag">&lt;<span class="hljs-name">casserver</span>&gt;</span> 的密钥口令
        (如果和密钥库口令相同, 按回车):
再次输入新口令:

------------------------------------------------------------------------------------

PS E:\ssl&gt; keytool -export -alias casserver -storepass 123456 -file E:/ssl/casserver.cer -keystore E:/ssl/casserver.keystore
存储在文件 &lt;E:/ssl/casserver.cer&gt; 中的证书

------------------------------------------------------------------------------------

PS E:\ssl&gt; keytool -import -alias casserver -keystore C:/"Program Files"/Java/jdk1.8.0_181/jre/lib/security/cacerts -file E:/ssl/casserver.cer -trustcacerts
输入密钥库口令:
所有者: CN=www.casserver.com, OU=cainian, O=casserver, L=wx, ST=js, C=CN
发布者: CN=www.casserver.com, OU=cainian, O=casserver, L=wx, ST=js, C=CN
序列号: 509d1aea
有效期为 Wed Jun 17 22:02:55 CST 2020 至 Tue Sep 15 22:02:55 CST 2020
证书指纹:
         MD5:  5B:B2:7C:D7:B7:31:C5:7C:1C:BC:F7:DA:A8:2D:1C:B2
         SHA1: F6:76:55:55:D7:48:E3:9F:3A:B6:EE:68:1F:BE:DC:DE:51:B1:33:E5
         SHA256: 24:53:18:CD:E8:95:65:D8:6E:6A:7B:8E:79:CB:91:BD:F4:2E:C3:99:59:D1:76:12:A8:95:45:2A:4B:03:E4:AD
签名算法名称: SHA256withRSA
主体公共密钥算法: 2048 位 RSA 密钥
版本: 3

扩展:

#1: ObjectId: 2.5.29.14 Criticality=false
SubjectKeyIdentifier [
KeyIdentifier [
0000: 70 B3 D5 76 36 EA 54 BA   75 C1 A1 5C DA 76 82 0E  p..v6.T.u..\.v..
0010: 4D F4 C9 05                                        M...
]
]

是否信任此证书? [否]:  y
证书已添加到密钥库中
</code></div></pre>
  <p>最后，hosts 配置 127.0.0.1 www.casserver.com</p>
  <h2><a id="CAS_service_95"></a>搭建CAS service</h2>
  <p>5.3之后的都是gradle项目，5.3以之前都是maven 项目，建议使用5.3版本搭建。<br>
    需要从github上拉取模板 <a href="https://github.com/apereo/cas-overlay-template/tree/5.3" target="_blank">https://github.com/apereo/cas-overlay-template/tree/5.3</a><br>
    可根据官方文档自行搭建。<br>
    如果你嫌麻烦，作者提供个了搭建好的cas-overlay工程，请在qq群中下载。</p>
  <ul>
    <li>1 将前面生成的casserver.keystore文件/cas-overlay/src/main/resources目录下</li>
    <li>2 在工程目录下执行命令 ./build.sh bootrun，按照springboot方式启动</li>
    <li>3 生成war包：./build.sh run。</li>
    <li>4 出现 READY 的branner就启动好了 访问地址 https://www.casserver.com:8443/cas/login, 出现如下画面，输入用户名admin，密码admin登录。<br>
      <img src="../../../assets/word/108.png" alt=""></li>
  </ul>
</div></div>
</template>

<script>
export default {
  name: "wordCom114"
}
</script>

<style scoped>

</style>
