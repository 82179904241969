<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h2><a ></a>代码结构</h2>
    <ol>
      <li>
        <h2><a ></a>后端代码结构图：</h2>
      </li>
    </ol>
    <p><img src="../../../assets/word/149.png" alt=""></p>
    <h2><a ></a>模块说明：</h2>
    <pre><code class="lang-">业务模块，用户自己开发的业务模块放在该目录下
-modules
├ -test 代码生成的示例文件

平台核心模块
-platform
├ -admin
├ -common

插件模块
-plugins
├ -calendar     日历模块
├ -datascreen   大屏模块
├ -datasource   多数据源模块
├ -echarts      图表模块
├ -file	       文件服务模块
├ -flowable     工作流模块
├ -form         动态表单
├ -mail         邮箱模块
├ -monitor      性能检测模块
├ -notify       通知模块
├ -quartz       定时任务
├ -tools        工具模块
├ -ureport      报表模块
├ -wps

启动模块
-web
</code></pre>
    <ol >
      <li>
        <h2><a ></a>前端代码模块</h2>
      </li>
    </ol>
    <p><img src="../../../assets/word/151.png" alt=""></p>
    <h2><a id="_40"></a>模块说明</h2>
    <pre><div class="hljs"><code class="lang-xml">-ui
 node_modules 依赖的包
 public  入口文件index.html
 src
  ├ assets   静态资源
  ├ api   接口api
  ├ components  自定义组件库
  ├ directive 自定义指令
  ├ router 路由模块
  ├ store 状态管理
  ├ utils 自定义工具类
  ├ views 视图模块
   ├ ...
   ├ sys  业务模块页面
   ├ ...
 App.vue
 main.js
 .env.development  开发环境配置文件
 .env.production 生成环境配置文件
 pacakage.json

</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom3"
}
</script>

<style scoped>

</style>
