<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="sql_0"></a>通过sql数据源配置图表</h2>
    <h3><a id="_1"></a>配置数据源</h3>
    <p><img src="../../../assets/word/168.png" alt=""></p>
    <h3><a id="SQL_sql_4"></a>设置数据类型为SQL数据库数据， 设置sql语句</h3>
    <p><img src="../../../assets/word/111.png" alt=""><br>
      注意sql语句的返回值必须包含name和value, 如果字段名不是name和value，请通过as设置别名。</p>
    <p><img src="../../../assets/word/4.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom128"
}
</script>

<style scoped>

</style>
