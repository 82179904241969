<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>图片选择</h3>
    <blockquote>
      <p>图片选择和图片上传组件的区别主要在于：图片上传组件是可以上传多组图片，图片选择只能上传一张图片，图片选择的样式更丰富多彩，支持自定义图标、裁剪等，单张图片上传的场景建议使用图片选择组件。</p>
    </blockquote>
    <pre><code class="lang-language"> ImageSelect 已注册成全局组件，无需单独引入。
</code></pre>
    <p>使用</p>
    <pre><code class="lang-language">&lt;image-select v-model="photoForm.photo" round icon="el-icon-avatar" title="头像"&gt;&lt;/image-select&gt;
</code></pre>
    <p>运行效果<br>
      <img src="../../../assets/word/162.png" alt=""></p>
    <p>使用</p>
    <pre><code class="lang-language">&lt;image-select v-model="inputForm.configForm.logo" title="产品logo"&gt;&lt;/image-select&gt;
</code></pre>
    <p>运行效果<br>
      <img src="../../../assets/word/172.png" alt=""><br>
      参数</p>
    <table>
      <thead>
      <tr>
        <th>参数</th>
        <th>类型</th>
        <th>默认值</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>limit</td>
        <td>number</td>
        <td>null</td>
        <td>限制上传文件的个数，默认无限制</td>
      </tr>
      <tr>
        <td>maxSize</td>
        <td>number</td>
        <td>10</td>
        <td>单位是MB,限制上传单个文件的大小</td>
      </tr>
      <tr>
        <td>accept</td>
        <td>String</td>
        <td>“image/gif, image/jpeg, image/png”</td>
        <td>限制上传文件的类型,默认无限制</td>
      </tr>
      <tr>
        <td>cropper</td>
        <td>Boolean</td>
        <td>false</td>
        <td>是否开启裁剪功能</td>
      </tr>
      <tr>
        <td>disabled</td>
        <td>Boolean</td>
        <td>false</td>
        <td>禁止模式</td>
      </tr>
      <tr>
        <td>onSuccess</td>
        <td>Function</td>
        <td>null</td>
        <td>上传成功之后的回调函数</td>
      </tr>
      </tbody>
    </table>
    <p>其他用法</p>
    <pre><code class="lang-language">&lt;image-select v-model="fileurl3"&gt;&lt;/image-select&gt;
&lt;image-select v-model="fileurl4" title="自定义标题" icon="el-icon-picture"&gt;&lt;/image-select&gt;
&lt;image-select v-model="fileurl5" :apiObj="uploadApi" accept="image/jpg,image/png" :on-success="success" :width="220"&gt;
		&lt;div class="custom-empty"&gt;
			&lt;el-icon&gt;&lt;el-icon-upload /&gt;&lt;/el-icon&gt;
			&lt;p&gt;自定义插槽&lt;/p&gt;
		&lt;/div&gt;
	&lt;/image-select&gt;
&lt;image-select v-model="fileurl6" round icon="el-icon-avatar" title="开启圆形"&gt;&lt;/image-select&gt;
&lt;image-select v-model="fileurl7" title="开启剪裁" :cropper="true" :compress="1" :aspectRatio="1/1"&gt;&lt;/image-select&gt;
</code></pre>
    <p>运行效果<br>
      <img src="../../../assets/word/174.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom30"
}
</script>

<style scoped>

</style>
