<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>权限指令</h3>
    <p>通过以下指令控制按钮 是否显示。</p>
    <pre><code class="lang-language"> v-if="hasPermission('xx:xx:xx')"
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom41"
}
</script>

<style scoped>

</style>
