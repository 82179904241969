<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="Grid_1"></a>一对多（自定义Grid)</h2>
    <p>如果2个list之间有关联关系，其中一个list中的某个字段来自于另一个list，比如学生属于某个班级，国家属于哪个州，这时候需要使用Grid选择框。</p>
    <h3><a id="2__4"></a>2 首先创建一个单表（五大洲），如下所示：</h3>
    <h4><a id="21_5"></a>2.1配置表单</h4>
    <p><img src="../../../assets/word/18.png" alt=""></p>
    <h4><a id="22_7"></a>2.2运行效果</h4>
    <p><img src="../../../assets/word/17.png" alt=""></p>
    <h4><a id="23__10"></a>2.3 添加到自定义对象管理</h4>
    <p>选中test_contient记录，点击自定义对象生成。</p>
    <p><img src="../../../assets/word/19.png" alt=""></p>
    <p>可以在代码生成器配置–》<a href="#" title="java类型" target="_blank">java类型</a>中查看:<br>
      <img src="../../../assets/word/20.png" alt=""></p>
    <h3><a id="3__18"></a>3 创建单表（国家）</h3>
    <h4><a id="31__20"></a>3.1 配置数据库属性</h4>
    <p><img src="../../../assets/word/21.png" alt=""><br>
      字段 continent_id 对应test_contient表的主键id。</p>
    <h4><a id="32_java_23"></a>3.2 java实体设置</h4>
    <p>Java类型：continent_id对应的jave类型选择2.3步骤中生成的自定义对象TestContinent<br><br>
      <img src="../../../assets/word/22.png" alt=""><br>
      <br><br>
      Java属性名称：选择id和name，生成continent.id|name<br>
      <img src="../../../assets/word/23.png" alt=""></p>
    <h4><a id="33__30"></a>3.3 表单页面</h4>
    <p>显示表单类型：grid选择框<br>
      <img src="../../../assets/word/25.png" alt=""></p>
    <p>配置grid选择框<br>
      <img src="../../../assets/word/27.png" alt=""><br>
      JAVA属性标签：grid选择框中显示的table的表头（例如洲名|备注）。<br><br>
      JAVA属性名称：自定义java对象中的属性，作为列表值显示在gird选择框中。<br><br>
      检索标签：gird选择框中搜索标签。<br><br>
      检索key：自定义java对象中的属性，作为检索key（例如name）。<br><br>
      <img src="../../../assets/word/24.png" alt=""></p>
    <h3><a id="4_44"></a>4运行效果</h3>
    <p><img src="../../../assets/word/26.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom88"
}
</script>

<style scoped>

</style>
