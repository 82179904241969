<template>
    <div class="ecology">
        <!--  轮播-->
        <div class="swiper">
            <el-row>
                <el-col :span="12">
                    <img src="../../../assets/ecology/ecology1.png" width="100%">
                </el-col>
                <el-col :span="12" style="padding-left: 15rem">
                    <h3>合作共赢<br/>共享商业成功</h3>
                    <h5>
                        优质的合作伙伴，可靠的商业模式
                    </h5>
                </el-col>

            </el-row>
            <!-- Additional required wrapper -->
            <!--      <div class="swiper-wrapper">-->
            <!-- Slides -->
            <!--        <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
            <!--          <img :src="require('../../../assets/'+item.url)" width="100%">-->
            <!--        </div>-->
            <!--      </div>-->
            <!-- If we need pagination -->
            <!--      <div class="swiper-pagination"></div>-->

            <!-- If we need navigation buttons -->
            <!--    <div class="swiper-button-prev"></div>-->
            <!--    <div class="swiper-button-next"></div>-->

            <!-- If we need scrollbar -->
            <!--    <div class="swiper-scrollbar"></div>-->
        </div>
        <!--我们的产品-->
        <section class="ecology1" style="margin-top: 90px">
            <div class="wrap">
                <div class="name">我们的生态</div>
                <img src="../../../assets/ecology/ecology2.png" style="width: 80%; margin: auto">
            </div>
        </section>
        <section class="ecology2">
            <div class="wrap">
                <img src="../../../assets/ecology/ecologyrenzheng.png" style="width: 80%; margin: auto">
            </div>
        </section>
        <section class="ecology3">
            <div class="wrap">
                <img src="../../../assets/ecology/ecology4.png" style="width: 80%; margin: auto">
            </div>
        </section>
        <section class="ecology4" style="padding-bottom: 20rem">
            <div class="wrap">
                <img src="../../../assets/ecology/ecology5.png" style="width: 80%; margin: auto">
            </div>
        </section>
    </div>
</template>

<script>
// import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

export default {
    name: "ecologyIndex",
    data(){
        return {
            swiperimgData:[
                {
                    name:'图片1',
                    url:'ecology/ecology1.png'
                }
            ],
        }
    },
    mounted() {
        // 轮播
        // new Swiper(".swiper", {
        //   speed: 1000,
        //   spaceBetween: 100,
        //   autoplay: {
        //     delay: 3000,
        //   },
        //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
        //   // navigation: {
        //   //   nextEl: ".swiper-button-next",
        //   //   prevEl: ".swiper-button-prev",
        //   // },
        //   pagination: {
        //     el: '.swiper-pagination',
        //     type: 'bullets',
        //   },
        //   // scrollbar: {
        //   //   el: ".swiper-scrollbar",
        //   //   draggable: true,
        //   // },
        // });
        //视频
        //
    },
    methods:{

    }

}
</script>

<style lang="scss" scoped>
.swiper{
  width: 100%;
  background: url('../../../assets/ecology/ecology1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 12rem 0 10rem;
    margin: auto;
    text-align: right;
    h3{

      margin-top: 6rem;
      font-size: 5rem;
      margin-bottom: 2rem;
    }
    h5{
      font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.ecology{
  padding-top: 0rem;
}
.wrap{
  width: 120rem;
  padding:15rem 0 15rem;
  margin: auto;
  .name{
    font-size: 4rem;
    color: #000;
    text-align: center;
    margin-bottom: 10rem;
  }
  img{display: block}
  .imglist{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .imgbox{
      width: 40rem;
      height: 42rem;
      margin-bottom: 4rem;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      border-radius: .4rem;
      align-items: center;
      background: #F8F8F8;
      img{
        width: 30%;
        margin-top: 7.2rem;
      }
      span{
        font-size: 2.8rem;
        margin-top: 4rem;
      }
      i{
        font-style: normal;
        font-size: 1.8rem;
        font-weight: 300;
        margin-top: .5rem;
      }

    }
  }

}
.ecology1{
  background: url('../../../assets/ecology/ecologybg.png') no-repeat;background-size: cover;
}
.ecology2{
  background: url('../../../assets/ecology/ecology3bg.png') no-repeat;background-size: cover;
}
.ecology3{
  background: url('../../../assets/ecology/ecology4bg.png') no-repeat;background-size: cover;
}
.ecology4{
  background: url('../../../assets/ecology/ecology5bg.png') no-repeat;background-size: cover;
}
</style>
