<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>岗位</h3>
    <p>引入</p>
    <pre><code class="lang-language"> import PostSelect from '@/components/postSelect' //
</code></pre>
    <p>使用</p>
    <pre><code class="lang-language"> &lt;post-select v-model="inputForm.post.id"&gt;&lt;/post-select&gt;
</code></pre>
    <p>参数</p>
    <table>
      <thead>
      <tr>
        <th>参数</th>
        <th>类型</th>
        <th>默认值</th>
        <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>printRead</td>
        <td>Boolean</td>
        <td>false</td>
        <td>打印阅读模式</td>
      </tr>
      <tr>
        <td>clearable</td>
        <td>Boolean</td>
        <td>true</td>
        <td>是否允许清除</td>
      </tr>
      <tr>
        <td>readonly</td>
        <td>Boolean</td>
        <td>false</td>
        <td>只读模式</td>
      </tr>
      <tr>
        <td>disabled</td>
        <td>Boolean</td>
        <td>false</td>
        <td>禁止模式</td>
      </tr>
      </tbody>
    </table>
    <p>演示<br>
      <img src="../../../assets/word/164.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom27"
}
</script>

<style scoped>

</style>
