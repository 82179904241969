<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>生成示例</h1>
    <ul>
      <li><a href="#">单表</a></li>
      <li><a href="#">主附表</a></li>
      <li><a href="#">树表</a></li>
      <li><a href="#">富文本</a></li>
      <li><a href="#">图片管理</a></li>
      <li><a href="#">一对多(自定义树组件)</a></li>
      <li><a href="#">一对多(自定义Grid)</a></li>
      <li><a href="#">多对多</a></li>
      <li><a href="#">左树右表</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom81"
}
</script>

<style scoped>

</style>
