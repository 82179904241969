<template>
  <div>
    <component :is="$store.state.wordName"></component>
  </div>
</template>

<script>
// const path = require('path')
// const files = require.context('@/components/word/content', false, /.vue$/)
// const modules = {}
// files.keys().forEach(key => {
//   const name = path.basename(key, '.vue')
//   modules[name] = files(key).default || files(key)
// })
export default {
  name: "wordLeft",
  // components:modules,

  props:{
    'wordName':{
      type:String,
      default:'wordcom1'
    }
  },
  data(){
    return {

    }
  },
}
</script>

<style lang="scss" >
.wordhomw-wrap{
  padding: 2rem 5rem;
  min-height: 300px;
  h1 {
    padding-bottom: 2rem;
    font-size: 5rem;
    border-bottom: 1px solid #eaecef;
    margin-bottom: 3rem;
    font-weight: 600;
    line-height: 1.25;
  }
  h3{
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 1.6rem;
    line-height: 1.25;
  }
  .markdown-body .highlight pre, .markdown-body pre {
    padding: 1.6rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: .3rem;
    margin-bottom: 1.6rem;
    font-family: SFMono-Regular,Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }
  .markdown-body table {
    display: table;
margin-bottom: 10px;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .markdown-body table td, .markdown-body table th {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
    font-size: 2rem;

  }
  .markdown-body table th {
    font-weight: 600;
  }
  .markdown-body table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }
  .markdown-body img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: #fff;
    margin-top: 10px;
  }
  .markdown-body pre code {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    font-size: 2rem;

  }
  .markdown-body>:first-child {
    margin-top: 0!important;
  }
  h2 {
    padding-bottom: .3em;
    font-size: 3rem;
    margin-bottom: 2.4rem;
    border-bottom: 1px solid #eaecef;
  }
  h4{
    font-size: 2.4rem;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    font-weight: 600;
    line-height: 1.25;
  }
  ol，ul,li{
    padding-left: 4rem;
    margin-top: 0;
    font-size: 2.2rem;
    line-height:1.7;
  font-weight: 300;}

  p{
    margin-top: 0;
    margin-bottom: 1.6rem;
    font-size: 2.2rem;
  }
  a {
    color: #0366d6;
    font-size: 2.4rem;
    line-height: 2;
    font-weight: 400;
    text-decoration: none;
  }
  .hljs-comment, .hljs-deletion, .hljs-meta, .hljs-quote {
    color: #75715e;
  }
  .hljs-doctag, .hljs-keyword, .hljs-literal, .hljs-section, .hljs-selector-id, .hljs-selector-tag, .hljs-title, .hljs-type {
    font-weight: 700;
  }
  .hljs-keyword, .hljs-literal, .hljs-name, .hljs-selector-tag, .hljs-strong, .hljs-tag {
    color: #f92672;
  }
  .hljs-addition, .hljs-built_in, .hljs-bullet, .hljs-emphasis, .hljs-section, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-string, .hljs-subst, .hljs-template-tag, .hljs-template-variable, .hljs-title, .hljs-type, .hljs-variable {
    color: #a6e22e;
  }
}
</style>
