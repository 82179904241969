<template>
<div class="home">
<!--  轮播-->
  <div class="swiper">
    <el-row>
      <el-col :span="12" style="padding-right: 15rem">
        <h3>低代码快速开发平台</h3>
        <h5>
          自动建表：根据表生成对应的Domain, Service, Mapper, Controller, Vue等。增删改查、排序、导出导入Excel、权限控制、功能生成直接使用，同时可一键生成菜单栏。
        </h5>
        <img src="./../../../assets/home/btn.png" style="width: 16rem; margin-top: 10rem; cursor: pointer">
      </el-col>
      <el-col :span="12">
        <img src="../../../assets/home/home1.png" width="100%">
      </el-col>
    </el-row>
    <!-- Additional required wrapper -->
<!--    <div class="swiper-wrapper">-->
      <!-- Slides -->
<!--      <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
<!--        <img :src="require('../../../assets/'+item.url)" width="100%">-->
<!--      </div>-->
<!--    </div>-->
    <!-- If we need pagination -->
<!--    <div class="swiper-pagination"></div>-->

    <!-- If we need navigation buttons -->
<!--    <div class="swiper-button-prev"></div>-->
<!--    <div class="swiper-button-next"></div>-->

    <!-- If we need scrollbar -->
<!--    <div class="swiper-scrollbar"></div>-->
  </div>
  <!--  六大核心价值-->
  <div class="wrap" style="padding: 6rem 0">
    <div class="el-row home2" >
      <el-col :span="4">
        <h5>低代码开发</h5>
        <h6>LOW CODE DEVELOPMENT</h6>
      </el-col>
      <el-col :span="4">
        <h5>数字化底座</h5>
        <h6>DIGITAL BASE</h6>
      </el-col>
      <el-col :span="4">
        <h5>国产化适配</h5>
        <h6>DOMESTICATED ADAPTATION</h6>
      </el-col>
      <el-col :span="4">
        <h5>大屏设计器</h5>
        <h6>LARGE SCREEN DESIGNER</h6>
      </el-col>
      <el-col :span="4">
        <h5>工作流引擎</h5>
        <h6>WORKFLOW ENGINE</h6>
      </el-col>
      <el-col :span="4">
        <h5>开发 对接 IOT</h5>
        <h6>LOW CODE DEVELOPMENT</h6>
      </el-col>
    </div>
  </div>
<!--低代码开发-->
  <section class="home3">
    <div class="wrap" >
      <div class="name">低代码开发</div>
      <div class="tablist" style="border-radius: 5px">
        <div class="tabspan" style="border-radius: 5px 5px 0 0">
          <span v-for="(item,index) in tabspanList" :key="index" :class="{action:tabspantext===item.name}" @click="tabclick(item)">{{item.name}}</span>
        </div>
        <div class="tabbox">
          <div v-for="(item,index) in tabspanList" :key="index +'s'" v-show="tabspantext===item.name">
            <img :src="require('../../../assets/'+item.img)" width="100%">
          </div>
        </div>
      </div>
    </div>
  </section>
<!--  数字化底座-->
  <section class="home4">
    <div class="wrap" >
      <div class="name">数字化底座</div>
      <img src="../../../assets/home/home9.png" style="width: 80%; margin: auto">
    </div>
  </section>
<!--  国产化适配-->
  <section class="home5">
    <div class="wrap" >
      <div class="name">国产化适配</div>
      <img src="../../../assets/home/home10.png" style="width: 80%; margin: auto">
    </div>
  </section>
  <!--  支持微信、钉钉、APP-->
  <section class="home6">
    <div class="wrap" >
      <div class="name">支持微信、钉钉、APP</div>
      <img src="../../../assets/home/home18.png" style="width: 80%; margin: auto">
    </div>
  </section>
<!--  可视化大屏设计器-->
  <section  class="home7">
    <div class="wrap" >
      <div class="name">可视化大屏设计器</div>
      <video :controls="controls1" src="../../../assets/home/sp1.mp4"  id="videoId" v-show="controls1" style="width: 100%;border-radius: 5px"></video>
      <img src="../../../assets/home/home11.png" width="100%" v-if="!controls1" @click="sp1click" style="cursor: pointer; border-radius: 5px">
    </div>
  </section>
<!--  工作流引擎-->
  <section  class="home8">
    <div class="wrap" >
      <div class="name">工作流引擎</div>
      <video :controls="controls2" src="../../../assets/home/sp2.mp4"  id="videoId2" v-show="controls2" style="width: 100%;border-radius: 5px"></video>
      <img src="../../../assets/home/home12.png" width="100%" v-if="!controls2" @click="sp2click" style="cursor: pointer;border-radius: 5px">
    </div>
  </section>
<!--  开放、对接、IOT-->
  <section   class="home9">
    <div class="wrap">
      <div class="name">开放、对接、IOT</div>
      <img src="../../../assets/home/home13.png" style="width: 80%; margin: auto">
    </div>
  </section>
</div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
// 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
//import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from "swiper"; // 导入您需要的模块
export default {
  name: "homeIndex",
  components: {
  },
  data(){
    return {
        home1:{},
      swiperimgData:[
        {
          name:'图片1',
          url:'home/home1.png'
        }
      ],
      tabspantext:'平台架构',
      tabspanList:[
        {
          name:'平台架构',
          img:'home/home8.png'
        }
        // {
        //   name:'功能引擎',
        //   img:'home/home14.png'
        // }
        ,{
          name:'流程引擎',
          img:'home/home16.png'
        },{
          name:'资源库',
          img:'home/home17.png'
        },
        // {
        //   name:'脚本工具',
        //   img:'home/home15.png'
        // }
      ],
      controls1:false,
    controls2:false,

    }
  },
  mounted() {
    // 轮播
    // new Swiper(".swiper", {
    //   speed: 1000,
    //   spaceBetween: 100,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
    //   // navigation: {
    //   //   nextEl: ".swiper-button-next",
    //   //   prevEl: ".swiper-button-prev",
    //   // },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     type: 'bullets',
    //   },
    //   // scrollbar: {
    //   //   el: ".swiper-scrollbar",
    //   //   draggable: true,
    //   // },
    // });
    //视频
    //
  },
  methods:{
    tabclick(val){
      this.tabspantext=val.name
    },
    sp1click(){
      let videoElement=document.getElementById("videoId")
      this.controls1=true
      videoElement.play();
    },
    sp2click(){
      let videoElement=document.getElementById("videoId2")
      this.controls2=true
      videoElement.play();
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper{
  width: 100%;
  background: url('../../../assets/home/home1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 18rem 0;
    margin: auto;
    h3{
      font-size: 5rem;
      margin-bottom: 4.8rem;
    }
    h5{
font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.home{
  padding-top: 6.4rem;
}
.wrap{
  min-width: 120rem;
  width: 120rem;
  padding:18rem 0 20rem;
  margin: auto;
  //height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name{
    font-weight: 800;
    color: #ED0200;
    line-height: 2.8rem;
    font-size: 2.8rem;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 6rem;
  }
  .imglist{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .imgbox{
      width: 37.2rem;
      height: 28rem;
      margin-bottom: 3.6rem;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      //box-shadow: 0px .3rem 1rem 0rem rgba(0,0,0,0.2);
      border-radius: .4rem;
      background: #fff;
      align-items: center;
      img{
        width: 10.8rem;
        margin-top: 5rem;
      }
      span{
        font-size: 2.4rem;
        margin-top: 3.2rem;
      }

    }
  }
  .tablist{
  width: 100%;
  background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.08);
    border-radius: 10px;
    .tabspan{
      background:#fff;
      height: 6.4rem;
      line-height: 6.4rem;
      display: flex;
      justify-content: space-around;
      span{
        font-weight: 400;
        color: #444955;
        font-size: 2rem;
        position: relative;
        cursor: pointer;
        &.action{
          //font-size: 2.4rem;
          font-weight: 500;
          color: #FD3335;
          &::after{
            content: '';
            position: absolute;
            height: .2rem;
            width: 100%;
            background: #FD3335;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
    .tabbox{
      padding: 4rem;
    }
}
  .home2{
    height: 20rem;
    background: url('../../../assets/home/home2bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 0 30px;
    h5{font-size: 2rem;color: rgba(255,255,255,1);margin-bottom: 5px }
    h6{font-size:1.2rem; color: rgba(255,255,255,.6); font-weight: 100}
  }

}
.home3{
  background: url('../../../assets/home/home8bg.png') no-repeat;background-size: cover;
}
.home4{
  background: url('../../../assets/home/home9bg.png') no-repeat;background-size: cover;
}
.home5{
  background: url('../../../assets/home/home10bg.png') no-repeat;background-size: cover;
}
.home6{
  background: url('../../../assets/home/home18bg.png') no-repeat;background-size: cover;
}
.home7{
  background: url('../../../assets/home/home11bg.png') no-repeat;background-size: cover;
}
.home8{
  background: url('../../../assets/home/home12bg.png') no-repeat;background-size: cover;
}
.home9{
  background: url('../../../assets/home/home13bg.png') no-repeat;background-size: cover;
}
</style>
