<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="wps_0"></a>wps集成</h1>
    <p>在线编辑office只能联网使用，所以应用只有部署在公网且使用域名时才能使用，因为是远程调用的金山wps， 在金山服务器上完成内容编辑，然后通过回调网址（你程序的后台网络地址）将文档保存到你的服务器里面。</p>
    <h3><a id="_4"></a>效果图</h3>
    <p><img src="../../../assets/word/169.png" alt="截屏20221113 21.35.53.png"></p>
    <h3><a id="_wps_9"></a>步骤一 登录wps开放平台注册账号</h3>
    <p><a href="https://open.wps.cn/" target="_blank">wps开放平台</a></p>
    <h3><a id="__13"></a>步骤二 创建应用</h3>
    <p>选择【在线编辑预览】创建应用<br>
      <img src="../../../assets/word/143.png" alt="截屏20221113 21.44.32.png"></p>
    <h3><a id="__17"></a>步骤三 填写申请信息</h3>
    <p>填写信息，平台审核通过之后就可以使用了<br>
      <img src="../../../assets/word/171.png" alt="截屏20221113 21.48.59.png"></p>
    <p>回调地址请填写wps服务的后台地址（非vue前段地址），例如 微服务版本地址填写：http://xxx.com/wsp-server， 单体版本填写：http://xxxx.com/-vue</p>
    <h3><a id="_wpsxml_24"></a>步骤四 配置-wps.xml</h3>
    <pre><code class="lang-language">#wps配置
wps:
  appid: 3f86c4255efd4e9cxxxxxxxxx
  appsecret: 4bbc464724dc4351a93a7xxxxxxxxxxx
  download_host: http://demo1..org/-vue      # 填写数据回调地址
  domain: https://wwo.wps.cn
  downloadCallbackPath: /usr/local/wps                    # 服务器上的文件缓存地址
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom124"
}
</script>

<style scoped>

</style>
