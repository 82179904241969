<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="Service_0"></a>创建Service</h3>
    <p>服务组件是包含@Service注解的类文件。这些类文件用于在与@RestController类文件分开的不同层中编写业务逻辑， @Transactional是事务注解。创建服务组件类文件的逻辑如下所示：</p>
    <pre><code class="lang-language">/**
 * Copyright © 2021-2025 &lt;a href="http://www..org/"&gt;&lt;/a&gt; All rights reserved.
 */
package com..test.one.service;

import org.springframework.stereotype.Service;
import org.springframework.transaction.annotation.Transactional;
import com.baomidou.mybatisplus.extension.service.impl.ServiceImpl;
import com.baomidou.mybatisplus.core.conditions.query.QueryWrapper;
import com.baomidou.mybatisplus.core.metadata.IPage;
import com.baomidou.mybatisplus.extension.plugins.pagination.Page;
import com..test.one.service.dto.TestFormLeaveDTO;
import com..test.one.domain.TestFormLeave;
import com..test.one.mapper.TestFormLeaveMapper;

/**
 * 请假Service
 * @author 刘高峰
 * @version 2022-07-26
 */
@Service
@Transactional
public class TestFormLeaveService extends ServiceImpl&lt;TestFormLeaveMapper, TestFormLeave&gt; {

	/**	 * 根据id查询	 * @param id	 * @return	 */
	public TestFormLeaveDTO findById(String id) {
		return baseMapper.findById ( id );
	}

	/**	 * 自定义分页检索	 * @param page	 * @param queryWrapper	 * @return	 */
	public IPage &lt;TestFormLeaveDTO&gt; findPage(Page &lt;TestFormLeaveDTO&gt; page, QueryWrapper queryWrapper) {
		queryWrapper.eq ("a.del_flag", 0 ); // 排除已经删除
		return  baseMapper.findList (page, queryWrapper);
	}

}

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom57"
}
</script>

<style scoped>

</style>
