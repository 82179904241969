<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>排序实现</h3>
    <p>默认排序推荐在前端配置<br>
      参照文档<a href="#">前端排序</a></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom69"
}
</script>

<style scoped>

</style>
