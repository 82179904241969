<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>智能表单</h1>
    <ul>
      <li><a href="#">数据库表设置</a></li>
      <li><a href="#">JAVA实体设置</a></li>
      <li><a href="#">表单页面</a></li>
      <li><a href="#">表单校验</a></li>
      <li><a href="#">列表页面</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom75"
}
</script>

<style scoped>

</style>
