<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>表单校验</h3>
    <p><img src="../../../assets/word/102.png" alt=""></p>
    <ul>
      <li>可空：不勾选就是必填项。</li>
      <li>校验类型：留空不使用校验。</li>
      <li>最小长度：允许输入的字符串最小长度。</li>
      <li>最大长度：允许输入的字符串最大长度。（特别注意：该长度应该比数据库属性中设置的数据物理长度的值要小。）</li>
      <li>最小值：校验类型为数字时，允许输入的数据最小值。</li>
      <li>最大值：校验类型为数字时，允许输入的数据最大值。</li>
    </ul>
    <h3><a id="_11"></a>支持以下校验方式</h3>
    <ul>
      <li>字符串</li>
      <li>电子邮件</li>
      <li>网址</li>
      <li>日期</li>
      <li>日期(ISO)"/&gt;</li>
      <li>信用卡号"/&gt;</li>
      <li>手机号码"/&gt;</li>
      <li>电话号码"/&gt;</li>
      <li>手机/电话"/&gt;</li>
      <li>QQ号码"/&gt;</li>
      <li>身份证号码"/&gt;</li>
      <li>数字"/&gt;</li>
      <li>整数"/&gt;</li>
      <li>整数(大于0)"/&gt;</li>
      <li>整数(大于等于0)"/&gt;</li>
      <li>整数(小于0)"/&gt;</li>
      <li>整数(小于等于0)"/&gt;</li>
      <li>浮点数(大于0)"/&gt;</li>
      <li>浮点数(大于等于0)"/&gt;</li>
      <li>浮点数(小于0)"/&gt;</li>
      <li>浮点数(小于等于0)"/&gt;</li>
      <li>邮政编码"/&gt;</li>
      <li>以字母开头，长度在6-12之间，只能包含字符、数字和下划线</li>
      <li>中文/英文/数字/下划线</li>
      <li>英语</li>
      <li>汉子</li>
      <li>汉英字符</li>
      <li>判断是否为合法字符(a-zA-Z0-9-_)</li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom79"
}
</script>

<style scoped>

</style>
