import { render, staticRenderFns } from "./wordcom124.vue?vue&type=template&id=643d3997&scoped=true&"
import script from "./wordcom124.vue?vue&type=script&lang=js&"
export * from "./wordcom124.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643d3997",
  null
  
)

export default component.exports