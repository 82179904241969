<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>字典操作</h3>
    <h4><a id="_2"></a>获取字典列表</h4>
    <pre><code class="lang-">
      $dictUtils.getDictList('yes_no')
</code></pre>
    <p>例如</p>
    <pre><code class="lang-language">            &lt;el-select v-model="inputForm.sysData" placeholder="请选择"  style="width: 100%;"&gt;
              &lt;el-option
                v-for="item in this.$dictUtils.getDictList('yes_no')"
                :key="item.value"
                :label="item.label"
                :value="item.value"&gt;
              &lt;/el-option&gt;
            &lt;/el-select&gt;
</code></pre>
    <h4><a id="_19"></a>获取字典标签</h4>
    <pre><code class="lang-">
$dictUtils.getDictLabel("yes_no", row.status, '-')
</code></pre>
    <h4><a id="_24"></a>获取字典值</h4>
    <pre><code class="lang-">
 $dictUtils.getDictValue("yes_no", row.status, '-')
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom38"
}
</script>

<style scoped>

</style>
