<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>工作流相关</h1>
    <ul>
      <li><a href="#">外置流程表单</a></li>
      <li><a href="#">动态流程表单</a></li>
      <li><a href="#">节点人员配置</a></li>
      <li><a href="#">自定义按钮</a></li>
      <li><a href="#">会签流程</a></li>
      <li><a href="#">常见问题</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom104"
}
</script>

<style scoped>

</style>
