<template>
<div :class="{bgred:isbackgrounds,tcolor:iscolors,fixheader:true,bgfff:isbgff,boxshow:isboxshow}">
  <div class="wrap">
    <!--  log-->
    <div class="logo">
<!--      <img src="../../assets/logo.png" v-if="islogoreds"/>-->
      <img src="../../assets/logored.png"/>
    </div>
    <nav>
      <span v-for="item in navtitle" :key="item.title" @click="navclick(item)" :class="{action:item.name===isnavtext,navtitle}">{{item.name}}</span>
    </nav>
    <div class="ewlink">
      <span v-for="(item,index) in ewlinkData" :key="index" @click="openlinke(item)" :class="{action:item.name===isnavtext,navtitle}">{{item.name}}</span>
<!--      :src="require('../../../assets/'+item.url)"-->

    </div>
      <img :src="telephoneimg" style="height: 1.6rem;position: relative; top: 25px; margin-right: 10px; margin-left: 20px">
      <span style="color: red;font-size: 20px;margin-left: -4px;">{{ company.businessHotline }}</span>
  </div>
</div>
</template>

<script>
import {mapState} from  'vuex'

export default {
  name: "headerIndex",
  props:{
    // 背景颜色
    'isbackground':{
      type:Boolean,
      default:false
    },
    // 字体颜色
    'iscolor':{
      type:Boolean,
      default:false
    },
    // logo颜色
    'islogored':{
      type:Boolean,
      default:false
    },
    company:{
        type: Object,
        default(){
            return {}
        }
    }
  },
  data(){
    return {
      timer:null,
      telephoneimg:require('../../assets/tb-dh1.png'),
      // 背景颜色
      isbackgrounds:false,
      isbgff:false,
     // 字体颜色
      iscolors:false,
      // logo颜色
      islogoreds:false,
      //底部阴影
      isboxshow:false,
//点击的文字
      isnavtext:'首页',
      // nav显示的文字
      navtitle:[
        {
          name:'首页',
          title:'home'
        },
        {
          name:'产品',
          title:'product'
        },
        {
          name:'方案',
          title:'programme'
        },
        {
          name:'生态',
          title:'ecology'
        },
        // {
        //   name:'云仓',
        //   title:'Yuncang'
        // },
        // {
        //   name:'服务',
        //   title:'service'
        // },
        {
          name:'关于',
          title:'about'
        }
      ],
      //额外的链接
      ewlinkData:[
        {
          name:'云仓',
          title:'Yuncang'
        },
        {
          name:'产品体验',
          link:''
        },
        {
          name:'开发文档',
          link:''
        },
        // {
        //   name:'用户社区',
        //   link:''
        // }
      ]
    }
  },
  watch:{
    'isbackground'(){
      this.isbackgrounds=this.isbackground
    },
    'iscolor'(){
      this.iscolors=this.iscolor
    },
    'islogored'(){
      this.islogoreds=this.islogored
    },
    '$store.state.components'(){
      let stname=this.$store.state.components
      let linkname=this.navtitle.filter(item=>item.title===stname)
      if(linkname.length!==0){
        this.isnavtext=linkname[0].name
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrolling);
    clearInterval(this.timer);
    let num=1
    let that=this
    this.timer = setInterval(function () {
      if(num===4){
        num=1
      }else{
        num++
      }
      that.telephoneimg=require('../../assets/tb-dh'+num+'.png')
    },300);
  },
  created() {
  },
  computed:{
    ...mapState(['components'])
  },
  methods:{
    scrolling(){
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if(scrollTop>100){
        if(!this.isbackgrounds){
          this.isbgff=true
        }
        if(this.iscolors && !this.isbackgrounds){
          this.iscolors=false
        }
        if(this.islogoreds && !this.isbackgrounds){
          this.islogoreds=false
        }
        this.isboxshow=true
        // if(this.$store.state.iscolor){
        //   this.$store.state.iscolor=false
        // }
      }else{
        this.isbgff=false
        this.isboxshow=false
        this.iscolors=this.$store.state.iscolor
        this.islogoreds=this.$store.state.islogored
      }

    },
    navclick(val){
      // console.log(this.isnavtext,val.name)
      this.isnavtext=val.name
      this.$store.state.components=val.title
      this.$store.state.isbackground=false
      this.$store.state.iscolor=false
      this.$store.state.islogored=false
      document.body.scrollTop=document.documentElement.scrollTop=0
    // this.$emit('navclick',val)
    },
    // 开外链
    openlinke(val){
      if(val.title!==undefined){
        this.navclick(val)
      }else{
        this.$emit('workclick',val)
        // window.open(val.url, "_self");
      }

    }
  },

}
</script>

<style scoped>
.wrap{
  width: 120rem;
  margin: auto;
  display: flex;
  min-width: 120rem;
}
.fixheader{
  height: 6.4rem;
  line-height: 6.4rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  min-width: 120rem;
  color: #000;

}
.boxshow{box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);}
.bgred{
  background: #fff;
/*  background: linear-gradient(90deg, #C81C22 0%, #D32129 100%);*/
}
.bgfff{
  background: #fff;
}
.tcolor{
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
  /*color:#fff ;*/
}
.logo img{
  height: 2.7rem;
  margin:2rem 3rem 2rem 0rem;
}
nav{
  flex: 1;
}
.navtitle{
  padding: 0 1rem;
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
}
.ewlink {
/*margin-right: 12rem;*/
}
.ewlink span{
  padding: 0 1rem;
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
}
.navtitle:hover::after,.action::after,.ewlink span:hover::after{
  position: absolute;
  content: '';
  height: 0.2rem;
  left: 30%;
  bottom:20%;
  width: 40%;
  background: #FF0B41;
}
.tcolor .navtitle:hover::after,.tcolor .action::after,.tcolor .ewlink span:hover::after{
  position: absolute;
  content: '';
  height: .2rem;
  left: 30%;
  bottom:20%;
  width: 40%;
  background: #FF0B41;
}
</style>
