<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>自定义图标</h3>
    <h4><a id="_4"></a>第一步</h4>
    <p>去<a href="https://www.iconfont.cn/home/index?spm=a313x.7781069.1998910419.2" target="_blank">阿里云图标库</a>拷贝svg 代码<br>
      <img src="../../../assets/word/150.png" alt=""></p>
    <h4><a id="_7"></a>第二步</h4>
    <p>在@/assets/icons中新增自己的SVG图标组件，创建xxx.vue文件</p>
    <pre><code class="lang-">&lt;template&gt;
	第一步copy的svg代码粘贴到这里...
&lt;/template&gt;

</code></pre>
    <h4><a id="_16"></a>第三步</h4>
    <p>在@/assets/icons/index.js中引入自定义图标。</p>
    <pre><code class="lang-">export { default as xxx } from './xxx.vue'
</code></pre>
    <h4><a id="_24"></a>第四步</h4>
    <p>在代码中使用</p>
    <pre><code class="lang-">&lt;el-icon size="14px"&gt; &lt;xxx&gt;&lt;/xxx&gt;&lt;/el-icon&gt;
</code></pre>
    <h4><a id="_32"></a>第五步</h4>
    <p>在菜单中使用，添加到</p>
    <pre><code class="lang-">src/config/iconSelect.js
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom22"
}
</script>

<style scoped>

</style>
