<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h2><a ></a>数据库配置</h2>
    <ul>
      <li><a href="#">连接非mysql数据库注意事项</a></li>
      <li><a href="#">集成其他数据库报错怎么办？</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom8"
}
</script>

<style scoped>

</style>
