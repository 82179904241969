<template>
<div class="wordhomw-wrap">

<P>暂无</P>
</div>
</template>

<script>
export default {
  name: "wordCom103"
}
</script>

<style scoped>

</style>
