<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><p>如何自定义任务节点的执行人员呢？</p>
    <p><img src="../../../assets/word/136.png" alt=""></p>
    <p>在前台设置了节点的执行人员之后，执行到该任务节点是会进行自动抽取。<br>
      如何自定义条件呢？<br>
      在前台定义自定义处理条件 _xxx1:<br>
      在后台java类中作如下处理:<br>
      在com..flowable.common.handler.ExtUserTaskActivityBehavior(任务节点)和com..flowable.common.handler.MutiInstanceHandler（会签节点)类中进行处理。</p>
    <pre><div class="hljs"><code class="lang-java"> <span class="hljs-keyword">switch</span> (flowAssignee.getType ()) {
                    <span class="hljs-keyword">case</span> <span class="hljs-string">"user"</span>:
<!--					 ...-->


                    <span class="hljs-keyword">case</span> <span class="hljs-string">"sql"</span>:
                        <span class="hljs-type">Map</span> <span class="hljs-variable">userMap</span> <span class="hljs-operator">=</span> SpringUtil.getBean (JdbcTemplate.class).queryForMap (flowAssignee.getValue ());
                        candidateUserIds.add(userMap.get (<span class="hljs-string">"id"</span>).toString ());
                        <span class="hljs-keyword">break</span>;
                     <span class="hljs-keyword">case</span> <span class="hljs-string">"custom"</span>:
<!--      <span class="hljs-keyword">if</span>(flowAssignee.getValue ().equals ( <span class="hljs-string">"_xxx1"</span> )) {-->
			}

                        <span class="hljs-comment">//根据你的自定义标记，请自行实现</span>
                        <span class="hljs-keyword">break</span>;
</code></div></pre>
    <p>在java代码中，自行根据需要进行用户抽取。</p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom107"
}
</script>

<style scoped>

</style>
