<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>树表</h2>
    <h3><a id="1_1"></a>1.创建表单</h3>
    <p><img src="../../../assets/word/51.png" alt=""><br>
      必须包含的四个字段：name, parent_ids, parent_id, sort。</p>
    <h3><a id="2_5"></a>2.生成代码</h3>
    <p><img src="../../../assets/word/66.png" alt=""><br>
      选择代码模板：树结构表</p>
    <h3><a id="3_9"></a>3.运行结果</h3>
    <p><img src="../../../assets/word/59.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom84"
}
</script>

<style scoped>

</style>
