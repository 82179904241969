<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>外部邮件配置</h1>
    <h3><a id="1_SMTPQQ_1"></a>1 邮箱开启SMTP服务，已QQ邮箱为例</h3>
    <p>如何打开POP3/SMTP/IMAP功能？<br>
      为了保障用户邮箱的安全，QQ邮箱设置了POP3/SMTP/IMAP的开关。系统缺省设置是“关闭”，在用户需要这些功能时请“开启”。<br>
      首先，登录邮箱，进入设置-帐户；<br>
      <img src="../../../assets/word/119.jpg" alt=""></p>
    <p>然后，在“帐户”设置中，找到设置项，进行设置，如下：<br>
      <img src="../../../assets/word/120.jpg" alt=""></p>
    <p>最后，保存设置，即打开了相应的服务。</p>
    <h3><a id="2__13"></a>2 配置自己的邮箱作为邮件服务器</h3>
    <p>系统管理-&gt;系统配置-&gt;系统邮箱设置<br>
      <img src="../../../assets/word/69.png" alt=""><br>
      邮箱：输入QQ邮箱 或者其它邮箱 比如 163邮箱<br>
      密码：输入邮箱密码<br>
      SMTP：QQ的 输入：smtp.qq.com    端口 ：25<br>
      SMTP：163的 输入：smtp.163.com    端口 ：25</p>
    <h3><a id="3__21"></a>3 发送邮件测试</h3>
    <p>常用工具-&gt;外部邮件<br>
      <img src="../../../assets/word/71.png" alt=""></p>
    <h3><a id="4_API_26"></a>4 代码中作为API调用方式</h3>
    <pre><div class="hljs"><code class="lang-java"><span class="hljs-type">SystemConfig</span> <span class="hljs-variable">config</span> <span class="hljs-operator">=</span> systemConfigService.get(<span class="hljs-string">"1"</span>);
<span class="hljs-type">boolean</span> <span class="hljs-variable">isSuccess</span> <span class="hljs-operator">=</span> MailSendUtils.sendEmail(config.getSmtp(), config.getPort(), config.getMailName(), config.getMailPassword(),“xxx<span class="hljs-meta">@qq</span>.com”,  <span class="hljs-string">"邮件标题"</span>, <span class="hljs-string">"这里是邮件内容"</span>, <span class="hljs-string">"0"</span>);
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom97"
}
</script>

<style scoped>

</style>
