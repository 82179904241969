<template>
<div>
  <component :is="componentNames"></component>
</div>
</template>
<script>
import home from '@/components/mian/home'
import product from '@/components/mian/product'
import programme from '@/components/mian/programme'
import ecology from '@/components/mian/ecology'
import Yuncang from '@/components/mian/Yuncang'
import service from '@/components/mian/service'
import about from '@/components/mian/about'
import product4 from '@/components/mian/product/product4'
import programme1 from '@/components/mian/programme/programme1'
import yuncang1 from "@/components/mian/Yuncang/Yuncang1";
import xieyi1 from '@/components/mian/agreement/agreement1'
import xieyi2 from '@/components/mian/agreement/agreement2'
import ClWordLeft from "@/components/clword/ClWordLeft.vue";
import ClWordRight from "@/components/clword/ClWordRight.vue";
import ClWordRightList from "@/components/clword/ClWordRightList.vue";
import Clword from "@/components/clword/index.vue";

export default {
  name: "mianIndex",
  components:{
      ClWordLeft,
      ClWordRight,
      ClWordRightList,
      Clword,
    home,
    product,
    programme,
    ecology,
    Yuncang,
    service,
    about,
    product4,
    programme1,
    yuncang1,
      xieyi1,
      xieyi2
  },
  props:{
    'componentName':{
      type:String,
      default:''
    }
  },
  watch:{
    'componentName'(){
      this.componentNames=this.componentName
    },
  },
  data(){
    return {
      componentNames:'home'
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
