<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>表格行权限</h3>
    <p>如何控制表格的数据对哪些人有可读权限？</p>
    <p>参照 <a href="/#/doc/detail/1585460201252515842/1585462259011612674">数据权限</a></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom"
}
</script>

<style scoped>

</style>
