import { render, staticRenderFns } from "./wordcom77.vue?vue&type=template&id=42882f2c&scoped=true&"
import script from "./wordcom77.vue?vue&type=script&lang=js&"
export * from "./wordcom77.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42882f2c",
  null
  
)

export default component.exports