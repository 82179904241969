<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a></a>前端组件</h1>
    <ul>
      <li><a href="#">路由</a></li>
      <li><a href="#">开发规范</a></li>
      <li><a href="#">组件库</a></li>
      <li><a href="#">表格相关</a></li>
      <li><a href="#">工具类</a></li>
      <li><a href="#">国际化支持</a></li>
      <li><a href="#">布局样式</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom11"
}
</script>

<style scoped>

</style>
