<template>
  <div class="service">
    <!--  轮播-->
    <div class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">
          <img :src="require('../../../assets/'+item.url)" width="100%">
        </div>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <!--    <div class="swiper-button-prev"></div>-->
      <!--    <div class="swiper-button-next"></div>-->

      <!-- If we need scrollbar -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>
    <!--我们的服务-->
    <section>
      <div class="wrap">
        <div class="name">我们的服务</div>
        <div class="servicebox">
            <div class="servicefu">
                <img src="../../../assets/service/service5.png"  style="width: 24rem">
              <div>
                <span>热线电话</span>
                <a href="tel:4000-696-178">4000-696-178</a>
              </div>
            </div>
<!--          <div class="servicefu">-->
<!--            <span>微信客服</span>-->
<!--            <img src="../../../assets/service/service3.png" width="220">-->
<!--          </div>-->
<!--          <div class="servicefu">-->
<!--            <img src="../../../assets/service/service4.png" width="80">-->
<!--            <span></span>-->
<!--            <a href="#">马上联系</a>-->
<!--          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

export default {
  name: "serviceIndex",
  data(){
    return {
      swiperimgData:[
        {
          name:'图片1',
          url:'service/service1.png'
        }
      ],
    }
  },
  mounted() {
    // 轮播
    new Swiper(".swiper", {
      speed: 1000,
      spaceBetween: 100,
      autoplay: {
        delay: 3000,
      },
      modules: [Navigation, Pagination, Scrollbar, Autoplay],
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      // scrollbar: {
      //   el: ".swiper-scrollbar",
      //   draggable: true,
      // },
    });
    //视频
    //
  },
  methods:{

  }

}
</script>

<style lang="scss" scoped>
.service{
  padding-top:6.4rem;
}
.wrap{
  width: 124.0rem;
  padding:20.0rem 0 20.0rem;
  margin: auto;
  .name{
    font-size: 4.0rem;
    color: #000;
    text-align: center;
    margin-bottom: 6.0rem;
  }
  .servicebox{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0rem .8rem 1.9rem .8rem rgba(0,0,0,0.03);
    border-radius: .4rem 10.0rem .4rem 10.0rem;
    padding: 60rem 0;
    .servicefu{
      display: flex;
      //flex-direction: column;
      justify-items: center;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      position: relative;
      width: 100%;
      &:first-child::after{
        width: 0;
      }
      &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 24.0rem;
        width: .1rem;
        background:#E5E5E5 ;
      }
      div{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left:12.0rem ;
      }
      span{
        font-size: 3.2rem;
        margin: 1.0rem 0;
        margin-left: 4.0rem;
      }
      a{
        cursor: pointer;
        text-decoration:none;
        font-size: 8.0rem;
        //border-radius: 32rem;
        //border: 1rem solid #FFEDEF;
        color: #333;
        margin-top: 2.0rem;
        padding: .7rem 1.5rem;
        //&:hover{
        //  background: #eee;
        //}
      }
    }
  }

}

</style>
