<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>登录超时配置</h1>
    <p>打开application.yml文件配置， 设置token过期时长。</p>
    <pre><div class="hljs"><code class="lang-xml">jwt.accessToken.expireTime: 86400  # 86400 ，24*3600  24小时，单位秒
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom94"
}
</script>

<style scoped>

</style>
