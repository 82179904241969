<template>
  <div>
    <el-input
        placeholder="输入关键字进行过滤"
        v-model="filterText">
    </el-input>
    <el-tree
        class="filter-tree"
        :data="data"
        :props="defaultProps"
        :default-expand-all="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        ref="tree">
    </el-tree>
  </div>
</template>

<script>
export default {
  name: "wordLeft",
  data() {
    return {
      filterText: '',
      data: [{
        label: '开发文档',
        name:'wordcom0',
        children: [{
          label: '主页',
          name:'wordcom1'
        },{
          label: '快速入门',
          name:'wordcom2',
          children: [{
            label: '代码结构图',
            name:'wordcom3'

          }]
        },{
          label: '设置环境',
          name:'wordcom4',
          children: [{
            label: '开发环境准备',
            name:'wordcom5'
          },
            {
              label: '前端搭建运行',
              name:'wordcom6'
            },
            {
              label: '后端搭建运行',
              name:'wordcom7'
            },{
              label: '数据库配置',
              name:'wordcom8',
              children: [{
                label: '连接非mysql数据库注意事项',
                name:'wordcom9'

              },{
                label: '集成其他数据库报错怎么办？',
                name:'wordcom10'

              }]
            }]
        },{
          label: '前端组件',
          name:'wordcom11',
          children: [{
            label: '路由',
            name:'wordcom12',
            children: [{
              label: '静态路由',
              name:'wordcom13'

            },{
              label: '动态路由',
              name:'wordcom14'

            },{
            label: '路由对象',
            name:'wordcom15'

          }]
          },
            {
              label: '开发规范',
              name:'wordcom16',
              children: [{
                label: '新建路由',
                name:'wordcom17'

              },{
                label: '新增view',
                name:'wordcom18'

              },{
                label: '新增API',
                name:'wordcom19'

              },{
                label: '新增组件',
                name:'wordcom20'

              },{
                label: '自定义样式',
                name:'wordcom21'

              },{
                label: '自定义图表',
                name:'wordcom22'

              }]
            },
            {
              label: '组件库',
              name:'wordcom23',
              children: [{
                label: '用户',
                name:'wordcom24'

              },{
                label: '部门',
                name:'wordcom25'

              },{
                label: '角色',
                name:'wordcom26'

              },{
                label: '岗位',
                name:'wordcom27'

              },{
                label: '文件上传',
                name:'wordcom28'

              },{
                label: '图片上传',
                name:'wordcom29'

              },{
                label: '图片选择',
                name:'wordcom30'

              }]
            },{
              label: '表格相关',
              name:'wordcom31',
              children: [{
                label: '默认排序/自定义排序',
                name:'wordcom32'

              },{
                label: '表格行权限',
                name:'wordcom33'

              },{
                label: '表格列权限',
                name:'wordcom34'

              }]
            },{
              label: '工具类',
              name:'wordcom35',
              children: [{
                label: '标签操作',
                name:'wordcom36'

              },{
                label: '获取当前登录人信息',
                name:'wordcom37'

              },{
                label: '字典操作',
                name:'wordcom38'

              },{
                label: '时间格式化',
                name:'wordcom39'

              },{
                label: '拷贝',
                name:'wordcom40'

              },{
                label: '权限指令',
                name:'wordcom41'

              }]
            },{
              label: '国际化支持',
              name:'wordcom42',
              children: [{
                label: '菜单/标签国际化',
                name:'wordcom43'

              },{
                label: '内容国际化',
                name:'wordcom44'

              }]
            },{
              label: '布局样式',
              name:'wordcom45',
              children: [{
                label: '布局',
                name:'wordcom46'

              },{
                label: '样式',
                name:'wordcom47'
              }]
            }]
        },{
          label: '后端开发',
          name:'wordcom48',
          children: [{
            label: '开发流程(第一个helloword)',
            name:'wordcom49',
            children: [{
              label: '创建模块',
              name:'wordcom50'

            },{
              label: '设计数据库表',
              name:'wordcom51'
            },{
              label: '创建Domain',
              name:'wordcom52'

            },{
              label: '创建DTO',
              name:'wordcom53'
            },{
              label: '创建VO',
              name:'wordcom54'

            },{
              label: '创建MapStruct',
              name:'wordcom55'
            },{
              label: '创建Mapper',
              name:'wordcom56'

            },{
              label: '创建Service',
              name:'wordcom57'
            },{
              label: '创建Controller',
              name:'wordcom58'

            },{
              label: '接口返回值规范',
              name:'wordcom59'
            }]
          },
            {
              label: '权限相关',
              name:'wordcom60',
              children: [{
                label: '免登陆配置',
                name:'wordcom61'

              },{
                label: '方法鉴权',
                name:'wordcom62'
              },{
                label: '菜单/按钮权限',
                name:'wordcom63'

              },{
                label: '数据权限',
                name:'wordcom64'
              },{
                label: '表格列显示/隐藏',
                name:'wordcom65'

              }]
            },
            {
              label: 'Redis缓存使用',
              name:'wordcom66'
            },{
              label: '分页实现',
              name:'wordcom67'
            },{
              label: '检索实现',
              name:'wordcom68'
            },{
              label: '排序实现',
              name:'wordcom69'
            },{
              label: '导入导出',
              name:'wordcom70'
            },{
              label: '上传下载',
              name:'wordcom71'
            }]
        },{
          label: '快速开发',
          name:'wordcom72',
          children: [{
            label: '代码生成',
            name:'wordcom73',
            children: [{
              label: '代码生成器配置',
              name:'wordcom74'
            },{
              label: '智能表单',
              name:'wordcom75',
              children: [{
                label: '数据库表设置',
                name:'wordcom76'

              },{
                label: 'JAVA实体设置',
                name:'wordcom77'
              },{
                label: '表单页面',
                name:'wordcom78'

              },{
                label: '表单校验',
                name:'wordcom79'
              },{
                label: '列表页面',
                name:'wordcom80'

              }]
            },{
              label: '生成示例',
              name:'wordcom81',
              children: [{
                label: '单表',
                name:'wordcom82'

              },{
                label: '主附表',
                name:'wordcom83'
              },{
                label: '树表',
                name:'wordcom84'

              },{
                label: '富文本',
                name:'wordcom85'
              },{
                label: '图片管理',
                name:'wordcom86'

              },{
                label: '一对多(自定义树组件)',
                name:'wordcom87'
              },{
                label: '一对多(自定义Grid)',
                name:'wordcom88'

              },{
                label: '多对多',
                name:'wordcom89'
              },{
                label: '左树右表',
                name:'wordcom90'

              }]

            }]
          },{
            label: '多数据配置',
            name:'wordcom91'
          },{
            label: '修改/添加包名',
            name:'wordcom92'
          },{
            label: '接口开发',
            name:'wordcom93'
          },{
      label: '登录超时配置',
            name:'wordcom94'
    }]
        },{
          label: '相关配置',
          name:'wordcom95',
          children: [{
            label: '动态表单',
            name:'wordcom96'
          },{
            label: '邮件配置',
            name:'wordcom97'
          },{
            label: '短信配置',
            name:'wordcom98'
          }]
        },{
          label: '打包部署',
          name:'wordcom99',
          children: [{
            label: 'war包部署',
            name:'wordcom100'
          },{
            label: 'jar包部署',
            name:'wordcom101'
          },{
            label: 'nginx包部署',
            name:'wordcom102'
          },{
            label: 'jar包加密',
            name:'wordcom103'
          }]
        },{
          label: '工作流相关',
          name:'wordcom104',
          children: [{
            label: '外置流程表单',
            name:'wordcom105'
          },{
            label: '动态流程表单',
            name:'wordcom106'
          },{
            label: '节点人员配置',
            name:'wordcom107'
          },{
      label: '自定义按钮',
            name:'wordcom108'
    },{
      label: '会签流程',
            name:'wordcom109'
    },{
      label: '常见问题',
            name:'wordcom110'
    }]
        },{
          label: '报表设计',
          name:'wordcom111',
        },{
          label: '单点登录',
          name:'wordcom112',
          children: [{
            label: '单点登录流程',
            name:'wordcom113'
          },{
            label: 'CAS服务器搭建',
            name:'wordcom114'
          },{
            label: 'JeepPlus后端对接CAS',
            name:'wordcom115'
          },{
            label: 'Vue前端开启CAS登录',
            name:'wordcom116'
          }]
        },{
          label: '多租户相关',
          name:'wordcom117',
          children: [{
            label: '多租户配置',
            name:'wordcom118'
          },{
            label: '租户共享数据配置',
            name:'wordcom119'
          }]
        },{
          label: '文件服务',
          name:'wordcom120',
          children: [{
            label: '使用minio',
            name:'wordcom121'
          },{
            label: '使用阿里云',
            name:'wordcom122'
          },{
            label: '使用本地存储',
            name:'wordcom123'
          }]
        },{
          label: 'office管理[WPS集成]',
          name:'wordcom124'
        },{
          label: '图表设计',
          name:'wordcom125'
        },{
          label: '数据大屏',
          name:'wordcom126',
          children: [{
            label: '静态数据源配置图表',
            name:'wordcom127'
          },{
            label: 'SQL数据源配置图表',
            name:'wordcom128'
          },{
            label: '数据集配置图表',
            name:'wordcom129'
          },{
            label: 'API接口配置图集',
            name:'wordcom130'
          },
          {
            label: '框架新增大屏组件',
            name:'wordcom131'
          }
          ]
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      if(data.name!==''){
        this.$store.state.wordName=data.name
      }
    }
  }
}
</script>

<style  lang="scss">
.el-tree{background:#f5f7f9 !important; padding: 1rem }
.el-tree-node__content{height: 6rem !important;font-size: 2.4rem}
.el-tree-node:focus>.el-tree-node__content{color:#409eff !important;}
</style>
