<template>
  <div class="programme">
    <!--  轮播-->
    <div class="swiper">
      <el-row>

        <el-col :span="12" style="padding-right: 15rem">
          <h3>经典案例<br/>众多企业都在用</h3>
          <h5>
            寻找适合您企业的应用案例
          </h5>
        </el-col>
        <el-col :span="12">
          <img src="../../../assets/programme/programme1.png" width="100%">
        </el-col>
      </el-row>
      <!-- Additional required wrapper -->
<!--      <div class="swiper-wrapper">-->
        <!-- Slides -->
<!--        <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
<!--          <img :src="require('../../../assets/'+item.url)" width="100%">-->
<!--        </div>-->
<!--      </div>-->
      <!-- If we need pagination -->
<!--      <div class="swiper-pagination"></div>-->

      <!-- If we need navigation buttons -->
<!--          <div class="swiper-button-prev"></div>-->
<!--          <div class="swiper-button-next"></div>-->

      <!-- If we need scrollbar -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>
    <!--我们的方案-->
    <section>
      <div class="wrap">
        <div class="name">我们的方案</div>
        <div class="swiper2" style="position: relative; display: block; overflow: hidden;margin-left: -30px" >
          <div class="swiper-wrapper">
            <div class="programmelist swiper-slide">
              <div  class="list-box" style="height: 800px">
<!--                <div  class="list" v-for="(item,index) in planList" :key="index" @click="clicknum(item.id)" style="width: 370px;height: 350px">-->
<!--                  <img :src="item.firstPhoto" width="350px" style="height: 220px"/>-->
<!--                  <div class="tetxbox">-->
<!--                    <div class="name">{{item.title}}</div>-->
<!--                    <div class="text"> {{item.synopsis}}</div>-->
<!--                  </div>-->
<!--                </div>-->
                  <el-carousel  style="width: 1500px;height: 1000px" indicator-position="none" :autoplay="false" arrow="never" ref="cardShow">
                      <el-carousel-item style="flex-wrap: wrap;justify-content: space-between;height: 1000px" v-for="itemPage in Math.ceil(total/6)" :key="itemPage">
                          <div class="imgbox list" style="
                                text-align: center;
                                margin-bottom: 10px;
                                float: left;
                                margin-left: 30px;margin-top: 30px;width: 370px;height: 350px" :span="4" v-for="(item, index) in planList.slice((itemPage - 1) * 6, itemPage * 6)" :key="index"  @click="clicknum(item.id)">
                              <img :src="item.firstPhoto" width="370px" style="height: 220px"/>
                              <div class="tetxbox">
                                  <div class="name">{{item.title}}</div>
                                  <div class="text"> {{item.synopsis}}</div>
                              </div>
                          </div>
                      </el-carousel-item>
                  </el-carousel>
                  <div  class="imgBg" style="z-index: 999;cursor: pointer;width: 35px;height: 35px;position: absolute;margin-top:-5px;margin-left: -40px"  @click="prePage" >
                  </div>
                  <div  class="imgBg1" style="z-index: 999;cursor: pointer;width: 35px;height: 35px;position: absolute;margin-top:-5px;margin-left: 1230px"  @click="nextPage" >
                  </div>
              </div>
<!--                <div v-if="planList.length<=3" class="list-box" style="height: 800px">-->
<!--                    <div  class="list" v-for="(item,index) in planList" :key="index" @click="clicknum(item.id)" style="width: 370px;height: 350px;margin-top: -400px">-->
<!--                        <img :src="item.firstPhoto" width="350px" style="height: 220px"/>-->
<!--                        <div class="tetxbox">-->
<!--                            <div class="name">{{item.title}}</div>-->
<!--                            <div class="text"> {{item.synopsis}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div  class="imgBg" style="z-index: 999;cursor: pointer;width: 50px;height: 50px;position: absolute;margin-top:-40px;margin-left: -70px"  @click="prePage" v-if="!(pageNum===1&&nextflag===6)">-->
<!--                    </div>-->
<!--                    <div  class="imgBg1" style="z-index: 999;cursor: pointer;width: 50px;height: 50px;position: absolute;margin-top:-40px;margin-left: 1220px"  @click="nextPage" v-if="total>nextflag+((pageNum-1)*pageSize)">-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";
import newService from "@/api/newService";
export default {
  name: "programmeIndex",
  data(){
    return {
        nextflag:6,
      planList:[],
        pageNum:1,
        //全查吧，别分页了
        pageSize: 6600,
        total:0
    }
  },
    created() {
      this.getPlanList()
    },
    mounted() {
    this.$nextTick(()=>{
      // 轮播1
      // new Swiper(".swiper", {
      //   speed: 1000,
      //   spaceBetween: 100,
      //   autoplay: {
      //     delay: 3000,
      //   },
      //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
      //   // navigation: {
      //   //   nextEl: ".swiper-button-next",
      //   //   prevEl: ".swiper-button-prev",
      //   // },
      //   pagination: {
      //     el: '.swiper-pagination',
      //     type: 'bullets',
      //     clickable :true,
      //   },
      //   // scrollbar: {
      //   //   el: ".swiper-scrollbar",
      //   //   draggable: true,
      //   // },
      // });
      //轮播2
      new Swiper(".swiper2", {
        speed: 1000,
        spaceBetween: 100,
        initialSlide: 0,
        modules: [Navigation, Pagination, Scrollbar, Autoplay],
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // pagination: {
        //   el: '.swiper-pagination2',
        //   type: 'bullets',
        //   clickable :true,
        // },
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        //   draggable: true,
        // },
      });
    })

  },
  methods:{
    clicknum(id){
      localStorage.setItem('planId',id)
      this.$store.state.components='programme1'
      this.$store.state.isbackground=true
      this.$store.state.iscolor=true
      this.$store.state.islogored=true
      document.body.scrollTop=document.documentElement.scrollTop=0

      // this.$emit('programmeMess')
    },
      getPlanList(){
        var obj={
            pageNum: this.pageNum,
            pageSize: this.pageSize
        }
        newService.getPlanList(obj).then((res)=>{
            this.planList=res.rows
            this.total=res.total
        })
      } ,
      prePage(){
         this.$refs.cardShow.prev()

      },
      nextPage(){
          this.$refs.cardShow.next()
      }
    }

}
</script>

<style lang="scss" scoped>


.imgBg{
  cursor: pointer;
  z-index: 99999;
  background: url('../../../assets/left.png') no-repeat;
  background-size: 35px;
  &:hover{
    background: url('../../../assets/left1.png') no-repeat;
    background-size: 35px;
  }
}

.imgBg1{
  cursor: pointer;
  z-index: 99999;
  background: url('../../../assets/right.png') no-repeat ;
  background-size: 35px;
  &:hover{
    background: url('../../../assets/right1.png') no-repeat ;
    background-size: 35px;
  }
}


.swiper{
  width: 100%;
  background: url('../../../assets/programme/programme1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 12rem 0 10rem;
    margin: auto;
    text-align: left;
    h3{

      margin-top: 10rem;
      font-size: 5rem;
      margin-bottom: 2rem;
    }
    h5{
      font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.programme{
  padding-top:0rem;

}
.wrap{
  width: auto;
  padding:20rem 0 16rem;
  margin: auto;
  display: block;
  .name{
    font-size: 4rem;
    color: #000;
    text-align: center;
    margin-bottom: 10rem;
  }

  .programmelist{

    .list-box{
      width: 120rem;
      min-width: 120rem;
      margin:0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    .list{
      width: 38rem;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0rem .3rem 1.2rem 0rem rgba(0,0,0,0.06);
      border-radius: .6rem;
      margin-bottom: 4rem;
      overflow: hidden;
      cursor: pointer;
      img{
        border-radius: .6rem .6rem 0 0;
      }
      &:hover {
        img{
          transform: scale(1.1);
        }
        box-shadow: 0rem .3rem 1.2rem 0rem rgba(0,0,0,0.5);
      }
      .tetxbox{
        padding: 2.4rem;
        .name{
          font-size: 2rem;
          color: #333;
          margin-bottom: 1.2rem;
          text-align: left;
          font-weight: bold;
        }
        .text{
          overflow: hidden; //超出的文本隐藏
          font-size: 1.4rem;
          text-overflow: ellipsis; //溢出用省略号显示
          font-weight: 400;
          display: -webkit-box;
          color: #444955;
          -webkit-line-clamp: 2; // 超出多少行
          line-height: 2.2rem;
          -webkit-box-orient: vertical;
        }

      }
    }
  }

}

</style>
