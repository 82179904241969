<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>打包发布</h1>
    <h2><a id="1__1"></a>1. 快速部署</h2>
    <h3><a id="___2"></a>一、 后台工程 打包</h3>
    <p>1 修改application-production.yml文件</p>
    <p>1.1 修改数据库连接为线上服务器的数据库连接</p>
    <pre><div class="hljs"><code class="lang-xml">      datasource:
        master:
          username: root
          password: root
          url: jdbc:mysql://127.0.0.1:3306/?useUnicode=true&amp;characterEncoding=utf-8&amp;useSSL=false&amp;nullCatalogMeansCurrent=true&amp;serverTimezone=GMT
          driver-class-name: com.mysql.cj.jdbc.Driver
</code></div></pre>
    <p>1.2 修改log级别为ERROR</p>
    <pre><div class="hljs"><code class="lang-xml">logging:
  level:
    root: ERROR
    #    org.flowable: ERROR
    com.: ERROR
    org.a
1.1 修改web/pom.xml文件
将war修改为jar
```xml
  <span class="hljs-tag">&lt;<span class="hljs-name">artifactId</span>&gt;</span>web<span class="hljs-tag">&lt;/<span class="hljs-name">artifactId</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">packaging</span>&gt;</span>jar<span class="hljs-tag">&lt;/<span class="hljs-name">packaging</span>&gt;</span>
</code></div></pre>
    <p>1.2 启动 jar</p>
    <pre><code class="lang-language">nohup java -jar plus.jar
</code></pre>
    <p>2 修改 application.yml文件<br>
      切换配置为生产环境配置</p>
    <pre><div class="hljs"><code class="lang-xml">spring:
  profiles:
    active: production
</code></div></pre>
    <p>3 打包</p>
    <p>首先 ，在根目录vue目录下，执行install</p>
    <p><img src="tu1.png" alt=""></p>
    <p>然后，在web目录下，执行package</p>
    <p><img src="tu2.png" alt=""></p>
    <p>在web目录下的target目录下会生成一个vue.war文件，这就是后台部署程序。</p>
    <p>二、前台工程ui打包发布</p>
    <p>进入ui根目录</p>
    <p>1 先执行yarn install命令。</p>
    <p>2 打开.env.production文件，进行生产环境配置</p>
    <pre><div class="hljs"><code class="lang-xml"># 生产环境配置
ENV = 'production'
#快速开发平台/后台地址, 使用多租户时，相对地址，解决跨域问题。
VUE_APP_SERVER_URL = ‘/api’
</code></div></pre>
    <p>3 在ui目录下，执行打包命令：yarn build， 生成一个dist目录就是前台的部署程序。</p>
    <p>三、线上部署。</p>
    <p>1 在linux服务器上安装mysql。</p>
    <p>2 设置mysql数据库不区分大小写。<br>
      Linux 系统默认情况下MySQL数据库是区分大小写的；因此要将mysql设置成不区分大小写，在/etc/my.cnf 配置后面加上一行（0：大小写敏感；1：大小写不敏感）</p>
    <pre><div class="hljs"><code class="lang-xml">lower_case_table_names=1
</code></div></pre>
    <p>最后重启一下MySql服务即可</p>
    <p>3 用utf8编码在服务器上创建数据库，并执行.sql脚本将数据导入。</p>
    <pre><div class="hljs"><code class="lang-xml">CREATE DATABASE  ``  DEFAULT CHARACTER SET utf8 COLLATE utf8_general_ci;
use ;
source .sql;
</code></div></pre>
    <p>4  拷贝dist下的内容到nginx目录下的html目录下。注意：如果你部署在公网，强力推荐使用cdn对前端资源进行加速，可以大大提高用户首次访问系统的速度。</p>
    <p>5 nginx配置</p>
    <p>nginx配置, 打开conf/nginx.conf<br>
      开启nginx压缩</p>
    <pre><div class="hljs"><code class="lang-xml">  gzip on;
    gzip_min_length 1k;
    gzip_comp_level 9;
    gzip_types text/plain application/javascript application/x-javascript text/css application/xml text/javascript application/x-httpd-php image/jpeg image/gif image/png;
    gzip_vary on;
    gzip_disable "MSIE [1-6]\.";
</code></div></pre>
    <p>注意:<br>
      一定要配置file，否则附件将不能正常显示。<br>
      一定要配置ureport, 否则报表不能正常打开。<br>
      如果是多租户，请配置多个域名（你在租户管理中添加的域名)，例如：wx..org sz..org cloud..org， 可实现根据域名访问实现租户切换。</p>
    <pre><div class="hljs"><code class="lang-xml"> server {
        listen    80;
        server_name  47.93.149.183 wx..org sz..org cloud..org;
        client_max_body_size 100m;

        location /api/ {
            proxy_pass              http://127.0.0.1:8088/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }
        location /ureport/ {
            proxy_pass              http://127.0.0.1:8088/ureport/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }
        location /file/ {
            proxy_pass              http://127.0.0.1:8088/file/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }

        location / {
                root   /usr/share/nginx/html/cloud;
                index  index.html index.htm;
        }

        location /h5 {
                alias    /usr/share/nginx/html/cloud-h5;
                index  index.html index.htm;
        }


    }
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom101"
}
</script>

<style scoped>

</style>
