<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>集成其他数据库报错怎么办</h3>
    <p>目前已经提供的数据库脚本，oracle，mysql， pgsql，sqlserver。集成其他数据库报错怎么办呢？</p>
    <h4><a ></a>这里只提供一种解决思路。</h4>
    <p>例如： 使用postgresql集成flowable6.7.2工作流，调用保存模型方法时，抛出以下异常。</p>
    <p><img src="../../../assets/word/163.png" alt=""></p>
    <p>试图把blob类型转换成long处理，所以抛出了异常，需要修改postgresql驱动包里面的代码。</p>
    <p>如果从github上下载全部代码，维护成本过高，且不利于后期升级。因此，我们只修改这个类即可。</p>
    <h4><a ></a>解决方法</h4>
    <p>在-flowable 建一个同名目录  org.postgresql.jdbc，将PgResultSet.java文件拷贝到该目录下，该文件将覆盖jar包中的同名文件。</p>
    <p>修改383行</p>
    <pre><code class="lang-language">    @Pure
    @Nullable
    public Blob getBlob(int i) throws SQLException {
        byte[] value = this.getRawValue(i);

        return value == null ? null : new javax.sql.rowset.serial.SerialBlob(value);
    }
</code></pre>
    <p>所以通过对第三方jar包的最小改动，用最低成本解决了兼容性问题。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom10"
}
</script>

<style scoped>

</style>
