<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_2"></a>样式</h3>
    <p>样式文件按照组件功能划分不同配置在不同的scss文件中，然后通过入口文件style.scss统一import便于维护和管理。</p>
    <pre><code class="lang-">┌── style			# 全局样式
│	├── style.scss		# 总入口
│	├── app.scss		# 框架样式
│	├── fix.scss		# element plus的自定义样式
│	├── media.scss		# 媒体查询样式(移动端样式)
│	├── vxtable.scss	# 表格样式
│	├── /theme/dark.scss	# 暗黑菜单样式
│	├── pages.scss		# 页面样式
│	└── ***.scss		# 其他自定义

</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom47"
}
</script>

<style scoped>

</style>
