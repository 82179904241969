<template>
<div class="product">
  <!--  轮播-->
  <div class="swiper">
    <el-row>
      <el-col :span="12">
        <img src="../../../assets/product/product1.png" width="100%">
      </el-col>
    <el-col :span="12" style="padding-left: 15rem">
      <h3>快速构建<br/>企事业单位管理系统</h3>
      <h5>
        有效降低运营成本，快速完成项目交付
         </h5>
    </el-col>

    </el-row>
    <!-- Additional required wrapper -->
<!--    <div class="swiper-wrapper">-->
      <!-- Slides -->
<!--      <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
<!--        <img :src="require('../../../assets/'+item.url)" width="100%">-->
<!--      </div>-->
<!--    </div>-->
    <!-- If we need pagination -->
<!--    <div class="swiper-pagination"></div>-->

    <!-- If we need navigation buttons -->
    <!--    <div class="swiper-button-prev"></div>-->
    <!--    <div class="swiper-button-next"></div>-->

    <!-- If we need scrollbar -->
    <!--    <div class="swiper-scrollbar"></div>-->
  </div>
  <!--我们的产品-->
  <section style="padding-bottom:20rem">
    <div class="wrap" style="width: auto">
      <div class="name">我们的产品</div>
      <div class="swiper2" style="position: relative; display: block; margin-left: -30px">
        <div class="swiper-wrapper" >
          <div class="imglist swiper-slide">
            <div class="list-box" style="height: 600px">
<!--                <div class="imgbox"  v-for="item in products" :key="item.id" @click="product4(item.id)" style="height: 320px;width: 350px">-->
<!--                    <img :src=item.firstPhoto>-->
<!--                    <span >{{ item.chName }}</span>-->
<!--                    <i >{{ item.enName }}</i>-->
<!--                </div>-->
                <el-carousel  style="width: 1500px;height: 1000px" indicator-position="none" :autoplay="false" arrow="never" ref="cardShow">
                    <el-carousel-item style="flex-wrap: wrap;justify-content: space-between;height: 1000px" v-for="itemPage in Math.ceil(total/6) " :key="itemPage">
                            <div class="imgbox" style=" width: 360px;
                                text-align: center;
                                margin-bottom: 10px;
                                float: left;
                                margin-left: 32px;margin-top: 30px" :span="8" v-for="(item, index) in products.slice((itemPage - 1) * 6, itemPage * 6)" :key="index" @click="product4(item.id)">
                                <img :src=item.firstPhoto>
                                <span >{{ item.chName }}</span>
                                <i >{{ item.enName }}</i>
                            </div>
                    </el-carousel-item>
                </el-carousel>
                <div  class="imgBg" style="z-index: 999;cursor: pointer;width: 35px;height: 35px;position: absolute;margin-top:372px;margin-left: -55px"  @click="prePage" >
                </div>
                <div  class="imgBg1" style="z-index: 999;cursor: pointer;width: 35px;height: 35px;position: absolute;margin-top:372px;margin-left: 1215px"  @click="nextPage" >
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";
import newService from "@/api/newService";

export default {
  name: "productIndex",
  data(){
    return {
        nextflag:6,
        pageLength:100,
        pageNum: 1,
        //全查吧，别分页了
        pageSize:6600,
        total:0,
        products:[]
    }
  },
    created() {

    },
    mounted() {
      this.getProductsList()
      this.$nextTick(()=>{
      // 轮播1
      // new Swiper(".swiper", {
      //   speed: 1000,
      //   spaceBetween: 100,
      //   autoplay: {
      //     delay: 3000,
      //   },
      //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
      //   // navigation: {
      //   //   nextEl: ".swiper-button-next",
      //   //   prevEl: ".swiper-button-prev",
      //   // },
      //   pagination: {
      //     el: '.swiper-pagination',
      //     type: 'bullets',
      //     clickable :true,
      //   },
      //   // scrollbar: {
      //   //   el: ".swiper-scrollbar",
      //   //   draggable: true,
      //   // },
      // });
      //轮播2
      //轮播2
      new Swiper(".swiper2", {
        speed: 1000,
        spaceBetween: 100,
        initialSlide: 0,
        modules: [Navigation, Pagination, Scrollbar, Autoplay],
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // pagination: {
        //   el: '.swiper-pagination2',
        //   type: 'bullets',
        //   clickable :true,
        // },
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        //   draggable: true,
        // },
      });
    })

    //
  },
  methods:{
    productzong(){
      this.$store.state.isbackground=false
      this.$store.state.iscolor=true
      this.$store.state.islogored=true
      document.body.scrollTop=document.documentElement.scrollTop=0
    },
    product4(id){
        localStorage.setItem('productId',id)
      this.$store.state.components='product4'
      this.productzong()
    },
      getProductsList(){
        var obj={
            pageNum:this.pageNum,
            pageSize:this.pageSize
        }
        newService.getProductsList(obj).then((res)=>{
            this.total=res.total
            this.products=res.rows
        })
      },
      prePage(){
          this.$refs.cardShow.prev()
      },
      nextPage(){
          this.$refs.cardShow.next()
        }
  }

}
</script>

<style lang="scss" scoped>

.imgBg{
  cursor: pointer;
  z-index: 99999;
  background: url('../../../assets/left.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: 35px;
  &:hover{
    background: url('../../../assets/left1.png') no-repeat;
    width: 35px;
    height: 35px;
    background-size: 35px;
  }
}

.imgBg1{
  cursor: pointer;
  z-index: 99999;
  width: 35px;
  height: 35px;
  background: url('../../../assets/right.png') no-repeat ;
  background-size: 35px;
  &:hover{
    width: 35px;
    height: 35px;
    background: url('../../../assets/right1.png') no-repeat ;
    background-size: 35px;
  }
}


.swiper{
  width: 100%;
  background: url('../../../assets/product/product1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 12rem 0 10rem;
    margin: auto;
    text-align: right;
    h3{

      margin-top: 10rem;
      font-size: 5rem;
      margin-bottom: 2rem;
    }
    h5{
      font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.product{
  padding-top:0rem;
}
.wrap{
  width: 120rem;
  min-width: 120rem;
  padding:20rem 0 16rem;
  margin: auto;
  display: block;
  overflow: hidden;
  .name{
    font-size: 4rem;
    color: #000;
    text-align: center;
    margin-bottom: 10rem;
  }
  .imglist{
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-around;
    //align-items: center;
    //height: 130rem;

    .list-box{
      width: 120rem;
      margin:0 auto;
      display: grid;
      grid-template-columns: 37.2rem 37.2rem 37.2rem;
      grid-template-rows: 33.2rem 33.2rem;
      grid-gap: 4rem 4rem;
    }
    .imgbox{
      width: 37.2rem;
      height: 33.2rem;
      margin-bottom: 4rem;
      margin-left: 2px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      border-radius: .4rem;
      align-items: center;
      background: #fff;
      border-top: 0.5rem solid #FD3335;
      box-shadow: 0rem .3rem 1rem 0rem rgb(0 0 0 / 20%);
      cursor: pointer;
      &:hover{
        background: #eee;
        box-shadow: 0rem .3rem 1rem 0rem rgb(0 0 0 / 20%);
      }
      img{
        width: 10rem;
        margin-top: 7rem;
      }
      span{
        font-size: 2.4rem;
        margin-top: 4.8rem;
      }
      i{
        font-style: normal;
        font-size: 2rem;
        font-weight: 300;
        margin-top: .5rem;
      }

    }
  }

}

</style>
