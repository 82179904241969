<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a></a>路由对象</h3>
    <pre><code class="lang-">{
	path: "/login",
	component: () =&gt; import(/* webpackChunkName: "login" */'@/views/sys/login/login'),
	meta: {
		title: "登录"
	}
}
</code></pre>
    <table>
      <thead>
      <tr><th>key</th> <th>说明</th></tr>
      </thead>
      <tbody>
      <tr><td>name</td> <td>路由的标识，必填且与页面组件的name一致，否则 <code>&lt;keep-alive&gt;</code> 页面缓存效果失效。</td></tr>
      <tr><td>path</td> <td>路由在游览器地址栏里的hash值，也就是说这个路由要用什么URL地址来访问。</td></tr>
      <tr><td>component</td> <td>加载的页面组件位置，系统将自动组装成 <code>() =&gt; import(/* webpackChunkName: "home" */ '@/views/home')</code> 的形式。</td></tr>
      <tr><td>children</td> <td>子集路由。</td></tr>
      <tr><td>redirect</td> <td>重定向地址。</td></tr>
      <tr><td>meta</td> <td>元数据，见下方。</td></tr>
      </tbody>
    </table>
    <p>meta</p>
    <table>
      <thead>
      <tr>
        <th>key</th> <th>说明</th>
      </tr>
      </thead>
      <tbody>
      <tr><td>title</td> <td>显示名称。展示在菜单，标签和面包屑等中</td></tr> 			<tr><td>hidden</td> <td>是否隐藏菜单，大部分用在无需显示在左侧菜单中的页面，比如详情页</td></tr>
      <tr><td>affix</td> <td>是否固定，类似首页控制台在标签中是没有关闭按钮的(在菜单中编辑，选择固定在首页）</td></tr> <tr><td>icon</td> <td>显示图标，建立2级菜单都设置图标，否则菜单折叠都将显示空白</td></tr>
      <tr><td>type</td> <td>类型：菜单，Iframe，外链，按钮</td></tr>
      <tr><td>hiddenBreadcrumb</td> <td>是否隐藏面包屑</td></tr>
      <tr><td>active</td> <td>左侧菜单的路由地址活动状态，比如打开详情页需要列表页的菜单活动状态</td></tr>
      <tr><td>color</td> <td>颜色值</td></tr> <tr><td>fullpage</td> <td>是否整页打开路由（脱离框架系），例如：<code>fullpage: true</code></td></tr>
      </tbody></table></div>

</div>
</template>

<script>
export default {
  name: "wordCom15"
}
</script>

<style scoped>

</style>
