<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="JAVA_5"></a>JAVA实体设置</h3>
    <p><img src="../../../assets/word/101.png" alt=""></p>
    <ul>
      <li>Java类型：实体对象的属性字段类型</li>
      <li>Java属性名称：如果是基本类型（例如：int，string）使用字符串，如果是对象类型（例如User,Office，自定义java对象），请选择需要关联查询的字段，id必选，除了id之外还必须选择至少一个字段。保存之后会生成“对象名.属性名|属性名2|属性名3“（例如：用户user.id|name|loginName，属性名2和属性名3为生成mapper文件时可以关联查询出来的字段）</li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom77"
}
</script>

<style scoped>

</style>
