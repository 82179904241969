<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>表格设置默认排序/自定义排序</h3>
    <p>这里是排序的前端实现，后端实现请参照<a href="/#/doc/detail/1585460201252515842/1590309090086449154">后端排序</a></p>
    <h4><a id="_3"></a>可以在前端设置默认排序</h4>
    <p>在orders中添加默认排序</p>
    <pre><code class="lang-language">      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orders: [{ column: "a.create_time", asc: false }],
      },
</code></pre>
    <p>升序 asc: true, 降序 asc: false</p>
    <h4><a id="sortby__14"></a>通过sort-by 设置排序别名</h4>
    <p>默认情况下，表格是通过传递field属性名当做参数在后台进行排序，但如果后台list操作是多表组合查询，将抛出错误，<br>
      所以需要通过sort-by设置排序别名。</p>
    <pre><code class="lang-">  &lt;vxe-column sort-by="a.create_date" title="发表时间"  field="createDate" sortable="custom"&gt;&lt;/vxe-column&gt;
</code></pre>
    <h4><a id="_24"></a>排序方法的实现</h4>
    <pre><code class="lang-">    sortChangeHandle (obj) {
        this.tablePage.orders = []

        if (obj.order != null) {
          this.tablePage.orders = [{column: obj.column.sortBy || this.$utils.toLine(obj.property), asc: obj.order === 'asc'}]
        }else{
          this.tablePage.orders = [{column: 'a.create_time', asc: false}]
        }
        this.refreshList()
      },
</code></pre>
    <p>特别注意：设置排序字段时，请使用数据库字段而不是java字段，property属性是java字段，我们通过toLine方法把它转成了数据库字段。如果没有设置sort-by属性，则通过property属性进行排序。</p>
    <p>你一定好奇字段前面为什么要加上a.（例如a.create_date), 这是因为我们使用了多表组合查询， findList 这个方法是我们自己实现的，不是mybatis-plus的原生方法，在对应的mapper.xml文件中，xxxx表的别名就是a， 所以加上"a."代表是xxxx表的字段。如实是单表查询，查询list的方法是使用mybatis_plus原生方法，直接使用字段名即可（例如：create_date), 但是这个时候因为property属性值是createDate，所以我们不需要设置sort-by属性。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom32"
}
</script>

<style scoped>

</style>
