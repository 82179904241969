<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h1><a id="_Vue3__0"></a>文档</h1>
    <ul>
      <li><a href="#">主页</a></li>
      <li><a href="#">快速入门</a></li>
      <li><a href="#">设置环境</a></li>
      <li><a href="#">前端组件</a></li>
      <li><a href="#">后端开发</a></li>
      <li><a href="#">快速开发</a></li>
      <li><a href="#">相关配置</a></li>
      <li><a href="#">打包部署</a></li>
      <li><a href="#">工作流相关</a></li>
      <li><a href="#">报表设计</a></li>
      <li><a href="#">单点登录</a></li>
      <li><a href="#">多租户相关</a></li>
      <li><a href="#">文件服务</a></li>
      <li><a href="#">office管理【WPS集成】</a></li>
      <li><a href="#">图表设计</a></li>
      <li><a href="#">数据大屏</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom0"
}
</script>

<style scoped>
</style>
