<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a ></a>使用其他数据库</h3>
    <h4><a id="_6"></a>分页配置</h4>
    <p>默认使用mysql 数据库， 如果使用其他数据库，请修改分页配置 MybatisPlusConfig.java#36的代码。</p>
    <p>使用Postgresql数据库，修改如下</p>
    <pre><code class="lang-language">        PaginationInnerInterceptor paginationInnerInterceptor = new PaginationInnerInterceptor (DbType.POSTGRE_SQL);
</code></pre>
    <p>使用Oracle数据库，修改如下</p>
    <pre><code class="lang-">        PaginationInnerInterceptor paginationInnerInterceptor = new PaginationInnerInterceptor (DbType.ORACLE);
</code></pre>
    <p>使用SqlServer数据库，修改如下</p>
    <pre><code class="lang-">        PaginationInnerInterceptor paginationInnerInterceptor = new PaginationInnerInterceptor (DbType.SQL_SERVER);
</code></pre>
    <h4><a id="yml_26"></a>数据库链接配置（修改yml文件)</h4>
    <p>mysql配置</p>
    <pre><code class="lang-language">     username: root
     password: root
     url: jdbc:mysql://127.0.0.1:3306/-vue-base?useUnicode=true&amp;characterEncoding=utf-8&amp;useSSL=false&amp;nullCatalogMeansCurrent=true&amp;serverTimezone=GMT%2B8
     driver-class-name: com.mysql.cj.jdbc.Driver
</code></pre>
    <p>oracle配置</p>
    <pre><code class="lang-language">     username: monitor
     password: monitor
     url: jdbc:oracle:thin:@localhost:1521:helowin
     driver-class-name: oracle.jdbc.driver.OracleDriver
</code></pre>
    <p>postgresql配置</p>
    <pre><code class="lang-language">     username: postgres
     password: password
     driver-class-name: org.postgresql.Driver
     url: jdbc:postgresql://localhost:54321/-vue-pg
     estSql: SELECT 'x'
</code></pre>
    <p>mssql配置</p>
    <pre><code class="lang-language">     username: sa
     password: yourStrong(!)Password
     driver-class-name: net.sourceforge.jtds.jdbc.Driver
     url: jdbc:jtds:sqlserver://localhost:1433;SelectMethod=cursor;DatabaseName=
     SelectMethod: cursor
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom9"
}
</script>

<style scoped>

</style>
