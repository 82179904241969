import { render, staticRenderFns } from "./wordcom58.vue?vue&type=template&id=7556a310&scoped=true&"
import script from "./wordcom58.vue?vue&type=script&lang=js&"
export * from "./wordcom58.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7556a310",
  null
  
)

export default component.exports