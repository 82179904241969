<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>接口返回值规范</h2>
    <p> 在Vue侧是根据http状态码来判断接口是否正常返回了值。</p>
    <h3><a id="_5"></a>状态码</h3>
    <table>
      <thead>
      <tr>
        <th>状态码</th>
        <th>描述</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>200</td>
        <td>请求成功</td>
      </tr>
      <tr>
        <td>400</td>
        <td>请求非法</td>
      </tr>
      <tr>
        <td>404</td>
        <td>接口不存在</td>
      </tr>
      <tr>
        <td>500</td>
        <td>后台抛出异常</td>
      </tr>
      </tbody>
    </table>
    <p>404和500由后台框架自动抛出异常，400是我们代码指定的非法请求异常，vue在response（src/utils/httpRequest.js）处理中已经做了拦截处理，无需用户处理。</p>
    <pre><code class="lang-language">/**
 * 响应拦截
 */
axios.interceptors.response.use(response =&gt; {
  if (loading) {
    loading.close()
  }
  return response.data
}, error =&gt; {
  if (loading) {
    loading.close()
  }
  // eslint-disable-next-line no-debugger
  if (error.response) {
    // eslint-disable-next-line no-debugger
    if (error.response.status === 408) { // 超时自动刷新
        ElMessageBox.confirm('当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。', '无权限访问', {
          type: 'error',
          closeOnClickModal: false,
          center: true,
          confirmButtonText: '重新登录'
        }).then(() =&gt; {
          clearLoginInfo()
          router.replace({path: '/login'});
        })
      } else if (error.response.status === 401) { // 需要重新登录
        ElNotification.error({
          title: "登录失败",
          message: error.response.data,
        })
        clearLoginInfo()
        router.replace({path: '/login'});
      } else if (error.response.status === 404) { // 路径找不到
        ElNotification.error({
          title: "404",
          message: '路径找不到' + ': ' + error.config.url
        });
      } else if (error.response.status === 503) {
        ElNotification.error({
          title: "503",
          message: '服务不可用' + ': ' + error.config.url
        });
      } else if (error.response.status === 504) {
        ElNotification.error({
          title: "504",
          message: '网络连接错误' + ': ' + error.data
        });
      } else if (error.response.status === 400) {
        ElNotification.error({
          title: "请求失败",
          message: error.response.data || error.data || error
        });
      }  else {
        ElNotification.error({
          title: "系统错误",
          message: error.response&amp;&amp;error.response.data || error.data || error
        });
      }
  }

  return Promise.reject(error)
})
</code></pre>
    <h3><a id="_83"></a>示例代码</h3>
    <h4><a id="_85"></a>返回成功</h4>
    <p>后台代码</p>
    <pre><code class="lang-">return ResponseEntity.ok ( "保存请假成功" );
</code></pre>
    <p>前台代码</p>
    <pre><code class="lang-language">this.testFormLeaveService.save(this.inputForm).then((data) =&gt; {
      this.visible = false
      this.$message.success(data)
      this.$emit('refreshDataList')
      this.loading = false
})
</code></pre>
    <h4><a id="_102"></a>返回失败</h4>
    <p>后台代码</p>
    <pre><code class="lang-">return ResponseEntity.badRequest ().body ("越权操作，只有平台管理员才能添加或修改数据！");
</code></pre>
    <p>默认vue侧方法不需要处理失败消息（状态码400)，已经在response中做了拦截。如果确实需要在失败时处理，添加catch 捕获异常即可。</p>
    <pre><code class="lang-"> this.testFormLeaveService.save(this.inputForm).then(data =&gt; {
       this.visible = false
       this.$message.success(data)
       this.$emit('refreshDataList')
       this.loading = false
 }).catch(() =&gt; {
	//请求失败时的处理方法
       this.loading = false
 })

</code></pre>
    <h3><a id="_124"></a>处理多个返回值</h3>
    <p>ResponseEntity.ok只能处理一个返回值，且不能为返回值指定属性名，前台都是用data接收的。如果处理多个返回值或者想指定返回的属性名怎么办呢？</p>
    <p>使用工具类 ResponUtil</p>
    <p>示例代码如下</p>
    <p>后台代码</p>
    <pre><code class="lang-"> return ResponseUtil.newInstance ().add("flag", true ).add("index", "file:").ok ();
</code></pre>
    <p>前台代码</p>
    <pre><code class="lang-language">(data =&gt; {
  console.log(data.flag)
  console.log(data.index)

})
</code></pre>
    <p>如何根据返回值的不同，前端做不同的处理？</p>
    <pre><code class="lang-language">ResponseUtil.newInstance ().add("flag", true).ok ()
</code></pre>
    <p>前端处理</p>
    <pre><code class="lang-language"> this.testFormLeaveService.save(this.inputForm).then((data) =&gt; {
     if(data.flag) {

     }
 }).catch(() =&gt; {
     this.loading = false
 })
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom59"
}
</script>

<style scoped>

</style>
