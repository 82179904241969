<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><p>1 新建会签流程<br>
    <img src="../../../assets/word/134.png" alt=""></p>
    <p>会签流程的其它设定内容，请参照 <a href="#" title="动态流程表单" target="_blank">动态流程表单</a>章节,本篇章只介绍会签任务节点。</p>
    <p>会签任务节点详细说明：</p>
    <ul>
      <li>会签节点的待办人员在【审核者】指定，执行到该任务节点时，会对全部人员进行抽取，确定会签人员。</li>
      <li>会签的完成条件，是指超过该比例的人员同意，任务节点就通过。</li>
      <li>任一会签人员驳回该流程，流程就驳回了。</li>
    </ul>
    <p>执行效果<br>
      <img src="../../../assets/word/135.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom109"
}
</script>

<style scoped>

</style>
