<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><div class="hljs-left">
    <h1><a ></a>项目介绍</h1>
    <p> vue3是基于spring boot2 + Vue3架构开发的经典的单体/多租户平台。</p>
  </div>
    <h3><a ></a>功能概述：</h3>
    <ul>
      <li>模板配置：可以在线复制编辑代码生成器模板，定义任何你想要的代码风格。</li>
      <li>工作流：可视化工作流，可以关联外置或者动态表单。</li>
      <li>菜单权限：权限精确到按钮级别。</li>
      <li>数据权限：支持行级权限，允许不同的人通过角色设置，看见不同的数据。</li>
      <li>报表：集成ureport，支持在线设计报表。</li>
      <li>多数据源：代码生成器支持连接不同的数据库，生成的模块可以连接指定的数据库，每个模块或者插件可以指定连接不同的数据库。</li>
      <li>多数据库支持：一套代码支持mysql、oracle、SqlServer, postgresql。</li>
      <li>PC/移动代码生成</li>
      <li>大屏/图表/报表/首页设计器</li>
      <li>根据租户机构的分级授权/数据权限</li>
      <li>OSS文件存储</li>
      <li>多租户</li>
      <li>多语言</li>
      <li>可视化工作流</li>
      <li>动态表单</li>
    </ul>
    <h3><a ></a>技术选型:</h3>
    <ul>
      <li>Springboot2</li>
      <li>mybatis plus</li>
      <li>spring security</li>
      <li>jwt token</li>
      <li>flowable</li>
      <li>hutool</li>
      <li>easy excel</li>
      <li>mapstruct</li>
      <li>dto/vo/entity</li>
      <li>vue3</li>
      <li>element plus</li>
      <li>es6</li>
      <li>vite</li>
    </ul>
    <h3><a ></a>分层设计：</h3>
    <ul>
      <li>使用分层设计，分为mapper，service，Controller，view层，层次清楚，低耦合，高内聚。</li>
      <li>安全考虑：严格遵循了web安全的规范，前后台双重验证，参数编码传输，密码md5加密存储，SpringSecurity权限验证，从根本上避免了SQL注入，XSS攻击，CSRF攻击等常见的web攻击手段。</li>
    </ul>
    <h3><a ></a>内置模块：</h3>
    <ul>
      <li>代码生成器</li>
      <li>报表设计器</li>
      <li>表单设计器</li>
      <li>工作流</li>
      <li>通知管理</li>
      <li>菜单管理</li>
      <li>用户管理</li>
      <li>机构管理</li>
      <li>角色管理</li>
      <li>区域管理</li>
      <li>字典管理</li>
      <li>日志查询</li>
      <li>连接池监控</li>
      <li>性能监控</li>
      <li>短信模块</li>
      <li>邮箱模块</li>
      <li>站内信系统</li>
      <li>app接口</li>
      <li>二维码生成等基础模块。</li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom1"
}
</script>

<style scoped>

</style>
