<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>通过数据集配置图表</h2>
    <h3><a id="_1"></a>配置数据集</h3>
    <p><img src="../../../assets/word/180.png" alt=""></p>
    <h3><a id="_3"></a>查看数据集结果</h3>
    <p><img src="../../../assets/word/153.png" alt=""></p>
    <h3><a id="_5"></a>配置图表</h3>
    <p>选择刚刚配置的数据集<br>
      <img src="../../../assets/word/161.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom129"
}
</script>

<style scoped>

</style>
