<template>
  <div class="product">
    <div class="ttop">
      <div class="wraps">
        <img src="../../../assets/logored.png" @click="home"/>
        <span @click="home">返回首页</span>
      </div>
    </div>
<!--    协议-->
    <div class="wrap" style="margin-top: 200px">
      <h3 >{{ privacy.title }}</h3>
      <h4>{{ privacy.customTime }}</h4>
      <div class="wrapbox">
        <div class="left" v-html="privacy.detail">
        </div>
        <div class="right">
            <div class="title">
              法律声明
            </div>
            <div class="list">
              <span @click="footerclick" style="font-size: 16px">隐私保护指引</span>
              <span style="font-size: 16px">软件许可及服务协议</span>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newService from "@/api/newService";

export default {
  name: "agreementOne",
    created() {
      this.getPrivacyList()
    },
    data(){
    return {
        privacy:{}
    }
  },
  methods:{
      getPrivacyList(){
          newService.getPrivacyList("SOFTWARE_LICENSE").then((res)=>{
              if (res==="暂无数据"){
                  this.$message.warning(res)
                  return;
              }
              this.privacy=res
          })
      },
    home(){
      this.$store.state.components='home'
      this.$store.state.isbackground=false
      this.$store.state.iscolor=false
      this.$store.state.islogored=false
      document.body.scrollTop=document.documentElement.scrollTop=0
    },
    agreementzong(){
      this.$store.state.isbackground=false
      this.$store.state.iscolor=true
      this.$store.state.islogored=true
      document.body.scrollTop=document.documentElement.scrollTop=0
    },
    footerclick(){
      this.$store.state.components='xieyi2'
      this.agreementzong()
      // this.$emit('footerclick',val)
    }
    // index(){
    //   this.$store.state.components='home'
    // },
    // next(){
    //   this.$store.state.components='product'
    // }
  }
}
</script>

<style lang="less" scoped>
.ttop{
  height: 6.4rem;
  line-height: 6.4rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  min-width: 120rem;
  color: #000;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
  background: #fff;
  .wraps{
    width: 120rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin: auto;
    img{
      cursor: pointer;
      height: 2.7rem;
      margin:2rem 3rem 2rem 0rem;
    }
    span{
      font-size: 1.6rem;
      cursor: pointer;
    }
    span:hover{
      color: #f00;
      text-decoration: underline;
    }
  }
}
.product{
  .wrap{
    width: 120rem;
    display: block;
    overflow: hidden;
    margin:12rem auto 15rem;
    min-height: 50rem;
    h3{
      font-size: 5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 6rem;
      margin-bottom: 2rem;
    }
    h4{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-bottom: 6rem;
      color: #999;
    }
    .wrapbox{
      display: flex;
      position: relative;
      justify-content: space-between;
      .left{
        width: 90rem;
        height: 75rem;
        float: left;
        overflow-y: auto;
        font-size: 1.4rem;
        color: #444955;
        padding: 1rem 1rem 4rem 1rem;
        line-height: 4rem;
        h4{
          font-size: 2.4rem;
          margin-bottom: 1rem;
          color: #333;
        }
        &::-webkit-scrollbar {
          width: 0px;
          height: 0;
        }
        &:hover::-webkit-scrollbar {
          width: 4px;
          height: 0;
        }
        //&::-webkit-scrollbar-thumb {
        //  border-radius: 10px;
        //  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //  opacity: 0.2;
        //  background:#ccc;
        //}
        //&::-webkit-scrollbar-track {
        //  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //  border-radius: 0;
        //  background: #eee;
        //}
      }
      .right{
        width: 22.8rem;
        height: auto;

        position: sticky;
        right: 0;
        top: 0;
        .title{
          height: 8rem;
          background: url("../../../assets/xieyibg.png") no-repeat;
          background-size: 100% 100%;
          font-size: 2.4rem;
          color: #fff;
          font-weight: bold;
          display: flex;
          justify-items: center;
          align-items: center;
          padding-left: 3.2rem;
        }
        .list{
          padding: 3.2rem 0 3.2rem 3.2rem;
          background: #F8F8F8;
          display: flex;
          flex-direction: column;
          font-size: 1.6rem;
          color:#020B1A ;
          line-height: 4rem;
          span{
            cursor: pointer;
          }
          span:hover{
            color: #f00;
            text-decoration: underline;
          }
        }
      }
    }

  }
}
</style>
