<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>后端开发</h2>
    <ul>
      <li><a href="#">开发规范</a></li>
      <li><a href="#">微服务相关</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom48"
}
</script>

<style scoped>

</style>
