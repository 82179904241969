<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>单表</h2>
    <h3><a id="1_1"></a>1.数据库表设置</h3>
    <p><img src="../../../assets/word/99.png" alt=""><br>
      <strong>注意：类名一定要大写。</strong></p>
    <h3><a id="2JAVA_5"></a>2.JAVA实体设置</h3>
    <p><img src="../../../assets/word/100.png" alt=""><br>
      如果JAVA类型是对象类型（例如：User，Office，Area，以及自定义java对象），请选择关联属性，id是必须属性，除了id之外要至少再选择一个属性值。关联属性，即生成mapper文件时，会生成关联查询sql。如下图：<br>
      <img src="../../../assets/word/83.png" alt=""></p>
    <h3><a id="3_11"></a>3.表单页面</h3>
    <p><img src="../../../assets/word/84.png" alt=""><br>
      表单显示：勾选在生成的form页面中会生成该控件。<br>
      控件类型：选择对应的控件。（如果选择了，下拉框，单选按钮，请选择对应字典。例如，如果你想生成一个下拉框，里面包含是和否两个选择，控件类型选择“下拉框”，字典类型选择“yes_no"。）</p>
    <h3><a id="4_15"></a>4.表单校验</h3>
    <p><img src="../../../assets/word/86.png" alt=""><br>
      可空：如果是必填，请不要勾选。<br>
      字符串：可以设置最小长度和最大长度。<br>
      数值型：可以设置最小值和最大值。<br>
      <br></p>
    <h3><a id="5_21"></a>5.列表页面</h3>
    <p><img src="../../../assets/word/85.png" alt=""><br>
      列表字段：勾选在生成的列表代码中包含此列。<br>
      查询：勾选之后会生成搜索框。<br>
      查询匹配方式： like是模糊查询。日期查询请选择between，生成一个起始时间结束时间的检索框。</p>
    <h3><a id="6_27"></a>6.同步数据库</h3>
    <p><img src="../../../assets/word/87.png" alt=""></p>
    <blockquote>
      <p>注意：普通同步是动态修改表结构，会保留数据。<br>
        强制同步，是删除原来的表然后重新建表，会清空数据。<br>
        请谨慎操作。</p>
    </blockquote>
    <h3><a id="7_34"></a>7.生成代码</h3>
    <p><img src="../../../assets/word/88.png" alt=""></p>
    <ol>
      <li>后端生成路径: 请选择java代码生成的路径，到后端代码模块的根路径（<a href="http://wiki..org/docs/show&amp;name=151" title="如何构建自定义模块？" target="_blank">如何构建自定义模块？</a>）。</li>
      <li>前端生成路径：请选择前端代码的生成路径，到前端代码的</li>
      <li>选择代码模板：单表。</li>
      <li>生成包路径：生成哪个包下，默认com..modules（<a href="http://wiki..org/docs/show&amp;name=154" title="如何使用自定义包名？" target="_blank">如何使用自定义包名？</a>）。</li>
      <li>生成模块名：生成包下的模块名称，模块名称下进行分层。</li>
      <li>生成子模块名：分层下的文件夹，可为空。</li>
      <li>生成功描述：生成到类注释里。</li>
      <li>生成功能名：生成功能提示，表单，对话框，列表上、提示信息等。</li>
      <li>生成功能作者：开发者姓名</li>
    </ol>
    <p>重复填写这些公共信息（例如 路径，包名，开发者姓名）很繁琐，你可以设置默认值（<a href="http://wiki..org/docs/show&amp;name=181" title="如何设置生成代码的默认参数" target="_blank">如何设置生成代码的默认参数</a>）<br>
      <img src="../../../assets/word/89.png" alt=""></p>
    <h3><a id="_50"></a></h3>
    <p>重启java开发工具，也可以等待热加载完成，前台无需重启。<br>
      <br></p>
    <h3><a id="8_53"></a>8.创建菜单</h3>
    <p><img src="../../../assets/word/90.png" alt=""></p>
    <h3><a id="9_58"></a>9.进行角色授权</h3>
    <p><img src="../../../assets/word/91.png" alt=""></p>
    <h3><a id="10_63"></a>10.生成代码的运行效果</h3>
    <p><img src="../../../assets/word/92.png" alt=""></p>
    <p>提示：如果访问报权限错误，请退出用户重新登录（不是重启), 刷新用户权限缓存即可了。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom82"
}
</script>

<style scoped>

</style>
