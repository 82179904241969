import {request} from '@/utils/request';

export default {
    getTopCompany:function () {
        return request({
            url: "/word/platform/zhWordCompanyInformation/user/getTopCompany",
            method: "get"
        });
    },
    getProductsList:function(params){
        return request({
            url: "/word/platform/zhWordProducts/user/getProductsList",
            method: "get",
            params:params
        });
    },
    getOneProduct:function (id){
        return request({
            url: "/word/platform/zhWordProducts/user/getOneProduct",
            method: "get",
            params:{ id:id }
        });
    },
    getPlanList:function(params){
        return request({
            url: "/word/platform/zhWordPlan/user/getPlanList",
            method: "get",
            params:params
        });
    },
    getOnePlan:function (id){
        return request({
            url: "/word/platform/zhWordPlan/user/getPlanOne",
            method: "get",
            params:{ id:id }
        });
    },
    getOneEcology:function (){
        return request({
            url: "/word/platform/zhWordEcology/user/getOneEcology",
            method: "get"
        });
    },
    getAboutOne:function (){
        return request({
            url: "/word/platform/zhWordAbout/user/getAboutOne",
            method: "get"
        });
    },
    getNewsAndEvents:function (params){
        return request({
            url: "/word/platform/zhWordNewsEvents/user/getEventAndNews",
            method: "get",
            params: params
        });
    },
    getCloudList:function (params){
        return request({
            url: "/word/platform/zhWordCloudDepot/user/getCloudList",
            method: "get",
            params: params
        });
    },
    getCloudOne:function (id){
        return request({
            url: "/word/platform/zhWordCloudDepot/user/getCloudOne",
            method: "get",
            params:{ id:id }
        });
    },
    addviewCount:function (id){
        return request({
            url: "/word/platform/zhWordCloudDepot/user/addviewCount",
            method: "post",
            params:{ id:id }
        });
    },
    getLabel:function (){
        return request({
            url: "/word/platform/zhWordCloudDepot/user/getLabel",
            method: "get",
        });
    },
    getClassify:function (){
        return request({
            url: "/word/platform/zhWordCloudDepot/user/getClassify",
            method: "get",
        });
    },
    getPrivacyList:function (type){
        return request({
            url: "/word/platform/zhWordPrivacy/user/getPrivacyList",
            method: "get",
            params:{ type:type }
        });
    },
    getClLabelList:function (zhClToken){
        return request({
            url: "/word/zhcl/zhWordClLabel/user/userGetClLabelList",
            method: "get",
            params:{zhClToken: zhClToken}
        });
    },
    getCLHelpOne:function(params) {
        return request({
            url: "/word/zhcl/zhWordClHelp/user/getCLHelpOne",
            method: "get",
            params: params
        });
    },
    getClHelpList:function (params){
        return request({
            url: "/word/zhcl/zhWordClHelp/user/getClHelpList",
            method: "get",
            params: params
        });
    },
    getCLHelpOneByList:function(params) {
        return request({
            url: "/word/zhcl/zhWordClHelp/user/getCLHelpOneByList",
            method: "get",
            params: params
        });
    },


    getZsctLabelList:function (zhZsctToken){
        return request({
            url: "/word/zsct/zhWordZsctLabel/user/userGetZsctLabelList",
            method: "get",
            params:{zhZsctToken: zhZsctToken}
        });
    },
    getZsctHelpOne:function(params) {
        return request({
            url: "/word/zsct/zhWordZsctHelp/user/getZsctHelpOne",
            method: "get",
            params: params
        });
    },
    getZsctHelpList:function (params){
        return request({
            url: "/word/zsct/zhWordZsctHelp/user/getZsctHelpList",
            method: "get",
            params: params
        });
    },
    getZsctHelpOneByList:function(params) {
        return request({
            url: "/word/zsct/zhWordZsctHelp/user/getZsctHelpOneByList",
            method: "get",
            params: params
        });
    },



}