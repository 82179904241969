<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>权限相关</h1>
    <ul>
      <li><a href="#">免登陆配置</a></li>
      <li><a href="#">菜单/按钮权限</a></li>
      <li><a href="#">数据权限</a></li>
      <li><a href="#">表格列显示/隐藏</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom60"
}
</script>

<style scoped>

</style>
