<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>报表设计</h1>
    <p>报表设计采用ureport2，请参照<a href="https://www.w3cschool.cn/ureport/ureport-jaod2h8k.html" title="官方文档" target="_blank">官方文档</a></p>
    <h3><a id="_3"></a>常见错误</h3>
    <p>部署到线上，访问报表设计时抛出：</p>
    <pre><code class="lang-">您尚未登录，请登录后操作!
</code></pre>
    <h4><a id="_10"></a>错误原因</h4>
    <p>报表设计是后台页面，不是vue页面。</p>
    <p>后台页面必须在相同的域名下 才能和 vue页面共享cookie， cookie中保存着token信息。<br>
      后端获取token的逻辑是优先从请求参数中获取，其次从header中获取（api接口请求默认方案），最后是从cookie中获取，只要有一种方式可以获取token就允许用户登录。<br>
      之所以出现请你登录这个界面，是因为你的vue页面的访问域名和你报表的访问路径不在同一个域名（端口也必须相同，否则浏览器认为域名不同）下，导致从cookie中无法获取token。</p>
    <h3><a id="_18"></a>解决办法</h3>
    <p>所以 ，保证报表设计页面和vue页面同源即可。<br>
      线上部署在Nginx中增加配置，访问报表设计采用相对路径而不是访问后台java绝对路径， 通过Nginx转发实现报表和前端vue界面的同源。</p>
    <pre><code>    location /ureport/ {
        proxy_pass              http://127.0.0.1:8088/ureport/;
        proxy_set_header        Host 127.0.0.1;
        proxy_set_header        X-Real-IP $remote_addr;
        proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
    }
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom111"
}
</script>

<style scoped>

</style>
