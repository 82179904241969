<template>
    <div class="ccdddd">
        <header>
<!--            <img src="../../assets/logored.png" @click="homeclick"/>-->
            <span style="font-size: 1.5rem;color: #409eff;margin-left: 1rem;overflow-x: hidden;overflow-y: hidden">数智产投操作手册</span>
            <div style="flex: 1; display: flex; margin-left: 35rem">
                <el-autocomplete
                        style="border-color: #000; border-radius: 3rem; width: 45rem"
                        class="inline-input"
                        size="small"
                        v-model="queryStr"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        :trigger-on-focus="true"
                        @select="handleSelect"
                        @keyup.enter.native="getZsctList"
                ></el-autocomplete>
                <div class="el-icon-search" style="font-size: 2.5rem;width: 5rem;height: 5rem;margin-top: 2rem;margin-left: 1rem;cursor: pointer" @click="getZsctList"></div>
            </div>
        </header>
        <div class="wordcontnt" style="background-color: #f5f7f9">
            <splitpanes class="default-theme" style="background-color: #f5f7f9">
                <pane size="20" style="overflow-y: auto;height: 100%;background-color: #f5f7f9">
                    <el-container  class="jp-container" style="background-color: #f5f7f9">
                        <el-tree
                                style="margin-left: 1rem;background-color: #f5f7f9"
                                class="filter-tree"
                                :data="leftList"
                                :props="defaultProps"
                                node-key="name"
                                @node-click="handleNodeClick"
                                ref="menuTree">
                        </el-tree>
                    </el-container>
                </pane>
                <pane size="85" style="height: 100%; overflow-y: scroll;background-color: white">
                    <!--        <div class="wordcontnt" v-loading="loading">-->
                    <!--            <cl-word-left v-if="pageSize===100" style="width: 300px; height: 100%; display: block; overflow-y: auto; background: #f5f7f9" :leftdata="leftdata" @handleNodeClick="textContentclick(id)"></cl-word-left>-->
                    <el-container  class="jp-container " style="background-color: white">
                        <zsct-word-right  v-if="flag==='one' &&loadingOne" style="width: calc(100% - 9rem);height: 100%;display: block; overflow-y: auto;background-color: white" :clDetail="clOne" :Clmessage="clOnemessage">
                        </zsct-word-right>
                        <zsct-word-right-list v-if="flag==='list' &&loadingList" style="width: calc(100% + 5rem);height: 100%;display: block; overflow-y: auto;background-color: white" :queryString="queryStr">
                        </zsct-word-right-list>
                    </el-container>
                </pane>
            </splitpanes>
        </div>
    </div>
</template>

<script>

import newService from "@/api/newService";
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
// import ClWordLeft from "@/components/clword/ClWordLeft.vue";
import ZsctWordRight from "@/components/zsctword/ZsctWordRight.vue";
import ZsctWordRightList from "@/components/zsctword/ZsctWordRightList.vue";
export default {
    name: "wordIndex",
    components:{
        // ClWordLeft,
        // ClWordLeft,
        Splitpanes,
        Pane,
        ZsctWordRight,
        ZsctWordRightList
    },
    data(){
        return {
            clOnemessage:"",
            loadingList:false,
            flag:"one",
            loadingOne:false,
            queryStr:'',
            restaurants:[],
            rightdata:[],
            leftList:[],
            clOne:{},
            cehtml:'',
            checkedkeys:[],
            filterText: '',
            data: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                id: 'id'
            }
        }
    },
    created() {
        this.userGetZsctLabelList()
    },
    mounted() {
        this.isGetZsctHelpOne()
    },
    methods:{

        isGetZsctHelpOne(){
            let query = location.search;
            let searchParams = new URLSearchParams(query);

            if (!searchParams.has("id")){
                if (searchParams.has("queryStr")){
                    var queryString = searchParams.get("queryStr");
                    this.queryStr=queryString
                    this.getZsctList()
                    return;
                }
                //到这表示无id且无模糊查寻
                this.getZsctHelpOne("1703686719859191810")
                return;
            }
            //到这表示有id但无模糊查
            var id = searchParams.get("id");
            this.getZsctHelpOne(id)
        },
        getZsctHelpOne(id){
            this.loadingList=false
            this.loadingOne=false
            let query = location.search;
            let searchParams = new URLSearchParams(query);
            let zhZsctToken = searchParams.get("zhZsctToken")
            const obj={
                id: id,
                zhZsctToken: zhZsctToken
            }
            newService.getZsctHelpOne(obj).then((res)=>{
                if(res==="无权限，请重新由产投系统访问"||res===null){
                    this.$message.error(res)
                    return;
                }
                if (res==="暂无数据请联系等待管理员添加"){
                    this.clOnemessage=res
                    this.flag="one"
                    setTimeout(()=>{
                        this.loadingOne=true
                    },1500)
                    return;
                }
                this.clOnemessage="hasdata"
                this.clOne=res
            })
            this.flag="one"
            setTimeout(()=>{
                this.loadingOne=true
            },1500)

        },
        userGetZsctLabelList(){

            let query = location.search;
            let searchParams = new URLSearchParams(query);
            const zhZsctToken = searchParams.get("zhZsctToken");

            newService.getZsctLabelList(zhZsctToken).then((res)=>{
                if(res==="无权限，请重新由产投系统访问"||res===null){
                    this.$message.error(res)
                    return;
                }
                var reg = new RegExp('\\,"children":\\[]', 'g')
                // 反斜杠
                //在对树结构进行替换
                this.leftList = JSON.parse(JSON.stringify(res).replace(reg, ''))
            })
        },
        getZsctList(){
            this.loadingList=false
            this.loadingOne=false
            this.flag='list'
            setTimeout(()=>{
                this.loadingList=true
            },1500)
        },
        textContentclick(){
            this.loadingList=false
            this.loadingOne=false
            let query = location.search;
            let searchParams = new URLSearchParams(query);


            var obj= {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryStr: this.queryStr,
                zhZsctToken: searchParams.get("zhZsctToken")

            }
            newService.getZsctHelpList(obj).then((res)=>{
                this.rightdata=res.rows
            })
            this.flag='list'
            setTimeout(()=>{
                this.loadingList=true
            },1500)
        },
        // homeclick(){
        //     this.$emit('homeclick')
        // },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleNodeClick(e) {
            const a = this.$refs.menuTree.getNode(e.name);
            if (a.childNodes.length === 0) {
                this.getZsctHelpOne(e.id)
                this.loadingOne=false

            }
        },
    }
}
</script>

<style scoped lang="scss">

.ccdddd{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  header{
    height: 6.4rem;
    line-height: 6.4rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: start;
    color: #000;
    box-shadow: 0px 2px 6px 0px white;
    img{
      height: 2.7rem;
      margin: 2rem 3rem 2rem 4rem;
      cursor: pointer;
    }

  }
  .wordcontnt{
    margin-top: 6.4rem;
    height: calc(100vh - 6.4rem);
    display: flex;
    overflow: hidden;
  }
}
.jp-container {
  margin: -10px;
}
.el-tree{background:#f5f7f9 !important; padding: 1rem }
.el-tree-node__content{height: 6rem !important;font-size: 2.4rem}
.el-tree-node:focus>.el-tree-node__content{color:#409eff !important;}
.is-current{color:#007aff}
.is-checked{color:#007aff}
</style>
