<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>拷贝</h3>
    <h4><a id="vcopy_2"></a>拷贝使用v-copy指令来实现。</h4>
    <pre><code class="lang-">&lt;el-card shadow="never" header="v-copy 一键复制" style="margin-top: 15px;"&gt;
	&lt;el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="copyText"&gt;&lt;/el-input&gt;
	&lt;el-button v-copy="copyText" type="primary" style="margin-top: 15px;"&gt;复制&lt;/el-button&gt;
	&lt;el-alert title="点击复制按钮会将文本框绑定的值复制到剪切板, 试着粘贴到其他地方看看效果" style="margin-top: 20px;"&gt;&lt;/el-alert&gt;
&lt;/el-card&gt;
</code></pre>
    <h4><a id="_11"></a>效果</h4>
    <p><img src="../../../assets/word/160.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom40"
}
</script>

<style scoped>

</style>
