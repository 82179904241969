<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>菜单国际化</h3>
    <p>菜单的国际化是通过给菜单设置多语言编码，然后在多语言里面添加对应的词条，</p>
    <h4><a id="__3"></a>步骤一 设置多语言编码</h4>
    <p><img src="../../../assets/word/154.png" alt=""></p>
    <h4><a id="___6"></a>步骤二  在多语言管理页面添加对应的词条内容即可</h4>
    <p><img src="../../../assets/word/175.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom43"
}
</script>

<style scoped>

</style>
