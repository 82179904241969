<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a id="Mapper_0"></a>创建Mapper</h3>
    <p>mapper是用来执行数据库操作的。</p>
    <p>中的mapper方法分为两种</p>
    <h4><a id="1_mapper_6"></a>1 原生mapper方法</h4>
    <p>中的Mapper类继承自mybatis plus的 BaseMapper， 默认提供了单表的增删查改方法。</p>
    <h4><a id="2_mapper_11"></a>2 自定义mapper方法</h4>
    <p>复杂的多表关联查询，这个时候就需要自定义方法了，同时在xml文件中配置对应的sql语句。</p>
    <p>Mapper.java 示例</p>
    <pre><code class="lang-language">
    /**
     * 根据id获取请假
     * @param id
     * @return
     */
    TestFormLeaveDTO findById(String id);

    /**
     * 获取请假列表
     *
     * @param queryWrapper
     * @return
     */
    IPage &lt;TestFormLeaveDTO&gt; findList(Page &lt;TestFormLeaveDTO&gt; page, @Param(Constants.WRAPPER) QueryWrapper queryWrapper);

}

</code></pre>
    <p>Mapper.xml</p>
    <pre><code class="lang-language">&lt;?xml version="1.0" encoding="UTF-8" ?&gt;
&lt;!DOCTYPE mapper PUBLIC "-//mybatis.org//DTD Mapper 3.0//EN" "http://mybatis.org/dtd/mybatis-3-mapper.dtd"&gt;
&lt;mapper namespace="com..test.one.mapper.TestFormLeaveMapper"&gt;

	&lt;sql id="testFormLeaveColumns"&gt;
		a.id AS "id",
		a.office_id AS "office.id",
		a.user_id AS "user.id",
		a.area AS "area",
		a.begin_date AS "beginDate",
		a.end_date AS "endDate",
		a.tenant_id AS "tenantId",
		a.create_by_id AS "createBy.id",
		a.create_time AS "createTime",
		a.update_by_id AS "updateBy.id",
		a.update_time AS "updateTime",
		a.del_flag AS "delFlag",

		office.name AS "office.name",

		user.name AS "user.name"
	&lt;/sql&gt;

	&lt;sql id="testFormLeaveJoins"&gt;

		LEFT JOIN sys_office office ON office.id = a.office_id
		LEFT JOIN sys_user user ON user.id = a.user_id
		LEFT JOIN sys_user createBy ON createBy.id = a.create_by_id
		LEFT JOIN sys_user updateBy ON updateBy.id = a.update_by_id
	&lt;/sql&gt;



	&lt;select id="findById" resultType="com..test.one.service.dto.TestFormLeaveDTO"&gt;
		SELECT
			&lt;include refid="testFormLeaveColumns"/&gt;
		FROM test_form_leave a
		&lt;include refid="testFormLeaveJoins"/&gt;
		WHERE a.id = #{id} and a.del_flag = 0
	&lt;/select&gt;

	&lt;select id="findList" resultType="com..test.one.service.dto.TestFormLeaveDTO"&gt;
		SELECT
			&lt;include refid="testFormLeaveColumns"/&gt;
		FROM test_form_leave a
		&lt;include refid="testFormLeaveJoins"/&gt;
<!--	    ${ew.customSqlSegment}-->
	&lt;/select&gt;

&lt;/mapper&gt;

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom56"
}
</script>

<style scoped>

</style>
