<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>列表页面</h3>
    <p><img src="../../../assets/word/103.png" alt=""></p>
    <ul>
      <li>列表字段：是否是列表字段，如果勾选则生成的表格里会包含此列。</li>
      <li>查询：是否是查询字段，如果勾选则生成的列表页面会包含此查询条件。</li>
      <li>查询匹配方式：查询字段的查询方式，也就是where后的条件表达式，如：字段1=字段2  AND  字段3&gt;字段4  AND  字段5 !=字段6。（常用的模糊匹配请选择like）</li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom80"
}
</script>

<style scoped>

</style>
