<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="1__0"></a>1 新建流程表单</h1>
    <h2><a id="11_1"></a>1.1新增流程表单</h2>
    <p><img src="../../../assets/word/76.png" alt=""><br>
      表类型：工作流表单<br>
      必须包含字段proc_ins_id.<br>
      注意：主键必须是uuid，不能使用自增。</p>
    <h2><a id="12_6"></a>1.2设置页面</h2>
    <p><img src="../../../assets/word/79.png" alt=""></p>
    <h2><a id="13_9"></a>1.3生成代码</h2>
    <p><img src="../../../assets/word/80.png" alt=""></p>
    <h2><a id="14_11"></a>1.4获取表单地址</h2>
    <p><img src="../../../assets/word/77.png" alt=""><br>
      如下：</p>
    <ul>
      <li>表单地址:/test/activiti/TestActivitiLeaveForm</li>
    </ul>
    <h1><a id="2_16"></a>2新建流程模型</h1>
    <p>新建一个请假模型，然后点击在线设计，进入设计流程。<br>
      <img src="../../../assets/word/81.png" alt=""></p>
    <p>设计如下流程图<br>
      <img src="../../../assets/word/132.png" alt=""></p>
    <h2><a id="21_22"></a>2.1设置流程属性</h2>
    <p><img src="../../../assets/word/133.png" alt=""></p>
    <ul>
      <li>流程名称：流程定义KEY，对应procDefKey，用来标识一个流程</li>
      <li>名称：流程定义名称</li>
      <li>描述：流程定义描述</li>
    </ul>
    <h2><a id="22_28"></a>2.2流程发起人流程变量</h2>
    <p>在开始节点的属性中设置“发起人”变量名：如：applyUserId<br>
      <img src="../../../assets/word/78.png" alt=""></p>
    <h2><a id="23_31"></a>2.3给开始节点挂接表单地址</h2>
    <ul>
      <li>全局表单：新建流程时或活动元素上未设置表单标识时调用的表单，位于开始事件属性中“表自定义表单”字段，指定表单访问地址。</li>
      <li>活动表单：当前步骤使用的表单，使用活动节点属性“自定义表单”字段。</li>
    </ul>
    <p><img src="../../../assets/word/82.png" alt=""></p>
    <p>将1.4步骤中，生成的表单:/test/activiti/TestActivitiLeaveForm地址，输入红框中。</p>
    <h2><a id="24_40"></a>2.4用户任务设置</h2>
    <p>选择部门领导审批，在活动节点属性中设置”表单地址",<br>
      输入1.4步骤中生成的表单地址:/test/activiti/TestActivitiLeaveForm（可以缺省设置，默认使用开始节点设置的表单)，并勾选只读。<br>
      如下图所示：</p>
    <p><img src="../../../assets/word/128.png" alt=""></p>
    <p>在活动节点属性中找到“审核人”属性<br>
      <img src="../../../assets/word/139.png" alt=""><br>
      <img src="../../../assets/word/141.png" alt=""><br>
      可以按公司，部门，用户，角色，岗位等指定办理人，如果有多个办理人，每个人都能接收到待办任务，任何一人都可以处理待办任务。</p>
    <h2><a id="25__53"></a>2.5 按钮配置</h2>
    <p><img src="../../../assets/word/129.png" alt=""></p>
    <p>选择你需要在该任务节点添加的系统按钮。<br>
      也可以添加自定义按钮，按钮编码，将作为流程变量提交。<br>
      例如：添加两个自定义按钮【同意1】编码为agree，【不同意2】编码为disagree，点【同意1】时，将提交两个流程变量agree为true，disagree为false。<br>
      点击【不同意2】，则提交agree为false和disagree为true的流程变量。<br>
      每个按钮对应一个编码，点击的按钮提交编码值为true的流程变量，其余按钮对应的编码提交为值是false的流程变量，流程分支根据提交的变量值决定执行哪个分支。<br>
      注意：系统按钮（_flow_开头）的编码将不会作为流程变量提交。</p>
    <h2><a id="26_62"></a>2.6设置流转条件</h2>
    <p>外置表单请使用流程表达式。<br>
      流程变量来自于表单中的变量，例如本例，表单变量如下：<br>
      inputForm: {<br>
      id: ‘’,<br>
      leaveType: ‘’,<br>
      startTime: ‘’,<br>
      endTime: ‘’,<br>
      reason: ‘’<br>
      }<br>
      写EL表达式，返回true或false,请直接使用inputForm中定义的变量，如下：${endTime - startTime&gt;5<em>24</em>3600},</p>
    <h2><a id="27_74"></a>2.7设置结束节点</h2>
    <p><img src="../../../assets/word/140.png" alt=""></p>
    <h2><a id="28_76"></a>2.8保存发布流程设计图</h2>
    <p>工具栏上点击“保存并发布”按钮发布流程。</p>
    <ul>
      <li>同一标识流程，部署多次，版本号自动加1</li>
      <li>正在运行的流程，再次部署新流程后，正在运行的流程还按照原来流程设置走，再次新建的流程使用新流程走。</li>
    </ul>
    <h1><a id="4_82"></a>4测试执行</h1>
    <p>流程应用/我的事物/发起流程，点击请假流程。</p>
    <p><img src="../../../assets/word/73.png" alt=""><br>
      <img src="../../../assets/word/74.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom105"
}
</script>

<style scoped>

</style>
