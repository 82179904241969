<template>
    <div>
        <el-tree
            class="filter-tree"
            :data="leftdata"
            :props="defaultProps"
            node-key="name"
            @node-click="handleNodeClick"
            ref="menuTree">
        </el-tree>
    </div>
</template>

<script>
export default {
    name: "ClWordLeft",
    props:{
        leftdata:{
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            checkedkeys:[],
            filterText: '',
            data: [],
            defaultProps: {
                children: 'children',
                label: 'name',
                id: 'id'
            }
        };
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    mounted() {

    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        handleNodeClick(e) {
            const a = this.$refs.menuTree.getNode(e.name);
            if (a.childNodes.length === 0) {
                localStorage.setItem("pageFlag","one")
            }
        },
    }
}
</script>

<style  lang="scss">
.el-tree{background: white !important; padding: 1rem }
.el-tree-node__content{height: 6rem !important;font-size: 2.4rem}
.el-tree-node:focus>.el-tree-node__content{color:#409eff !important;}
.is-current{color:#007aff}
.is-checked{color:#007aff}
</style>
