<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>多租户相关</h1>
    <ul>
      <li><a href="#">多租户配置</a></li>
      <li><a href="#">租户共享数据配置</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom"
}
</script>

<style scoped>

</style>
