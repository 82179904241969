<template>
<div class="wordhomw-wrap">

  <div  class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>富文本</h2>
    <h3><a id="1_1"></a>1.创建表单</h3>
    <p><img src="../../../assets/word/60.png" alt=""><br>
      注意：富文本框对应的物理类型为longblob（例子中的contents字段)，且列明不能包括下划线(_),例如t_contents这样的命名是不允许的，这是mybatis的bug，如果这样命名了，富文本框中的中文将显示为乱码。</p>
    <h3><a id="2_JAVA_5"></a>2 JAVA实体设置</h3>
    <p><img src="../../../assets/word/49.png" alt=""><br>
      富文本框对应的java类型请选择String。</p>
    <h3><a id="3__9"></a>3 表单页面</h3>
    <p><img src="../../../assets/word/68.png" alt=""><br>
      控件类型请选择 富文本编辑器，wangEditor或者quill， 支持两种富文本编辑框。</p>
    <h3><a id="4__13"></a>4 运行效果</h3>
    <p><img src="../../../assets/word/38.png" alt=""></p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom85"
}
</script>

<style scoped>

</style>
