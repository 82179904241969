<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h1><a id="_0"></a>代码生成</h1>
    <ul>
      <li><a href="#">智能表单</a></li>
      <li><a href="#">生成示例</a></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom73"
}
</script>

<style scoped>

</style>
