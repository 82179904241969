<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="oss_0"></a>使用阿里云oss</h3>
    <h4><a id="_oss_2"></a>步骤一 开通阿里云oss服务</h4>
    <p><a href="https://help.aliyun.com/product/31815.html" target="_blank">官方文档</a></p>
    <h4><a id="_minio_6"></a>步骤二 修改配置使用minio存储</h4>
    <p>编辑application-development.yml文件，将config.accessory.type类型设置为 minIO</p>
    <pre><code class="lang-language">#oss配置
config:
  accessory:
    #local, aliyun, minIO
    type: aliyun
    baseDir: file
    aliyun:
      endpoint: oss-cn-hangzhou.aliyuncs.com
      accessKeyId: xxxx
      accessKeySecret: xxxx
      bucketName:
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom122"
}
</script>

<style scoped>

</style>
