<template>
<div class="wordhomw-wrap">
<p>暂无</p>

</div>
</template>

<script>
export default {
  name: "wordCom62"
}
</script>

<style scoped>

</style>
