<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h1><a id="Vue_cas_0"></a>Vue 前端开启cas登录</h1>
    <p>打开.env.development文件修改如下配置</p>
    <pre><div class="hljs"><code class="lang-xml">#单点登录设置
VUE_APP_SSO_LOGIN  = 'true'
VUE_APP_CAS_SERVER = 'https://www.casserver.com:8443/cas'
VUE_APP_CLIENT_LOGIN = 'http://localhost:3000/#/casLogin'
</code></div></pre>
    <p>登录页面如下<br>
      <img src="/front/file/download?uploadPath=uploads/202012&amp;name=5fc74e2572dec_5fc74e25.png" alt=""><br>
      输入 admin/admin, 就跳转到home页面了。</p>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom116"
}
</script>

<style scoped>

</style>
