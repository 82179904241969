<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
*{margin: 0; padding: 0; font-size: 62.5%; font-style: normal}
li, ol, ul {
  font: inherit;
  list-style: none;
}
body{min-width: 120rem}
#app{min-width: 120rem; display: block; overflow: hidden}
.anchorBL a {
  display: none;
}

.anchorBL img {
  display: none;
}

.anchorBL span {
  display: none !important;
}
.swiper-pagination-bullet{
  /*background: #ffedef;*/
  background: #e5e5e5;
  opacity: 1;

}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{margin-top: 8rem}
.swiper-pagination-bullet-active{
  /*width: .8rem;*/
  /*height: .8rem;*/
  border-radius: .6rem;
  /*background: linear-gradient(90deg, rgba(255, 113, 114, 1), rgba(255, 237, 239, 1))*/
  background: rgba(255, 122, 123, 1);
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{}
.swiper-button-next:hover{
  background: url("assets/right1.png") no-repeat;background-size: contain;
}
.swiper-button-prev:hover{
  background: url("assets/left1.png") no-repeat;background-size: contain;
}
.swiper-button-next, .swiper-button-prev{top:50%;transform: translate(-50%,-30%); width:3.6rem; height: 3.6rem }
.swiper-button-next::after, .swiper-button-prev::after{content: ''}
.swiper-button-next{background: url("assets/right.png") no-repeat; background-size: contain; right:50%; margin-right: -700px }
.swiper-button-prev{background: url("assets/left.png") no-repeat; background-size: contain ;left:50%; margin-left: -670px }
div::-webkit-scrollbar {
  width: 4px;
  height: 0;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background:#ccc;
}
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #eee;
}
</style>
