<template>
<div class="wordhomw-wrap">

  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>通过静态数据源配置图表</h2>
    <h3><a id="_1"></a>设置数据类型</h3>
    <p>设置数据类型为静态数据<br>
      <img src="../../../assets/word/145.png" alt=""></p>
    <p>设置请求数据</p>
    <pre><code class="lang-language">[
    {
        "name": "苹果",
        "value": 1000879,
        "url": "http://www.baidu.com"
    },
    {
        "name": "三星",
        "value": 3400879,
        "url": "http://www.baidu.com"
    },
    {
        "name": "小米",
        "value": 2300879,
        "url": "http://www.baidu.com"
    },
    {
        "name": "oppo",
        "value": 5400879,
        "url": "http://www.baidu.com"
    },
    {
        "name": "大疆",
        "value": 3000,
        "url": "http://www.baidu.com"
    }
]
</code></pre>
  </div>
</div>
</template>

<script>
export default {
  name: "wordCom127"
}
</script>

<style scoped>

</style>
