import { render, staticRenderFns } from "./wordcom46.vue?vue&type=template&id=35398734&scoped=true&"
import script from "./wordcom46.vue?vue&type=script&lang=js&"
export * from "./wordcom46.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35398734",
  null
  
)

export default component.exports