<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h3><a></a>新增api</h3>
    <pre><code class="lang-">

**在 @/api 文件夹下创建本模块对应的 api 服务。**

例如： /src/api/test/one/testFormLeaveService.js

```language
import request from '@/utils/httpRequest'
import { TEST_PATH as prefix } from '@/api/AppPath'

export default {
  save: function (inputForm) {
    return request({
      url: prefix + '/test/one/testFormLeave/save',
      method: 'post',
      data: inputForm
    })
  },

  delete: function (ids) {
    return request({
      url: prefix + '/test/one/testFormLeave/delete',
      method: 'delete',
      params: {ids: ids}
    })
  },

  queryById: function (id) {
    return request({
      url: prefix + '/test/one/testFormLeave/queryById',
      method: 'get',
      params: {id: id}
    })
  },

  list: function (params) {
    return request({
      url: prefix + '/test/one/testFormLeave/list',
      method: 'get',
      params: params
    })
  }
}

</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom19"
}
</script>

<style scoped>

</style>
