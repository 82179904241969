<template>
<div class="wordhomw-wrap">
  <div  class="v-note-wrapper markdown-body shadow"><h2><a ></a>路由</h2>
    <p>路由是系统中最重要的存在之一，控制者系统导航、页签标题、页面跳转等，是整个系统的中枢大脑。<br>
      路由分为两种，静态路由 和 动态路由。</p>
    <p>constantRoutes： 代表那些不需要动态判断权限的路由，如登录页、404、等通用页面。</p>
    <p>asyncRoutes： 代表那些需求动态判断权限并通过 addRoutes 动态添加的页面。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom12"
}
</script>

<style scoped>

</style>
