<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>流程中文乱码</h3>
    <p>catalina.sh文件中修改下 ,添加红色部分即可<br>
      windows下修改 catalina.bat<br>
      设置JVM参数<br>
      JAVA_OPTS="$JAVA_OPTS -Dorg.apache.catalina.security.SecurityListener.UMASK=<code>umask</code> -Dfile.encoding=UTF-8"</p>
    <h3><a id="_7"></a>流程设计器修改标题名</h3>
    <p>流程设计器默认标题名 “流程设计器”， 修改成其他名字请使用title属性。</p>
    <pre><code class="lang-"> &lt;design-form title="xxxx" ref="designForm" @refreshList="refreshList"&gt;&lt;/design-form&gt;
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom110"
}
</script>

<style scoped>

</style>
