<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><p> 后端对接cas<br>
    打开application-development.yml文件，修改如下配置</p>
    <pre><div class="hljs"><code class="lang-xml">#cas服务端的地址
cas:
  server-url-prefix: https://www.casserver.com:8443/cas
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom115"
}
</script>

<style scoped>

</style>
