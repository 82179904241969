<template>
  <div class="product">
    <!--  编外人员管理系统-->
    <div class="banner">
      <img :src=products.topPhotos  width="100%" height="300px">
      <div class="wrap">
        <h1 ref="title1" style="color: black">{{ products.chName }}</h1>
        <h2 ref="title2" style="color: black">{{ products.enName }}</h2>
      </div>
    </div>
    <div class="linkbanner">
      <el-link type="danger" @click="index">首页</el-link>
      <i class="el-icon-arrow-right"></i>
      <el-link type="danger"  @click="next">产品</el-link>
      <i class="el-icon-arrow-right"></i>
      <el-link type="info">{{ products.chName}}</el-link>
    </div>
    <div class="wrap" v-html="products.detail">
    </div>
  </div>
</template>

<script>
import newService from "@/api/newService";

export default {
  name: "productFone",
    mounted() {
      this.getOneProduct()
    },
    data(){
    return {
      bannerimg:"product4/produc9.png",
        products:{},
    }
  },
  methods:{
    index(){
      this.$store.state.components='home'
    },
    next(){
      this.$store.state.components='product'
    },
    getOneProduct(){
        var id=localStorage.getItem('productId')
        newService.getOneProduct(id).then((res)=>{
            if (res.titleColor!== null && res.titleColor!== ""){
                this.$refs.title1.style.color=res.titleColor
                this.$refs.title2.style.color=res.titleColor
            }
            this.products=res
        })
    }
  }
}
</script>

<style lang="less" scoped>
.product{
  .linkbanner{
    width: 118rem;
    height: 4.8rem;
    display: flex;
    align-items: center;
    margin: auto;
    background: #F8F8F8;
    box-shadow: 0px 1px 0px 0px #E5E5E5;
    padding-left: 2rem;
    font-size: 1.6rem;
    /deep/.el-link--inner{
      font-size: 1.6rem;
    }
    .el-link.el-link--danger{
      color: #ED0200;
      font-size: 1.6rem;
    }
    i{
      margin: 0 5px;
      font-size: 1.6rem;
      padding-top: 1px;
    }
  }
  .banner{
    display: block;
    overflow: hidden;
    position: relative;
    width: 120rem;
    min-width: 120rem;
    margin: 6.4rem auto 0;
    img{display: block}
    .wrap{
      width: auto;
      position: absolute;
      top: 10rem;
      left:  10rem;
      z-index: 1;
      color: #fff;
      margin: 0;
      h1{
        font-size: 5.2rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        letter-spacing: .8rem;
      }
      h2{
        font-size: 2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
  .wrap{
    width: 120rem;
    display: block;
    overflow: hidden;
    margin:15rem auto;
    h3{
      font-size: 2.8rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.8rem;
      margin-bottom: 3.2rem;
    }
    h4{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-bottom: 10rem;
      line-height: 4rem;
    }
    .productOne-box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 6rem auto;
      div{
        width: calc(50% - 8rem);
        display: flex;
        margin-bottom: 4rem;
        align-items: center;
        font-size: 1.8rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 2;
        img{
          width: 7.2rem;
          margin-right: 2.5rem;
        }
        h5{

        }
        em{
          font-style: normal;
        }
      }
    }
  }
}
</style>
