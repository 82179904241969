<template>
<div class="wordhomw-wrap">
  <div class="v-note-wrapper markdown-body shadow"><h3><a id="VO_0"></a>创建VO</h3>
    <h4><a id="_1"></a>简介</h4>
    <p>VO即视图对象，用于展示层，它的作用是把某个指定页面（或组件）的所有数据封装起来。即和vue页面属性对应的java对象。<br>
      如果VO属性和DTO属性一致，我们通常会省略VO，直接使用DTO即可，这么做的目的是为了节省几行代码。<br>
      什么时候定义VO呢，比如有些属性，我们不想返还给用户，比如用户的密码等（DTO是包含用户密码的，这个时候就不能共用了），这时候就可以定义一个不含密码的VO。</p>
    <h4><a id="VO_7"></a>VO示例</h4>
    <pre><code class="lang-language">@Data
@EqualsAndHashCode(callSuper = false)
public class UserVO {
	private String id;

	/**	 * 头像	 */
	private String avatar;
	/**	 * 昵称	 */
	private String nickname;
	/**	 * 角色	 */
	private String role;
	/**	 * 邮件	 */
	private String email;
	/**	 * 手机电话	 */
	private String mobile;
	/**	 * qq	 */
	private String qq;

	.....
}
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom54"
}
</script>

<style scoped>

</style>
