<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h2><a id="_0"></a>数据权限</h2>
    <p>注意：admin是超级用户，上帝视角，对admin用户设置数据权限是无效的，该账号可见所有数据，请使用其他账号测试数据权限。</p>
    <p>支持数据行级权限，可以通过角色配置，灵活的设置用户可以看见的数据，比如只能看见自己创建的数据等等。</p>
    <p>底层原理是通过DataPermissionHandlerImpl.java类，拦截指定的mapper方法，在mapper方法中增加指定的过滤条件实现数据权限。</p>
    <h3><a id="1__7"></a>1 添加数据规则</h3>
    <p>对具体的菜单进行数据权限过滤，请添加数据规则：<br>
      （注意：数据规则虽然创建在菜单上，但是和菜单并没有任何直接关系，只是为了方便显示数据规则和功能之间的关系，实际上数据过滤是根据绑定的mapper方法实现的。）<br>
      <img src="../../../assets/word/170.png" alt=""></p>
    <p>添加数据权限：<br>
      <img src="../../../assets/word/118.png" alt=""></p>
    <p>详细说明：</p>
    <pre><div class="hljs"><code class="lang-xml">- 数据规则名称：自定义名字。
- 规则mapper方法：请输入要进行数据权限过滤的的mapper方法（例如: com..xxx.xxMapper.findList)。
- 规则字段： 要进行过滤的数据库字段，比如create_by_id，记录的创建者。（注意：是数据库中的字段，非java属性。以findList方法为例，如果是单表查询，即使用了mybatis plus的原生findList,请使用create_by_id,如果mapper方法是关联多表查询，即在xml中自定义了findList的SQL，那么该字段请带上xml中定义的表名，例如a.create_by_id）。
- 规则条件：大于（&gt;），等于(=)，小于等于(&lt;=)，模糊匹配(like)等等。
- 规则值：可以传入具体的值，比如 1,2，“abc”等等。 也可以传输动态参数值，#{currentUser.id},  currentUser表示当前登录的用户。
- 自定义sql：可以添加自定义sql语句，添加自定义sql时，规则字段和规则条件和规则值可以留空。例如（a.name IS 'test'）, 仅展现name是test的数据记录。
- 备注信息：自定义。
</code></div></pre>
    <h3><a id="2__28"></a>2 设置角色</h3>
    <p>选择角色-&gt;菜单权限-&gt;数据权限<br>
      <img src="../../../assets/word/173.png" alt=""></p>
    <p>说明：数据权限采用or，选择多个数据权限，数据显示是合集。</p>
    <h3><a id="3__36"></a>3 将角色分配给要使用数据权限的用户。</h3>
    <p><img src="../../../assets/word/146.png" alt=""></p>
    <h3><a id="4__39"></a>4 下面给几个添加数据权限的例子</h3>
    <p><img src="../../../assets/word/155.png" alt=""></p>
    <p>特别注意： 你一定好奇字段前面为什么要加上a.（例如a.create_by_id), 这是因为 findList 这个方法是我们自己实现的，不是mybatis-plus的原生方法，在对应的mapper.xml文件中，test_form_leave表的别名就是a， 所以加上a. 代表是test_form_leave表的字段。<br>
      那mybatis-plus的原生方法支持数据权限吗？同样支持，因为原生方法都是单表操作，所以原生方法不需要加别名，直接使用字段名即可（例如：create_by_id=@currentUser.id)。</p>
    <h3><a id="5__49"></a>5 忽略数据权限拦截器</h3>
    <p>有些特定场景，如果想让mapper方法不被数据拦截过滤器拦截，可以通过注解<br>
      @InterceptorIgnore(dataPermission = “true”) 实现。</p>
    <p>例如：</p>
    <pre><code class="lang-language">/**
 * 租户MAPPER接口
 *
 * @author
 * @version 2021-05-16
 */
@InterceptorIgnore(dataPermission = "true")
public interface TenantMapper extends BaseMapper &lt;Tenant&gt; {

}

</code></pre>
    <p>租户的mapper 就不经过数据权限拦截器。（注释：该注解既可以注释在Mapper接口上，忽略Mapper接口中（包括从父类继承的）的所有方法，也可以单独注解在Mapper接口中的具体方法上，但是不能注解在service层。</p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom64"
}
</script>

<style scoped>

</style>
