<template>
  <div class="Yuncang">
    <!--  轮播-->
    <div class="swiper">
      <el-row>

        <el-col :span="12" style="padding-right: 15rem">
          <h3>共建一个创造平台<br/>共创客户价值</h3>
          <h5>
            您想要的，都在这里
          </h5>
        </el-col>
        <el-col :span="12">
          <img src="../../../assets/Yuncang/Yuncang1.png" width="100%">
        </el-col>
      </el-row>
      <!-- Additional required wrapper -->
<!--      <div class="swiper-wrapper">-->
        <!-- Slides -->
<!--        <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
<!--          <img :src="require('../../../assets/'+item.url)" width="100%">-->
<!--        </div>-->
<!--      </div>-->
      <!-- If we need pagination -->
<!--      <div class="swiper-pagination"></div>-->

      <!-- If we need navigation buttons -->
      <!--    <div class="swiper-button-prev"></div>-->
      <!--    <div class="swiper-button-next"></div>-->

      <!-- If we need scrollbar -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>
    <!--我们的方案-->
    <section>
      <div class="wrap">

        <el-row :gutter="10">
          <el-col :span="23">
            <el-input placeholder="请输入内容" v-model="nickTitle" class="input-with-select" clearable>
              <el-button slot="append" icon="el-icon-search" style="background: #333" @click="serachByNickTitle">搜索</el-button>
            </el-input>
          </el-col>
        </el-row>
        <div class="yctab">
            <div class="list">
              <div class="left">
                分类
              </div>
              <div class="right">
                <span class="action" @click="clearClassfyId">全部</span>
                <span v-for="item in classifys" :key="item.id" @click="changeClassify(item.id)">{{ item.name }}</span>
              </div>
            </div>
          <div class="list">
            <div class="left">
              标签
            </div>
            <div class="right">
              <span class="action" @click="clearlabelIds">全部</span>
              <span v-for="item in labels" :key="item.id" @click="changelabelId(item.id)">{{ item.name }}</span>
            </div>
          </div>
          <div class="list">
            <div class="left">
              排序
            </div>
            <div class="right">
              <span class="action" @click="orderBy(0)">默认排序</span>
              <span @click="orderBy(1)">按价格</span>
              <span @click="orderBy(2)">按浏览量</span>
            </div>
          </div>
        </div>

        <div class="Yuncanglist">
          <div class="list" v-for="item in cloudList" :key="item.id" @click="clicknum(item.id)">
            <img :src="item.firstPhoto" width="100%">
            <div class="tetxbox">
              <div class="name">{{item.title}}</div>
              <div class="text"> {{item.synopsis}}</div>
              <div class="money">
                <div><i class="el-icon-view" style="color: #999; margin-right: 3px"></i>{{item.viewCount}}</div>
                <div><i>{{  parseFloat(item.price).toFixed(2) }}</i>元</div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            :page-count="11"
            layout="prev, pager, next"
            :total=total
        style="text-align: center; margin-top: 50px">
        </el-pagination>
      </div>
    </section>

  </div>
</template>

<script>
// import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";

import newService from "@/api/newService";

export default {
  name: "YuncangIndex",
    created() {
        this.getLabel()
        this.getClassify()
        this.getCloudList()
    },
    data(){
    return {
        cloudList:[],
        labels:[],
        classifys:[],
        pageNum:1,
        pageSize:8,
        classifyId:'',
        labelIds:'',
        orderByIng:'',
        nickTitle:'',
        total:0,
      ycinput:'',
      swiperimgData:[
        {
          name:'图片1',
          url:'Yuncang/Yuncang1.png'
        }
      ],
    }
  },
  mounted() {
    // 轮播
    // new Swiper(".swiper", {
    //   speed: 1000,
    //   spaceBetween: 100,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
    //   // navigation: {
    //   //   nextEl: ".swiper-button-next",
    //   //   prevEl: ".swiper-button-prev",
    //   // },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     type: 'bullets',
    //   },
    //   // scrollbar: {
    //   //   el: ".swiper-scrollbar",
    //   //   draggable: true,
    //   // },
    // });
    //视频
    //
  },
  methods:{
    clicknum(id){
      // let name='Yuncang'+(index+1)
        localStorage.setItem('cloudId',id)
      this.$store.state.components='yuncang1'
      this.$store.state.isbackground=true
      this.$store.state.iscolor=true
      this.$store.state.islogored=true
      document.body.scrollTop=document.documentElement.scrollTop=0
      // this.$emit('programmeMess')
    },
      getLabel(){
        newService.getLabel().then((res)=>{
            this.labels=res
        })
      },
      getClassify(){
        newService.getClassify().then((res)=>{
            this.classifys=res
        })
      },
      getCloudList(){
        var obj={
            pageNum:this.pageNum,
            pageSize:this.pageSize,
            classifyId:this.classifyId,
            labelIds:this.labelIds,
            orderByIng:this.orderByIng,
            nickTitle:this.nickTitle
        }
        newService.getCloudList(obj).then((res)=>{
            this.cloudList=res.rows
            this.total=res.total
        })
      },
      handleCurrentChange(val) {
          this.pageNum=val;//当前页
          this.getCloudList()
      },
      changeClassify(id){
          this.labelIds=''
          this.classifyId=id
          this.orderByIng=''
          var obj={
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              classifyId:this.classifyId,
              labelIds:this.labelIds,
              orderByIng:this.orderByIng,
              nickTitle:this.nickTitle
          }
          newService.getCloudList(obj).then((res)=>{
              this.cloudList=res.rows
              this.total=res.total
          })
      },
      clearClassfyId(){
        this.classifyId=''
          var obj={
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              classifyId:this.classifyId,
              labelIds:this.labelIds,
              orderByIng:this.orderByIng,
              nickTitle:this.nickTitle
          }
          newService.getCloudList(obj).then((res)=>{
              this.cloudList=res.rows
              this.total=res.total
          })
      },
      clearlabelIds(){
        this.labelIds=''
          var obj={
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              classifyId:this.classifyId,
              labelIds:this.labelIds,
              orderByIng:this.orderByIng,
              nickTitle:this.nickTitle
          }
          newService.getCloudList(obj).then((res)=>{
              this.cloudList=res.rows
              this.total=res.total
          })
      },
      changelabelId(id){
          this.classifyId=''
          this.orderByIng=''
          this.labelIds=id
          var obj={
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              classifyId:this.classifyId,
              labelIds:this.labelIds,
              orderByIng:this.orderByIng,
              nickTitle:this.nickTitle
          }
          newService.getCloudList(obj).then((res)=>{
              this.cloudList=res.rows
              this.total=res.total
          })
      },
      serachByNickTitle(){
          this.labelIds=''
          this.classifyId=''
          this.orderByIng=''
          var obj={
              pageNum:this.pageNum,
              pageSize:this.pageSize,
              classifyId:this.classifyId,
              labelIds:this.labelIds,
              orderByIng:this.orderByIng,
              nickTitle:this.nickTitle
          }
          newService.getCloudList(obj).then((res)=>{
              this.cloudList=res.rows
              this.total=res.total
          })
      },
      orderBy(id){
        if (id==0){
            this.orderByIng=''
            var obj={
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                classifyId:this.classifyId,
                labelIds:this.labelIds,
                orderByIng:this.orderByIng,
                nickTitle:this.nickTitle
            }
            newService.getCloudList(obj).then((res)=>{
                this.cloudList=res.rows
                this.total=res.total
            })
            return;
        }
        if (id==1){
            this.orderByIng='price'
            var obj1={
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                classifyId:this.classifyId,
                labelIds:this.labelIds,
                orderByIng:this.orderByIng,
                nickTitle:this.nickTitle
            }
            newService.getCloudList(obj1).then((res)=>{
                this.cloudList=res.rows
                this.total=res.total
            })
            return;
        }
        if (id==2){
            this.orderByIng='viewCount'
            var obj2={
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                classifyId:this.classifyId,
                labelIds:this.labelIds,
                orderByIng:this.orderByIng,
                nickTitle:this.nickTitle
            }
            newService.getCloudList(obj2).then((res)=>{
                this.cloudList=res.rows
                this.total=res.total
            })
            return;
        }
      }
  }

}
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FFEDEF;border-radius: 6px;color: #FF0B41;
}


.yctab{
  border: 1px solid #F8F8F8;
  display: block;overflow: hidden;
  margin: 4rem 0;
  border-bottom: 0;
  .list{
    display: flex;
    border-bottom: 1px solid #F8F8F8;
    line-height: 44px;
    .left{
      width: 90px;
      padding-left: 30px;
      color: #999;
    }
    .right{
      flex: 1;
      span{
        padding: 0 20px;
        cursor: pointer;
        height: 34px;
        display: inline-block;
        line-height: 34px;
        &:hover{
          background: #eee;
          border-radius: 5px;
        }
        &.action{
          font-weight: bold;
        }
      }
    }
  }
}
.input-with-select{
  background: #444955; color: #fff;
  border-radius: 5px;
  /deep/ .el-input-group__append{
    background: rgb(51, 51, 51);color: #fff;border-right: 0;
    position: relative;left: -1px;

  }
 /deep/ input{
  background: #444955; color: #fff
}
}
.swiper{
  width: 100%;
  background: url('../../../assets/Yuncang/Yuncang1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 12rem 0 10rem;
    margin: auto;
    text-align: left;
    h3{

      margin-top: 10rem;
      font-size: 5rem;
      margin-bottom: 2rem;
    }
    h5{
      font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.Yuncang{
  padding-top: 0rem;
}
.wrap{
  width: 120.0rem;
  min-width: 120rem;
  padding:16.0rem 0 16rem;
  margin: auto;
  .name{
    font-size: 4.0rem;
    color: #000;
    text-align: center;
    margin-bottom: 10rem;
  }
  .Yuncanglist{
    //display: flex;
    //flex-wrap: wrap;
    //justify-content:start;
    //align-items: center;
    display: grid;
    grid-template-columns: 28rem 28rem 28rem 28rem;
    //grid-template-rows: 28rem;
    grid-gap: 2.7rem 4rem;
    .list{
      width: 28.0rem;
      height: 28.0rem;
      display: flex;
      flex-wrap: wrap;
      box-shadow: 0rem .3rem 1.2rem 0rem rgba(0,0,0,0.12);
      border-radius: .6rem;
      margin-bottom: 4.0rem;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        img{
          transform: scale(1.15);

        }
        box-shadow: 0rem .3rem 1.2rem 0rem rgba(0,0,0,0.5);
      }
      img{height: 15.4rem}
      .tetxbox{
        padding: 1.4rem;
        .name{
          font-size: 2.0rem;
          color: #333;
          margin-bottom: 1.2rem;
          text-align: left;
          font-weight: 550;
        }
        .text{
          overflow: hidden; //超出的文本隐藏
          font-size: 1.4rem;
          text-overflow: ellipsis; //溢出用省略号显示
          font-weight: 400;
          display: -webkit-box;
          color: #444955;
          -webkit-line-clamp: 1; // 超出多少行
          line-height: 2.2rem;
          -webkit-box-orient: vertical;
        }
      .money{
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        margin-top: 2.0rem;
        color: #9999;
        i{
          font-size: 1.4rem;
          font-style: normal;
          color: #FF0B41;
        }
      }
      }
    }
  }


}

</style>
