<template>
<div class="wordhomw-wrap">
  <div d class="v-note-wrapper markdown-body shadow"><h2><a id="_1"></a>多数据源</h2>
    <p>在微服务中不建议使用多数据，强烈建议通过在yml文件中为不同的服务配置不同数据源的方式实现多数据功能。如果确实需要再同一服务中访问不同的数据源，请往下继续阅读。</p>
    <h4><a id="_8"></a>多数据源的功能简介</h4>
    <ul>
      <li>在yml文件中配置默认主数据源。</li>
      <li>项目启动后能动态增减数据源。</li>
      <li>支持多租户架构，使用spel动态参数解析数据源，如从session，header和参数中获取数据源。</li>
      <li>多层数据源嵌套切换。（一个业务ServiceA调用ServiceB，ServiceB调用ServiceC，每个Service都是不同的数据源）</li>
      <li>数据源分组，适用于多种场景 纯粹多库 读写分离 一主多从 混合模式。</li>
      <li>使用正则匹配或spel表达式来切换数据源</li>
      <li>每个模块或者插件可以访问不同的数据源，把系统数据库和业务数据库做到完全隔离。</li>
      <li>性能无损耗。</li>
    </ul>
    <h4><a id="1__18"></a>1 新建数据源</h4>
    <p>打开 数据管理/数据库连接<br>
      <img src="../../../assets/word/6.png" alt=""></p>
    <p>添加你的数据库连接，测试通过就可以使用该数据源了。<br>
      注意：连接英文名是数据源的唯一标志，不可重复。</p>
    <p>备注：代码生成器/图表组件/仪表盘设计都用到了该功能。</p>
    <h4><a id="2__DS__27"></a>2 使用 @DS 切换数据源。</h4>
    <p>@DS 可以注解在方法上和类上，同时存在方法注解优先于类上注解，强烈建议只注解在service实现上，没有@DS时，或者DS注解的数据源不存在时使用默认数据源。</p>
    <p>使用方法：@DS(“dsName”)， dsName是数据库连接的英文名。</p>
    <p>使用示例： findList访问数据源jee4， service中的其它方法访问jee3。</p>
    <pre><div class="hljs"><code class="lang-java"><span class="hljs-meta">@DS("jee3")</span>
<span class="hljs-meta">@Service</span>
<span class="hljs-meta">@Transactional(readOnly = true)</span>
<span class="hljs-keyword">public</span> <span class="hljs-keyword">class</span> <span class="hljs-title class_">LeaveForm1Service</span> <span class="hljs-keyword">extends</span> <span class="hljs-title class_">CrudService</span>&lt;LeaveForm1Mapper, LeaveForm1&gt; {

	<span class="hljs-keyword">public</span> LeaveForm1 <span class="hljs-title function_">get</span><span class="hljs-params">(String id)</span> {
		<span class="hljs-keyword">return</span> <span class="hljs-built_in">super</span>.get(id);
	}

	<span class="hljs-meta">@DS("jee4")</span>
	<span class="hljs-keyword">public</span> List&lt;LeaveForm1&gt; <span class="hljs-title function_">findList</span><span class="hljs-params">(LeaveForm1 leaveForm1)</span> {
		<span class="hljs-keyword">return</span> <span class="hljs-built_in">super</span>.findList(leaveForm1);
	}

	<span class="hljs-keyword">public</span> Page&lt;LeaveForm1&gt; <span class="hljs-title function_">findPage</span><span class="hljs-params">(Page&lt;LeaveForm1&gt; page, LeaveForm1 leaveForm1)</span> {
		<span class="hljs-keyword">return</span> <span class="hljs-built_in">super</span>.findPage(page, leaveForm1);
	}

	<span class="hljs-meta">@Transactional(readOnly = false)</span>
	<span class="hljs-keyword">public</span> <span class="hljs-keyword">void</span> <span class="hljs-title function_">save</span><span class="hljs-params">(LeaveForm1 leaveForm1)</span> {
		<span class="hljs-built_in">super</span>.save(leaveForm1);
	}

	<span class="hljs-meta">@Transactional(readOnly = false)</span>
	<span class="hljs-keyword">public</span> <span class="hljs-keyword">void</span> <span class="hljs-title function_">delete</span><span class="hljs-params">(LeaveForm1 leaveForm1)</span> {
		<span class="hljs-built_in">super</span>.delete(leaveForm1);
	}

}
</code></div></pre>
    <h4><a id="3__65"></a>3 动态参数解析数据源</h4>
    <pre><div class="hljs"><code class="lang-java"><span class="hljs-meta">@DS("#session.tenantName")</span><span class="hljs-comment">//从session获取</span>
<span class="hljs-keyword">public</span> List <span class="hljs-title function_">selectSpelBySession</span><span class="hljs-params">()</span> {
	<span class="hljs-keyword">return</span> userMapper.selectUsers();
}

<span class="hljs-meta">@DS("#header.tenantName")</span><span class="hljs-comment">//从header获取</span>
<span class="hljs-keyword">public</span> List <span class="hljs-title function_">selectSpelByHeader</span><span class="hljs-params">()</span> {
	<span class="hljs-keyword">return</span> userMapper.selectUsers();
}

<span class="hljs-meta">@DS("#tenantName")</span><span class="hljs-comment">//使用spel从参数获取</span>
<span class="hljs-keyword">public</span> List <span class="hljs-title function_">selectSpelByKey</span><span class="hljs-params">(String tenantName)</span> {
	<span class="hljs-keyword">return</span> userMapper.selectUsers();
}

<span class="hljs-meta">@DS("#user.tenantName")</span><span class="hljs-comment">//使用spel从复杂参数获取</span>
<span class="hljs-keyword">public</span> List <span class="hljs-title function_">selecSpelByTenant</span><span class="hljs-params">(User user)</span> {
	<span class="hljs-keyword">return</span> userMapper.selectUsers();
}
</code></div></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom91"
}
</script>

<style scoped>

</style>
