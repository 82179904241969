<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><h3><a id="_0"></a>时间格式化</h3>
    <h4><a id="JS_2"></a>JS中格式化</h4>
    <pre><code class="lang-"> this.moment(startDate).format('YYYY-MM-DD HH:mm:ss')
</code></pre>
    <h4><a id="_7"></a>模板中格式化</h4>
    <pre><code class="lang-">&lt;el-card shadow="never" header="v-time 时间转换" style="margin-top: 15px;"&gt;
	&lt;el-tag v-time="1630117968295" format="yyyy-MM-dd hh:mm:ss"&gt;&lt;/el-tag&gt;
	&lt;el-tag v-time.tip="time1"&gt;&lt;/el-tag&gt;
	&lt;el-tag v-time.tip="time2"&gt;&lt;/el-tag&gt;
	&lt;el-tag v-time.tip="time3"&gt;&lt;/el-tag&gt;
	&lt;el-alert title="指令方式日期时间转换,如设置'tip'修饰符将会转换成相对时间,并且每60秒自动更新" style="margin-top: 20px;"&gt;&lt;/el-alert&gt;
&lt;/el-card&gt;
</code></pre>
    <h4><a id="_18"></a>效果</h4>
    <p><img src="../../../assets/word/1.png" alt=""></p>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom39"
}
</script>

<style scoped>

</style>
