<template>
  <div class="wordhome">
    <header>
      <img src="../../assets/logored.png" @click="homeclick"/>
      <span>开发文档</span>
    </header>
    <div class="wordcontnt">
      <word-left style="width: 34rem; height: 100%; display: block; overflow-y: auto; background: #f5f7f9"></word-left>
      <wordright  style="width: calc(100% - 10rem); height: 100%;display: block; overflow-y: auto"></wordright>
    </div>
  </div>
</template>

<script>
import wordLeft from "@/components/word/wordleft";
import wordright from "@/components/word/wordright";
export default {
  name: "wordIndex",
  components:{
    wordLeft,
    wordright
  },
  methods:{
    homeclick(){
      this.$emit('homeclick')
    }
  }
}
</script>

<style scoped lang="scss">
.wordhome{
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  header{
    height: 6.4rem;
    line-height: 6.4rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: start;
    color: #000;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 10%);
    img{
      height: 2.7rem;
      margin: 2rem 3rem 2rem 4rem;
      cursor: pointer;
    }

}
  .wordcontnt{
    margin-top: 6.4rem;
    height: calc(100vh - 6.4rem);
    display: flex;
    overflow: hidden;
  }
 }
</style>
