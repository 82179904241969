<template>
<div class="wordhomw-wrap">
  <div data-v-7a63e4b3="" class="v-note-wrapper markdown-body shadow"><pre><code class="lang-language">    server {
        listen    80;
        server_name  47.93.149.183 wx..org sz..org cloud..org;
        client_max_body_size 100m;

        location /api/ {
            proxy_pass              http://127.0.0.1:8088/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }
        location /ureport/ {
            proxy_pass              http://127.0.0.1:8088/ureport/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }
        location /file/ {
            proxy_pass              http://127.0.0.1:8088/file/;
            proxy_set_header        Host 127.0.0.1;
            proxy_set_header        X-Real-IP $remote_addr;
            proxy_set_header        X-Forwarded-For $proxy_add_x_forwarded_for;
        }

        location / {
                root   /usr/share/nginx/html/app-web;
                index  index.html index.htm;
        }

        location /h5 {
                alias    /usr/share/nginx/html/app-h5;
                index  index.html index.htm;
        }


    }
</code></pre>
  </div>

</div>
</template>

<script>
export default {
  name: "wordCom102"
}
</script>

<style scoped>

</style>
