<template>
<div>aboutIndex</div>
</template>

<script>
export default {
  name: "aboutIndex"
}
</script>

<style scoped>

</style>
<template>
    <div class="about">
        <!--  轮播-->
        <div class="swiper">
            <el-row>

                <el-col :span="12" style="padding-right: 15rem">
                    <h3>聚焦客户需求 <br/>持续为客户创造价值</h3>
                    <h5>
                        成为中国一流的信息技术服务提供商
                    </h5>
                </el-col>
                <el-col :span="12">
                    <img src="../../../assets/about/about1.png" width="100%">
                </el-col>
            </el-row>
            <!-- Additional required wrapper -->
            <!--      <div class="swiper-wrapper">-->
            <!-- Slides -->
            <!--        <div class="swiper-slide" v-for="(item,index) in swiperimgData" :key="index">-->
            <!--          <img :src="require('../../../assets/'+item.url)" width="100%">-->
            <!--        </div>-->
            <!--      </div>-->
            <!-- If we need pagination -->
            <!--      <div class="swiper-pagination"></div>-->

            <!-- If we need navigation buttons -->
            <!--    <div class="swiper-button-prev"></div>-->
            <!--    <div class="swiper-button-next"></div>-->

            <!-- If we need scrollbar -->
            <!--    <div class="swiper-scrollbar"></div>-->
        </div>
        <!--关于我们-->
        <section style="margin-top: 70px">
            <div class="wrap" style="padding-bottom: 6rem">
                <div class="name">关于我们</div>
                <div style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap; flex-direction: column">
                    <!--        <img :src="require('../../../assets/'+gywourl)" style="width: 100%; height: 36rem">-->
                    <div style="font-size: 2rem;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;
color: #333333;line-height: 3rem; width: 100%; margin-top: 7.6rem; width: 100%; margin: auto;">
                        <p style="text-indent: 2em; font-size: 16px; margin-bottom: 4rem">
                            福建纵横创建于2009年，是一家提供数字化服务、软件研发、企业管理咨询服务、企业互联网解决方案、大数据服务、人才派遣服务、员工福利、区块链技术输出等信息产业相关服务的企业。我们以为客户提供数字化相关一站式服务为宗旨。截止2022年公司整体软件及信息化研发板块，拥有员工70多人，其中信息化技术及软件研发人员50人，专业信息化咨询、实施、交付与服务人员20人，高级计算机人才占公司员工结构的100%。其旗下“万启人力” 外包人力服务板块拥有500多人，为客户提供智能化、园区运行管理及企业IT员工租赁派遣等人事外包服务工作。
                        </p>
                        <p style="text-indent: 2em;font-size: 16px; margin-bottom: 4rem">公司是国家级高新技术企业，并与国内多家研究机构和高校：国家工业信息安全发展研究中心(工业和信息化部电子第一研究所)、中科院海西研究院，厦门大学、福州大学、福建工程学院、福建阳光学院等建立“产学研” 合作体系。也是国内互联网企业“京东云”、“腾讯云” 在福建区域的总代理商，负责“京东云”、“腾讯云”在福建区域的 “业务中台”、“数据中台”、““智慧仓储”、“智慧园区”等解决方案的指定唯一服务商。同时，也是知名上市公司用友股份，金蝶软件的专业授权销售、开发、交付、服务伙伴。为客户提供用友NC\NCC、金蝶EAS\k3等序列产品的信息化咨询、销售与实施交付支持工作。</p>
                        <p style="text-indent: 2em;font-size: 16px;"> 公司始终坚持“用信息技术推动商业和社会进步”的使命、定位 “为企业、政府、院校及社会组织资源聚合提供“智慧化”信息化平台服务”、并立志成为“行业领先的企业&政府&院校（社会组织)信息化管理与增值服务提供商”。</p>
                    </div>

                </div>
                <div class="about2" style="position: relative;">
                    <div style="position: absolute; left: 50rem; top: 5.5rem">
                        <h3 style="font-size: 2.6rem;font-weight: 400; margin-bottom: 1.8rem">成为中国一流的信息技术服务提供商</h3>
                        <h5 style="font-size: 16px;  font-weight: 100; line-height:1.6">聚焦客户需求、提供实用、管用、好用的信息化解决方案和服务，持续<br/>为客户创造价值</h5>
                    </div>
                </div>
            </div>
        </section>
        <!--团队介绍-->
        <section class="about3">
            <div class="wrap">
                <div class="name">团队介绍</div>
                <div style="display: flex; justify-content: space-between; align-items: center;  text-align: center; margin-top: 10rem">
                    <div class="tdbox">
                        <img :src="require('../../../assets/about/jj.png')" style="width: 14rem"/>
                        <div  class="tdbox-top">
                            <div class="name">陈俊箭</div>
                            <div class="zw">联合创始人/研发总经理</div>
                            <dl>
                                <dd>软件架构师&nbsp;&nbsp;&nbsp;&nbsp;软件设计师</dd>
                                <dd>系统分析师</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="tdbox">
                        <img :src="require('../../../assets/about/about44.png')" style="width: 14rem"/>
                        <div  class="tdbox-top">
                            <div class="name">龚丽娟</div>
                            <div class="zw">联合创始人/副总裁</div>
                            <dl>
                                <dd>财务及内控领域产品架构师</dd>
                                <dd>高级自选规划师</dd>
                                <dd>中级会计师</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="tdbox" >
                        <img :src="require('../../../assets/about/about47.png')"   style="transform: scale(1.1)  translateY(-9rem); width: 20rem"/>
                        <div style="margin-top: -9.4rem"  class="tdbox-top">
                            <div class="name" >林鹏</div>
                            <div class="zw">联合创始人/总裁</div>
                            <dl>
                                <dd>国际注册人力资源管理师</dd>
                                <dd>PMP项目管理国际认证顾问</dd>
                                <dd>高级咨询顾问&nbsp;&nbsp;&nbsp;&nbsp;IT咨询规划师</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="tdbox">
                        <img :src="require('../../../assets/about/about45.png')" style="width: 14rem"/>
                        <div  class="tdbox-top">
                            <div class="name">冯振光</div>
                            <div class="zw">联合创始人/副总裁</div>
                            <dl>
                                <dd>PMP项目管理国际认证顾问</dd>
                                <dd>产品架构师</dd>
                                <dd>高级咨询顾问</dd>
                            </dl>
                        </div>

                    </div>

                    <div class="tdbox">
                        <img :src="require('../../../assets/about/td-csh.png')" style="width: 14rem"/>
                        <div class="tdbox-top">
                            <div class="name">施秋济</div>
                            <div class="zw">联合创始人/项目总经理</div>
                            <dl>
                                <dd>供应链及创新产品架构师</dd>
                                <dd>高级咨询顾问</dd>
                                <dd>IT咨询规划师</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--发展历程-->
        <section  >
            <div class="wrap">
                <div class="name">发展历程</div>
                <div style="position: relative">
                    <img :src="require('../../../assets/about/aboutfzlc.png')" width="100%"/>
                    <!--          <div class="licheng" style="width: 20rem; left: 4.5rem; top: 60rem">-->
                    <!--            <div class="year">2017 <span>年</span></div>-->
                    <!--            <div class="text">纵横云创成立</div>-->
                    <!--          </div>-->
                    <!--          <div class="licheng"  style="width: 25rem; left: 24.5rem; top: 30rem">-->
                    <!--            <div class="text">纵横云创砥砺前行，在研发的道路上不断开拓创新。</div>-->
                    <!--            <div class="year">2010-2017 <span>年</span></div>-->
                    <!--          </div>-->
                    <!--          <div class="licheng" style="width: 25rem; left: 44.5rem; top: 53rem">-->
                    <!--            <div class="year">2010-2017 <span>年</span></div>-->
                    <!--            <div class="text">纵横云创砥砺前行，在研发的道路上不断开拓创新。</div>-->
                    <!--          </div>-->
                    <!--          <div class="licheng" style="width: 25rem; left: 67rem; top: 20rem">-->
                    <!--            <div class="text">纵横云创砥砺前行，在研发的道路上不断开拓创新。</div>-->
                    <!--            <div class="year">2020-2021 <span>年</span></div>-->

                    <!--          </div>-->
                    <!--          <div class="licheng" style="width: 25rem; left: 91rem; top: 45rem">-->
                    <!--            <div class="year">2022 <span>年</span></div>-->
                    <!--            <div class="text">ISO质量管理体系认证证书</div>-->
                    <!--          </div>-->
                    <!--          <div class="licheng" style="width: 25rem; left: 102rem; top: 21rem">-->
                    <!--            <div class="text">成就客户<br/>客户成功即我们的成功</div>-->
                    <!--            <div class="year">未来 </div>-->

                    <!--          </div>-->
                </div>
                <!--          <div class="lclist">-->
                <!--            <div class="lclist-box" v-for="(item,index) in fzlistData" :key="index + `lc`">-->
                <!--              <div class="year">{{item.year}}</div>-->

                <!--              <div class="year-box" v-if="item.chiled.length">-->
                <!--                <div class="year-link">-->
                <!--                  <a href="#" v-for="(link,linkindex) in item.chiled" :key="linkindex + `year`">·  {{link.name}}</a>-->
                <!--                </div>-->
                <!--                <img :src="item.img" width="285" />-->
                <!--              </div>-->
                <!--              <div class="xuline" v-if="item.chiled.length">-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--                <i></i>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--            <div class="button">查看更多</div>-->
                <!--          </div>-->
            </div>
        </section>

      <!--软件著作权-->
      <section class="about5">
          <div class="wrap" style="width: auto">
              <div class="name">行业认可</div>
              <div class="swiper2" style="position: relative; display: block; overflow: hidden">
                  <div class="swiper-wrapper">
<!--                        高新技术企业认证-->
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="justify-content: center;margin-top: 15rem">
                              <img :src="require('../../../assets/about/ryzz-22.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-22.png'))"/>
                          </div>
                      </div>
<!--                      //软件企业证书  软件产品证书-->
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="justify-content: center;margin-top: 15rem" >
                              <img :src="require('../../../assets/about/ryzz-16.png')" style="margin: 0;width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-16.png'))"/>
                              <img :src="require('../../../assets/about/ryzz-15.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-15.png'))"/>
                          </div>
                      </div>

                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">

                              <img :src="require('../../../assets/about/ry-rz-01.png')"   style="width:31rem " @click="onPreview(require('../../../assets/about/ry-rz-01.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-18.png')"   style="width:31rem " @click="onPreview(require('../../../assets/about/ry-ryzz-18.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-10.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-10.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="justify-content: center;">
                              <img :src="require('../../../assets/about/about33.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about33.png'))"/>
                              <img :src="require('../../../assets/about/about34.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about34.png'))"/>
                              <img :src="require('../../../assets/about/about35.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about35.png'))"/>
                              <img :src="require('../../../assets/about/about36.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about36.png'))"/>
                              <img :src="require('../../../assets/about/about37.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about37.png'))"/>
                              <img :src="require('../../../assets/about/about38.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/about38.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="justify-content: center;margin-top: 15rem;">
                              <img :src="require('../../../assets/about/ryzz-17.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-17.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box"  >
                              <img :src="require('../../../assets/about/ryzz-18.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-18.png'))"/>
                              <img :src="require('../../../assets/about/ryzz-19.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-19.png'))"/>
                              <img :src="require('../../../assets/about/ryzz-20.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-20.png'))"/>
                              <img :src="require('../../../assets/about/ryzz-21.png')" style="width: 41.3rem" @click="onPreview(require('../../../assets/about/ryzz-21.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;">
                              <img :src="require('../../../assets/about/ry-qlrz-01.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-qlrz-01.png'))"/>
                              <img :src="require('../../../assets/about/ry-qlrz-02.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-qlrz-02.png'))"/>
                              <img :src="require('../../../assets/about/ry-qlrz-03.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-qlrz-03.png'))"/>
                              <img :src="require('../../../assets/about/ry-qlrz-04.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-qlrz-04.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">
                              <img :src="require('../../../assets/about/ry-qlrz-05.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-qlrz-05.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">
                              <img :src="require('../../../assets/about/ry-ryzz-02.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-02.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-03.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-03.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-04.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-04.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-05.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-05.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">
                              <img :src="require('../../../assets/about/ry-ryzz-06.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-06.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-07.png')" style="width: 31rem; " @click="onPreview(require('../../../assets/about/ry-ryzz-07.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-08.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-08.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-09.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-09.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">
                              <img :src="require('../../../assets/about/ry-ryzz-01.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-01.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-11.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-11.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-12.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-12.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-13.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-13.png'))"/>
                          </div>
                      </div>
                      <div class="programmelist swiper-slide">
                          <div class="list-box" style="margin-top: 10rem;justify-content: center;">
                              <img :src="require('../../../assets/about/ry-ryzz-14.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-14.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-15.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-15.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-16.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-16.png'))"/>
                              <img :src="require('../../../assets/about/ry-ryzz-17.png')" style="width: 31rem" @click="onPreview(require('../../../assets/about/ry-ryzz-17.png'))"/>
                          </div>
                      </div>
                  </div>
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-pagination2" style="text-align: center"></div>
              </div>
              <!--        <div class="swiper2">-->
              <!--          <div class="swiper-wrapper">-->
              <!--            <div class="imglist swiper-slide">-->
              <!--              1-->
              <!--            </div>-->
              <!--            <div class="imglist swiper-slide">-->
              <!--              2-->

              <!--            </div>-->
              <!--          </div>-->
              <!--          <div class="swiper-pagination2" style="text-align: center"></div>-->
              <!--        </div>-->

          </div>
      </section>

        <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="[url]"
        />

        <!--新闻与活动-->
        <section  style="background: #fff">
            <div class="wrap">
                <div class="name">新闻与活动</div>
                <div class="boxwrap">
                    <div class="box"  @click="getOneNews()">
                        <img :src=enentFirst.firstPhoto style="width: 79rem;height: 250px;border-radius: 3px;margin-left: 1px">
                        <div class="boxname">
                            <i>{{ enentFirst.category }}</i>
                            <span>{{ enentFirst.title }}</span>
                        </div>
                    </div>
                    <div class="box" v-for="item in eventList" :key="item.id" @click="getOneNews(item.id)">
                        <img :src=item.firstPhoto style="width: 38rem;height: 250px;border-radius: 3px;margin-left: 1px">
                        <div class="boxname">
                            <i>{{ item.category }}</i>
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                    <!--                      <el-pagination-->
                    <!--                              background-->
                    <!--                              @current-change="handleCurrentChange"-->
                    <!--                              :current-page.sync="pageNum"-->
                    <!--                              :page-size="pageSize"-->
                    <!--                              layout="prev, pager, next"-->
                    <!--                              :total=total-->
                    <!--                              style="text-align: center; margin-top: 50px;margin-left: 45%">-->
                    <!--                      </el-pagination>-->
                </div>
            </div>
        </section>
    <!--联系我们-->
    <section n class="about7" style="padding-bottom: 20rem">
      <div class="wrap">
        <div class="name">联系我们</div>
        <div class="aboutme">
         <div class="mebox">
           <div class="me-name">{{ companys.name }}</div>
           <div class="list" style="width: 451px">
             <span>地址：{{ companys.address }}</span>
           </div>
           <div class="list">
             <i>电话：</i>
             <span>{{ companys.phone }}</span>
           </div>
           <div class="list">
             <i>邮箱：</i>
             <span>{{ companys.businessEmail }}</span>
           </div>
           <div class="ewm">
             <img :src=companys.wechatImg style="width: 12.8rem">
             <i>关注我，了解更多信息</i>
           </div>
         </div>
          <div class="map">
            <div id="map" style="width: 68rem; height: 38.5rem; display: block;overflow: hidden"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper, {Autoplay, Navigation, Pagination, Scrollbar} from "swiper";
import newService from "@/api/newService";
// 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: "aboutIndex",
    created() {
    },
    data(){
    return {
        url:[],
        showViewer: false, // 显示查看器
        isBackground: true,
        enentFirst:{},
        companys:{},
        aboutOne:{},
        pageNum:1,
        pageSize:5,
        total:0,
        eventList:[]
    }
  },
    components:{
        ElImageViewer
    },
  mounted() {
    this.getmapAddress()
      this.getNewsAndEvents()
    // 轮播
    // new Swiper(".swiper", {
    //   speed: 1000,
    //   spaceBetween: 100,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   modules: [Navigation, Pagination, Scrollbar, Autoplay],
    //   // navigation: {
    //   //   nextEl: ".swiper-button-next",
    //   //   prevEl: ".swiper-button-prev",
    //   // },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     type: 'bullets',
    //   },
    //   // scrollbar: {
    //   //   el: ".swiper-scrollbar",
    //   //   draggable: true,
    //   // },
    // });
    //轮播2
    new Swiper(".swiper2", {
      speed: 1000,
      spaceBetween: 100,
      initialSlide: 0,
          autoplay: {
            delay: 3000,
          },
      modules: [Navigation, Pagination, Scrollbar, Autoplay],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // pagination: {
      //   el: '.swiper-pagination2',
      //   type: 'bullets',
      //   clickable :true,
      // },
      // scrollbar: {
      //   el: ".swiper-scrollbar",
      //   draggable: true,
      // },
    });
  },
  methods:{
      onPreview(e) {
          this.url=[e]
          this.showViewer = true
      },
      // 关闭查看器
      closeViewer() {
          this.url=[]
          this.showViewer = false
      },
      getAboutOne(){
          newService.getAboutOne().then((res)=>{
              this.aboutOne=res
          })
      },
      getmapAddress(){
          newService.getTopCompany().then((res)=>{
              this.companys=res
              const company = res;
              this.map = new BMap.Map("map", {enableMapClick:false})  //新建地图实例，enableMapClick:false ：禁用地图默认点击弹框
              this.map.enableScrollWheelZoom(true);
              const x = company.longitude;
              const y = company.latitude;
              const mapPoint = parseInt(company.mapPoint);
              const point = new BMap.Point(x, y);
              this.map.centerAndZoom(point,mapPoint)
              var ssr = new BMap.Marker(point);
              this.map.addOverlay(ssr);
          })

      },
      getNewsAndEvents(){
          var obj={
              pageNum: this.pageNum,
              pageSize: this.pageSize
          }
          newService.getNewsAndEvents(obj).then((res)=>{
              this.eventList=res.rows
              this.enentFirst=this.eventList[0]
              this.eventList.shift()
              this.total=res.total
          })
      },
      prePage(){
          if (this.pageNum>1){
              this.pageNum=this.pageNum-1;
              this.getNewsAndEvents()
          }
          if (this.pageNum<=1){
             return;
          }
      },
      nextPage(){
          var count = this.pageNum*this.pageSize
          if (this.total<=count){
              return;
          }
          this.pageNum=this.pageNum+1;
          this.getNewsAndEvents();
      },
      getOneNews(id){

      },
      handleCurrentChange(val) {
          this.pageNum=val;//当前页
          this.getNewsAndEvents()
      },
  }

}
</script>

<style lang="scss" scoped>

.el-pagination {
  ::v-deep .btn-prev,
  ::v-deep .btn-next {
    background-color: transparent;
  }
  ::v-deep .el-icon-arrow-left:before,
  ::v-deep .el-icon-arrow-right:before{
    color: #afaaaa;
  }
}

::v-deep .el-pagination.is-background .el-pager li{
  background-color: transparent;
  color: #afaaaa;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #FFEDEF;
  color: #FF0B41;
}

.swiper{
  width: 100%;
  background: url('../../../assets/about/about1bg.png') no-repeat; background-size: 100% 100%;
  .el-row{
    width: 120rem;
    min-width: 120rem;
    padding: 12rem 0 10rem;
    margin: auto;
    text-align: left;
    h3{

      margin-top: 10rem;
      font-size: 5rem;
      margin-bottom: 2rem;
    }
    h5{
      font-size: 2rem;
      font-weight: 400;
      color: #444955;
      line-height: 40px;
    }
  }
}
.about{
  padding-top: 0rem;
}

.wrap{
  width: 120rem;
  min-width: 120rem;
  padding:20rem 0 16rem;
  margin: auto;
  display: block;
  overflow: hidden;
  .name{
    font-size: 4rem;
    color: #000;
    text-align: center;
    margin-bottom: 10rem;
  }
  .imglist{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img{
      width: 41.6rem;
    }
  }
  .boxwrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .box{
      position: relative;
      display: block;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 2.2rem;
      transition:all  0.5s ease-in;
      .boxname{
        position: absolute;
        bottom: 0;
        width: 90%;
        left: 10%;
        display: flex;
        flex-direction: column;
        color: #fff;
        i{
          font-style: normal;
          font-size:1.8rem ;
          font-weight: 300;
        }
        span{
          font-size: 2.8rem;
          margin: 1rem 0 2rem;
        }
      }
      &:hover {
        span{
          margin: 1rem 0 4rem;
        }
        img{
          transform: scale(1.1);
        }
      }
    }
  }
  .aboutme{
    display: flex;
    justify-content: space-between;
    justify-items: center;
    .mebox{
      margin-right: 4rem;
      .me-name{
        font-size: 3rem;
        font-weight: 500;
        color: #333;
        letter-spacing: .2rem;
        margin-bottom: 4rem;
      }
      .list{
        display: flex;
        font-size: 2.4rem;
        line-height: 2;
        i{
          font-style: normal;
          margin-right: .5rem;
        }
      }
      .ewm{
        display: flex;
        align-items: center;
        margin-top: 2rem;
        i{
          font-style: normal;
        }
      }
    }
  }
  .lclist{
    display: flex;
    flex-wrap: wrap;
    .button{
      display: block;margin:2rem auto;
      width: 14rem;
      height: 3.6rem;
      line-height: 3.6rem;
      text-align: center;
      border-radius: .2rem;
      border: .1rem solid #E5E5E5;
      font-size: 2rem;
      color: #999999;
      font-weight: 400;
      cursor: pointer;
      &:hover{
        background: #eee;
      }
    }
    .lclist-box{
      width: 100%;
      display: flex;
      padding-bottom: 1rem;
      justify-content: space-between;
      position: relative;
      .year{
        font-size: 4rem;
padding-left: 3rem;
        position: relative;
        z-index: 1;
        &::after{
          content: '';
          position: absolute;
          left: 0;
          top: 2.4rem;
          width: 2rem;
          height: .2rem;
          background: #999;
        }
        &:before{
          content: none;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          //border-left: 5px solid #999;
          border-style: dotted dotted dotted dotted;
          border-color: #D8D8D8;
        }
      }
      .year-box{
        background: #fff;
        display: flex;
        width: 111.5rem;
        margin-top: 6rem;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3rem;
        .year-link{;
          padding: 2rem 2rem 2rem 3rem;
          a{
            display: block;
            text-decoration: none;
            color: #444955;
            line-height: 2;
            font-size: 2rem;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        img{
          padding: 2rem;
        }
      }
      .xuline{
        position: absolute;
        left: 0;
        top: 2.4rem;
        width: 1rem;
        height: 100%;
        i{
          font-style: normal;
          left: 0;
          width: 1rem;
          height: .2rem;
          background:#D8D8D8 ;
          position: absolute;
          &:nth-child(1){
            top: 7.7%;
          }
          &:nth-child(2){
            top: 15.4%;
          }
          &:nth-child(3){
            top: 23.1%;
          }
          &:nth-child(4){
            top: 30.8%;
          }
          &:nth-child(5){
            top: 38.5%;
          }
          &:nth-child(6){
            top: 46.2%;
          }
          &:nth-child(7){
            top: 53.9%;
          }
          &:nth-child(8){
            top: 61.6%;
          }
          &:nth-child(9){
            top: 69.3%;
          }
          &:nth-child(10){
            top: 77%;
          }
          &:nth-child(11){
            top: 84.7%;
          }
          &:nth-child(12){
            top: 93.4%;
          }
        }
      }
    }
  }
  .tdbox{ width:22rem;
    margin-top: 7rem;
    .tdbox-top{
      height: 17rem;
    }
    .name{font-size: 3rem;
      font-family: PingFangSC-Medium, PingFang SC;
      margin-top: 4.6rem;
      margin-bottom: 1rem;
      font-weight: 500;
      color: #000000;
      line-height: 5rem;}
    .zw{font-size: 1.8rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 1.8rem;}
    dl{
      margin-top: 2.2rem;
      dd{
        font-style: normal;
        font-size: 1.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444955;
        line-height: 0.8rem;
        margin-bottom: 1rem;
      }
    }

  }
  .licheng{
    position: absolute;
    display: inline-block;
    text-align: center;
    .year{
      font-size: 3.2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 3.2rem;
      padding: 1.5rem 0;
      span{
        font-size: 2.4rem;
      }
    }
    .text{
      font-size: 2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444955;
      line-height: 3rem;
    }
  }
  .list-box{
    width: 124rem;
    margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
.about2{width: 100%; height: 24rem; background: url('../../../assets/about/about2bg.png') no-repeat; background-size: cover; margin-top: 6rem}
.about3{
  background: url('../../../assets/about/about3bg.png') no-repeat;background-size: cover;
}
.about5{
  background: url('../../../assets/about/about5bg.png') no-repeat;background-size: cover;
}
.about7{
  background: url('../../../assets/about/about7bg.png') no-repeat;background-size: cover;
}
</style>
